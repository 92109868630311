<template>
    <div
        v-if="!isOldUi && companyUser && companyUser.isLoaded() && permissionsLoaded"
        class="page-global-information"
    >
        <!-- PERSONAL INFO -->
        <NewProfileInfoMain
            :company-user="companyUser"
        />

        <!-- JOB INFORMATION -->
        <NewProfileInfoExtra
            :company-user="companyUser"
        />

        <NewProfileCustomFields
            v-if="companyUser.custom_fields.models.length"
            :user="companyUser"
        />

        <!-- CONTRACT INFORMATION -->
        <NewProfileInfoProbationary
            v-if="hasOnboardingProgram && $canRead('AbstractProfileProbation', { company_user_id: companyUser.id })"
            :company-user="companyUser"
        />
    </div>
    <div
        v-else-if="isOldUi && companyUser && companyUser.isLoaded() && permissionsLoaded"
        class="old-page-global-information"
    >
        <!-- PERSONAL INFO -->
        <ProfileInfoMain
            :company-user="companyUser"
        />

        <!-- JOB INFORMATION -->
        <ProfileInfoExtra
            :company-user="companyUser"
        />

        <!-- CONTRACT INFORMATION -->
        <ProfileInfoProbationary
            v-if="hasOnboardingProgram && $canRead('AbstractProfileProbation', { company_user_id: companyUser.id })"
            :company-user="companyUser"
        />

        <ProfileCustomFields
            v-if="companyUser.custom_fields.models.length"
            :user="companyUser"
        />

        <ProfileInfoTeam
            :company-user-id="companyUser.id"
        />
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import HtProgramType from '@/models/HtProgramType';
import NewProfileInfoMain from '@/components/pages/profile/profile/NewProfileInfoMain.vue';
import NewProfileInfoExtra from '@/components/pages/profile/profile/NewProfileInfoExtra.vue';
import NewProfileCustomFields from '@/components/pages/profile/profile/NewProfileCustomFields.vue';
import NewProfileInfoProbationary from '@/components/pages/profile/profile/NewProfileInfoProbationary.vue';
import ProfileInfoMain from '@/components/pages/profile/profile/ProfileInfoMain.vue';
import ProfileInfoExtra from '@/components/pages/profile/profile/ProfileInfoExtra.vue';
import ProfileCustomFields from '@/components/pages/profile/profile/ProfileCustomFields.vue';
import ProfileInfoProbationary from '@/components/pages/profile/profile/ProfileInfoProbationary.vue';
import ProfileInfoTeam from '@/components/pages/profile/profile/ProfileInfoTeam.vue';
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';

export default {
    name: 'ProfileInfo',
    components: {
        ProfileInfoMain,
        ProfileInfoExtra,
        ProfileCustomFields,
        ProfileInfoTeam,
        ProfileInfoProbationary,
        NewProfileInfoMain,
        NewProfileInfoExtra,
        NewProfileCustomFields,
        NewProfileInfoProbationary,
    },

    props: {
        companyUser: {
            type: CompanyUser,
            required: true,
        },
    },
    permissions: [
        'ModelCompanyUser',
        'AbstractProfileProbation',
    ],

    computed: {
        isOldUi() {
            return !hasFeatureEnabled(FEATURE_NEW_UI);
        },
        hasOnboardingProgram() {
            return this.companyUser.company_user_program.models.some((userProgram) => userProgram.company_program.ht_program_type.slug === HtProgramType.SLUG_ONBOARDING);
        },

        canShowPersonalInformation() {
            return (
                this.$canRead('AbstractProfileUserPersonalInformation', { company_user_id: this.companyUser.id })
                || this.$canRead('AbstractProfilePersonal', { company_user_id: this.companyUser.id })
            );
        },
    },
};
</script>
<style lang="scss" src="./ProfileInfo.scss" scoped></style>
