<template>
    <div class="user-card">
        <div class="user-header">
            <router-link
                class="user-header-link"
                :to="{
                    name: 'Profile',
                    params: { company_user_id: user.id },
                }"
            >
                <HtAvatar :user="$Utils.getAvatarData(user)" />

                <div class="text">
                    <div class="name">
                        {{ user.firstname }} {{ user.lastname.toUpperCase() }}
                    </div>
                    <div
                        v-if="user.job"
                        class="job"
                    >
                        {{ user.job.name }}
                    </div>
                </div>
                <HtIcon
                    name="nav-arrow-right"
                    size="20"
                />
            </router-link>
        </div>

        <div
            v-if="tabs.length > 1"
            class="tabs"
        >
            <HtTabs>
                <template #default>
                    <button
                        v-for="tab in tabs"
                        :key="tab.label"
                        type="button"
                        class="tab"
                        :class="{active: currentProgramToShow === tab.id}"
                        @click="currentProgramToShow = tab.id"
                    >
                        {{ tab.label }}
                    </button>
                </template>
            </HtTabs>
        </div>

        <div
            v-if="currentProgramToShow"
            class="list-tasks-user"
        >
            <div
                class="wrapper-tasks-list-items"
            >
                <TasksListItem
                    v-for="(task) in sortedFilteredAndLimitedTasksByProgramId(currentProgramToShow)"
                    :key="`task-${task.id}`"
                    class="task-item"
                    :task="task"
                    minimal-layout
                    :hide-program="true"
                    @update="updateTask"
                    @updateStatus="updateTaskStatus"
                    @delete="deleteTask"
                    @on-task-refreshed="handleTaskRefreshed"
                />
            </div>
        </div>

        <div
            v-if="!activeUserProgramsWithoutDefault.length"
            class="no-task-info"
        >
            <t>No current program</t>
        </div>

        <div
            v-else-if="!user.nb_tasks"
            class="no-task-info"
        >
            <t>No task left to do</t>
        </div>
        <button
            v-if="currentProgramToShow &&
                sortedAndFilteredTasksByProgramId(currentProgramToShow).length > nbTaskMinimum"
            type="button"
            class="user-footer"
            @click="toggleAllActions"
        >
            <t v-if="!showAllActions">
                Show all actions
            </t>
            <t v-else>
                Hide all actions
            </t>
        </button>
    </div>
</template>

<script>
import ParticipantListHelper from '@/components/mixins/ParticipantListHelper.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import resourceEventBus from '@/eventBus/resourceEventBus';
import HtTabs from '@/components/miscellaneous/HtTabs.vue';
import { sortUserProgramTasks } from '@/helpers/task';
import TasksListItem from '../item/TasksListItem.vue';
import listTasksMixin from '../listTasksMixin';

export default {
    name: 'TasksListByUserItem',
    components: {
        HtTabs,
        TasksListItem,
    },
    mixins: [listTasksMixin, ParticipantListHelper],
    props: {
        user: {
            type: Object,
            required: true,
        },
        addButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        isHistory: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            userProgramTasks: [],
            loading: false,
            nbTaskMinimum: 3,
            showAllActions: false,
            loadModal: false,
            currentProgramToShow: null,
        };
    },
    computed: {
        activeUserProgramsWithoutDefault() {
            return this.user.user_programs
                .filter((userProgram) => userProgram?.program?.type?.slug !== 'default'
                    && userProgram?.status === CompanyUserProgram.STATUS_ACTIVE);
        },
        tabs() {
            return this.activeUserProgramsWithoutDefault.map((userProgram) => ({
                label: userProgram.program?.name,
                id: userProgram.id,
            }))
                .filter((tab) => this.sortedAndFilteredTasksByProgramId(tab.id).length);
        },
        sortedTasks() {
            if (!this.userProgramTasks || !this.userProgramTasks.length) return [];

            return sortUserProgramTasks(
                this.handleTasks(
                    this.userProgramTasks
                        .map((task) => {
                            if (!this.hasParentPendingTask(task)
                                && task.status === CompanyUserProgramTask.STATUS_PENDING
                                && task.dependencies?.length) {
                                return this.getAllDependentTasks(task);
                            }
                            return task;
                        })
                        .filter((task) => !this.hasParentPendingTask(task)),
                ),
            );
        },
    },
    watch: {
        tabs(tabs) {
            if (!tabs || tabs.length <= 0) {
                return;
            }

            this.currentProgramToShow = tabs[0].id;
        },
    },
    created() {
        this.getTasksUser();

        resourceEventBus.$on('survey-done', (surveyId) => {
            const surveyTask = this.userProgramTasks.find((task) => task.resource === 'company_survey'
                && task.resource_id === surveyId);
            if (surveyTask) this.updateTask(surveyTask.id);
        });
        resourceEventBus.$on('nudge-accepted', (taskId) => {
            if (this.userProgramTasks.some((task) => task.id === taskId)) this.updateTask(taskId);
        });
    },
    methods: {
        getTasksUser() {
            if (this.user.nb_tasks && !this.loading) {
                this.loading = true;
                const userId = this.user.id;
                this.$store.dispatch(
                    this.isHistory ? 'tasks/fetchHistoryUserTasks' : 'tasks/fetchCurrentUserTasks',
                    { id: userId },
                )
                    .then((result) => {
                        this.userProgramTasks = this.userProgramTasks.concat(result.data.data);
                        this.loading = false;
                    });
            }
        },
        handleTaskRefreshed() {
            this.userProgramTasks = [];
            this.getTasksUser();
        },
        // the sorted tasks is an array of tasks
        sortedAndFilteredTasksByProgramId(programId) {
            return this.sortedTasks.filter((task) => task.user_program.id === programId);
        },
        sortedFilteredAndLimitedTasksByProgramId(programId) {
            return this.showAllActions
                ? this.sortedAndFilteredTasksByProgramId(programId)
                : this.sortedAndFilteredTasksByProgramId(programId)
                    .slice(0, this.nbTaskMinimum);
        },
        toggleAllActions() {
            this.showAllActions = !this.showAllActions;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.user-card {
    border-radius: var(--radius-block);
    background-color: var(--fill-primary);
    border: 1px solid #F2F2F4;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
}

.user-header {
    padding: 20px 20px 12px;
}

.user-header-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;

    .text {
        margin-right: auto;
    }

    .name {
        @include ht-body;
        line-height: 1;
    }

    .ht-avatar {
        margin-right: 12px;
    }

    .job {
        @include ht-caption;
        color: var(--text-secondary);
    }
}

.tabs {
    padding: 0 20px;
    border-bottom: 1px solid var(--border-primary);
}

.list-tasks-user {
    padding: 20px;
}

.task-item:not(:last-child) {
    margin-bottom: 12px;
}

.user-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 20px;
    border-top: 1px solid #F2F2F4;
    @include ht-body;
    text-decoration: underline;
    color: var(--text-primary);
}

.no-task-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    @include ht-body;
    border-top: 1px solid #F2F2F4;
}
</style>
