<template>
    <div class="d-flex document-editor">
        <div class="w-100">
            <div class="d-flex align-items-center p-5">
                <div class="d-flex align-items-center justify-content-center document-editor-file-icon">
                    <FontAwesomeIcon
                        class="branding-color"
                        :size="'1x'"
                        :icon="['fal', 'file']"
                    />
                </div>
                <div class="ml-4 document-editor-file-name">
                    {{ companyFile.original_name }}
                </div>
            </div>
            <template v-if="pdfFormTemplate">
                <div
                    id="dropzone"
                    class="mx-5"
                >
                    <Dropzone
                        :selected-page="selectedPage"
                        :parent-height="parentHeight"
                        :pdf-form-template="pdfFormTemplate"
                        :start-resize="startResize"
                        :resize="resize"
                        class="document-editor-dropzone"
                        :files.sync="pdfFormTemplate.files"
                        :document-fields.sync="pdfFormTemplate.fields"
                        :field-validator="fieldValidator"
                        @delete="remove"
                        @on-drop="onDrop"
                        @on-image-load="resize"
                    />
                </div>
                <HtPagination
                    v-model="selectedPage"
                    :last-page="pdfFormTemplate.files.length || 0"
                    @input="onPageChange"
                />
                <div class="d-flex justify-content-center my-5">
                    <Button
                        :state=" loading ? 'loading' : 'idle'"
                        :disabled="loading"
                        @click="handleUpdate"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </template>
            <IconLoading v-else />
        </div>
    </div>
</template>

<script>
import HtPagination from '@/components/globals/HtPagination.vue';
import Dropzone from '@/components/DocumentEditor/Dropzone.vue';
import DocumentEditor from '@/components/DocumentEditor/mixins/DocumentEditor';
import api from '@/store/api';
import { isEmpty } from '@/helpers/_';
import { parseDateByValueAndLanguage, parseDateByValueAndLanguageWithSpecificFormat } from '@/helpers/date';
import FieldValidator from './FieldValidator';

export default {
    name: 'EnrolleeEditor',
    components: {
        HtPagination,
        Dropzone,
    },
    mixins: [DocumentEditor],
    inject: ['modal'],
    data() {
        return {
            fieldValidator: null,
            editingLang: this.shared.session.companyUser.company_language.key,
            loading: false,
            items: [],
        };
    },
    computed: {
        editingSizes() {
            return {
                edited_width: this.pdfFormTemplate.edited_width,
                edited_height: this.pdfFormTemplate.edited_height,
            };
        },
        pdfSizes() {
            return {
                width: this.pdfFormTemplate.width,
                height: this.pdfFormTemplate.height,
            };
        },
        route() {
            return api.pdfForm.ROUTES.ENROLLEE;
        },
        documentFiles() {
            return this.pdfFormTemplate.files;
        },
        documentFields: {
            set(value) {
                this.$set(this.pdfFormTemplate, 'fields', value);
            },
            get() {
                return this.pdfFormTemplate.fields;
            },
        },
    },
    async created() {
        this.fieldTypes = (await api.pdfForm.loadAvailableTypes()).data.data;
        this.setItems();
        this.fieldValidator = new FieldValidator(this.fieldTypes);
    },
    methods: {
        refreshSizes(elem) {
            this.pdfFormTemplate.edited_width = elem.offsetWidth;
            this.pdfFormTemplate.edited_height = elem.offsetHeight;
            this.parentHeight = elem.offsetHeight;
            this.startResize = false;
        },
        fetchCallback(data) {
            this.pdfFormTemplate = data;
            this.startResize = true;
            this.before_width = data.edited_width;
            this.before_height = data.edited_height;
            if (data.scale) this.scale = data.scale;
            this.processFields();
        },
        processFields() {
            const paginatedFields = this.pdfFormTemplate.files.map(() => []);
            if (this.pdfFormTemplate.fields) {
                this.pdfFormTemplate.fields.forEach((field) => {
                    if (!paginatedFields[field.page_number]) {
                        paginatedFields[field.page_number] = [];
                    }
                    paginatedFields[field.page_number].push({
                        ...field,
                        uuid: this.$Utils.uniqId(),
                        top: field.original_top,
                        left: field.original_left,
                        value: field.type !== 'date' || isEmpty(field.value)
                            ? field.value
                            : parseDateByValueAndLanguage(
                                field.value,
                                this.editingLang,
                            ),
                    });
                });
            }

            this.pdfFormTemplate.fields = paginatedFields;
        },
        getPostData() {
            const elem = document.querySelector('#dropzone img');
            return {
                ...this.pdfFormTemplate,
                fields: this.pdfFormTemplate.fields.flat()
                    .map((field) => {
                        if (field.type !== 'date' || isEmpty(field.value)) {
                            return { ...field };
                        }

                        return {
                            ...field,
                            value: parseDateByValueAndLanguageWithSpecificFormat(
                                field.value,
                                this.editingLang,
                                'YYYY-MM-DD',
                            ),
                        };
                    }),
                edited_size: {
                    height: elem.offsetHeight,
                    width: elem.offsetWidth,
                },
            };
        },
        /**
         * @returns {boolean}
         */
        notAllMandatoryFieldsFilled() {
            return this.pdfFormTemplate.fields.some(
                (field) => field.is_mandatory
                    && (!field.value || field.value == ''),
            );
        },
        /**
         * @returns {boolean}
         */
        isComplete() {
            return !this.notAllMandatoryFieldsFilled();
        },
        /**
         * /!\ Two previous methods have to be called before processFields
         */
        handleUpdate() {
            this.loading = true;
            api.pdfForm.update(
                this.route,
                this.pdfFormTemplate.id,
                this.getPostData(),
            )
                .then((response) => {
                    this.pdfFormTemplate = response.data.data;
                    // putting the result of this.isComplete in a variable because this.isComplete, has said above,
                    // has to be called BEFORE this.processFields.
                    const isComplete = this.isComplete();
                    this.processFields();
                    this.resize();
                    this.$emit('onUpdate', isComplete);
                })
                .finally(() => {
                    this.loading = false;
                    this.modal.close();
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.document-editor {
    .label {
        font-size: 14px;
    }

    .square {
        width: 24px;
        height: 24px;
        border-radius: 6px;
        margin-right: 10px;
        background: $blue-1;
    }

    &-sidebar {
        background: $grey-light;
        min-height: 100vh;

        &-title {
            font-size: 20px;
            font-weight: 700;
            color: $black;
            padding: 33px 0 40px 0;
        }

        &-subtitle {
            font-size: 16px;
            font-weight: 700;
            color: $black;
            padding-bottom: 24px;
        }

        &-variables {
            position: sticky;
            top: 20px;
        }
    }

    &-file-icon {
        width: 36px;
        height: 36px;
        background: $primary-alpha;
        border-radius: 6px;
    }

    &-file-name {
        font-size: 14px;
        font-weight: 700;
        color: $black;
    }

    &-dropzone {
        border: 1px solid $grey-ultra-light
    }
}
</style>
