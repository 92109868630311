<template>
    <div
        class="list-tasks-user"
    >
        <div
            class="header"
            :class="{'no-tasks': !sortedTasks.length}"
        >
            <router-link
                :to="{
                    name: 'Profile',
                    params: { company_user_id: user.id },
                }"
                class="info"
            >
                <div class="avatar">
                    <div
                        v-user-image="getUserImage({company_user: user})"
                        :class="getClass({company_user: user})"
                        :title="getTitle({company_user: user})"
                    />
                </div>

                <div class="text">
                    <div class="name">
                        {{ user.firstname }} {{ user.lastname }}
                    </div>
                    <div
                        v-if="user.job"
                        class="job"
                    >
                        {{ user.job.name }}
                    </div>
                </div>
            </router-link>
            <div class="header-right">
                <div class="programs">
                    <div
                        :key="`program-label`"
                        class="program"
                    >
                        {{ programTagLabel }}
                    </div>
                </div>
                <FontAwesomeIcon
                    v-if="false"
                    :icon="['far', 'ellipsis-v']"
                    class="more"
                />
            </div>
        </div>

        <HtTabs
            v-if="activeProgramsWithoutDefault.length > 1"
            class="list-tasks-user-tabs"
        >
            <HtTab
                v-for="(item, index) in activeProgramsWithoutDefault"
                :key="index"
                :ref="`tab-program-${item.program.id}`"
                :title="item.program.name"
            >
                <transition-group
                    v-if="sortedAndFilteredTasksByProgramId(item.program.id).length"
                    class="list-tasks"
                    name="list"
                    tag="div"
                >
                    <TasksListItem
                        v-for="(task) in sortedAndFilteredTasksByProgramId(item.program.id)"
                        :key="`task-${task.id}`"
                        :task="task"
                        minimal-layout
                        :hide-program="true"
                        @update="updateTask"
                        @updateStatus="updateTaskStatus"
                        @delete="deleteTask"
                    />
                </transition-group>
            </HtTab>
        </HtTabs>
        <div v-else>
            <transition-group
                v-if="sortedAndFilteredTasks.length"
                class="list-tasks"
                name="list"
                tag="div"
            >
                <TasksListItem
                    v-for="(task) in sortedAndFilteredTasks"
                    :key="`task-${task.id}`"
                    :task="task"
                    minimal-layout
                    :hide-program="activeProgramsWithoutDefault.length < 2"
                    @update="updateTask"
                    @updateStatus="updateTaskStatus"
                    @delete="deleteTask"
                />
            </transition-group>
        </div>
        <div class="footer">
            <t
                v-if="!activeProgramsWithoutDefault.length"
                class="footer-info"
            >
                No current program
            </t>
            <template v-else>
                <t
                    v-if="!user.nb_tasks"
                    class="footer-info"
                >
                    No task left to do
                </t>
                <HtButton
                    v-else-if="restToDisplay || canHideTasks"
                    type="tertiary"
                    size="medium"
                    class="load-more"
                    @click.native="toggleTasksUser"
                >
                    <t
                        v-if="restToDisplay > 0"
                        :count="restToDisplay"
                    >
                        Display the {count} resource | Display the {count} resources
                    </t>
                    <t v-else>
                        Hide resources
                    </t>
                    <FontAwesomeIcon
                        v-if="loading"
                        icon="circle-notch"
                        spin
                    />
                    <FontAwesomeIcon
                        v-else
                        icon="chevron-down"
                        class="chevron"
                        :class="{up: !hideTasks }"
                    />
                </HtButton>
                <div
                    v-if="addButton"
                    class="add-resource-button"
                >
                    <HtButton
                        type="secondary"
                        size="medium"
                        :cypress="`add-resource-button-${user.id}`"
                        @click.native="openAddResource"
                    >
                        <FontAwesomeIcon
                            :icon="['fal', 'plus']"
                            class="plus"
                        />
                        <t>Add</t>
                    </HtButton>
                    <ModalAddUserElement
                        v-if="loadModal"
                        ref="modalableAddElement"
                        :company-user-id="user.id"
                        :company-user-program-id="activeProgramsWithoutDefault[0].id"
                        :company-user-programs="activeProgramsWithoutDefault"
                        @onUpdate="onUpdate"
                        @onDelete="onUpdate"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';
import ParticipantListHelper from '@/components/mixins/ParticipantListHelper.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import ModalAddUserElement from '@/components/profile/ModalAddUserElement.vue';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import resourceEventBus from '@/eventBus/resourceEventBus';
import HtTabs from '@/components/globals/HtTabs.vue';
import HtTab from '@/components/globals/HtTab.vue';
import { sortUserProgramTasks } from '@/helpers/task';
import TasksListItem from '../item/TasksListItem.vue';
import listTasksMixin from '../listTasksMixin';

export default {
    name: 'TasksListByUserItem',
    components: {
        HtTab,
        HtTabs,
        HtButton,
        TasksListItem,
        ModalAddUserElement,
    },
    mixins: [listTasksMixin, ParticipantListHelper],
    props: {
        user: {
            type: Object,
            required: true,
        },
        autoLoadTasks: {
            type: Boolean,
            required: false,
            default: false,
        },
        addButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        isHistory: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            userProgramTasks: [],
            loading: false,
            hideTasks: true,
            nbTaskMinimum: 3,
            loadModal: false,
        };
    },
    computed: {
        activeProgramsWithoutDefault() {
            return this.user.user_programs
                .filter((userProgram) => userProgram?.program?.type?.slug !== 'default'
                    && userProgram?.status === CompanyUserProgram.STATUS_ACTIVE);
        },
        restToLoad() {
            return (this.user.nb_tasks ?? 0) - this.userProgramTasks.length;
        },
        sortedTasks() {
            if (!this.userProgramTasks) return [];

            return sortUserProgramTasks(
                this.handleTasks(
                    this.userProgramTasks
                        .map((task) => {
                            if (!this.hasParentPendingTask(task)
                                && task.status === CompanyUserProgramTask.STATUS_PENDING
                                && task.dependencies?.length) {
                                return this.getAllDependentTasks(task);
                            }
                            return task;
                        })
                        .filter((task) => !this.hasParentPendingTask(task)),
                ),
            );
        },
        sortedAndFilteredTasks() {
            if (this.hideTasks) {
                return this.sortedTasks.slice(0, this.autoLoadTasks ? this.nbTaskMinimum : 0);
            }
            return this.sortedTasks;
        },
        restToDisplay() {
            return this.user.nb_tasks === this.userProgramTasks.length
                ? this.sortedTasks.length - this.sortedAndFilteredTasks.length : this.restToLoad;
        },
        canHideTasks() {
            return this.sortedTasks.length - this.sortedAndFilteredTasks.length === 0
                && this.sortedTasks.length > (this.autoLoadTasks ? this.nbTaskMinimum : 0);
        },
        programTagLabel() {
            if (this.activeProgramsWithoutDefault.length === 1) {
                return this.activeProgramsWithoutDefault[0].program.name;
            }

            return `${this.activeProgramsWithoutDefault.length} programs`;
        },
    },
    created() {
        if (this.autoLoadTasks) {
            this.getTasksUser(this.nbTaskMinimum);
        }

        resourceEventBus.$on('survey-done', (surveyId) => {
            const surveyTask = this.userProgramTasks.find((task) => task.resource === 'company_survey'
                && task.resource_id === surveyId);
            if (surveyTask) this.updateTask(surveyTask.id);
        });
        resourceEventBus.$on('nudge-accepted', (taskId) => {
            if (this.userProgramTasks.some((task) => task.id === taskId)) this.updateTask(taskId);
        });
    },
    methods: {
        getTasksUser(nbTasksToLoad = undefined) {
            if (this.user.nb_tasks && !this.loading) {
                this.loading = true;
                const userId = this.user.id;
                this.$store.dispatch(
                    this.isHistory ? 'tasks/fetchHistoryUserTasks' : 'tasks/fetchCurrentUserTasks',
                    {
                        id: userId,
                        body: {
                            params: {
                                limit: nbTasksToLoad,
                                offset: this.userProgramTasks.length,
                            },
                        },
                    },
                )
                    .then((result) => {
                        this.userProgramTasks = this.userProgramTasks.concat(result.data.data);
                        this.loading = false;
                    });
            }
        },
        toggleTasksUser() {
            this.hideTasks = !this.hideTasks;
            if (!this.hideTasks && this.restToLoad > 0) {
                this.getTasksUser();
            }
        },
        openAddResource() {
            this.loadModal = true;
            this.$nextTick(() => {
                this.$refs.modalableAddElement.open();
            });
        },
        onUpdate() {
            this.userProgramTasks = [];
            this.getTasksUser();
        },
        sortedAndFilteredTasksByProgramId(programId) {
            return this.sortedAndFilteredTasks.filter((task) => task.user_program.program.id === programId);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/transition-list";

.list-tasks-user {
    .header {
        background: $neutral-white;
        border-radius: 16px 16px 0 0;
        border: 1px solid $neutral-300;
        border-bottom: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;

        &.no-tasks {
            padding-bottom: 10px;
        }

        .info {
            flex-grow: 1;
            display: flex;
            gap: 8px;
            align-items: center;
            text-decoration: none;

            .avatar {
                border-radius: 100%;
                overflow: hidden;
                width: 56px;
                height: 56px;
                flex-shrink: 0;

                div {
                    width: 100%;
                    height: 100%;
                }
            }

            .text {
                .name {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 130%;
                    color: $neutral-black;
                }

                .job {
                    font-size: 14px;
                    line-height: 140%;
                    color: $neutral-500;
                }
            }
        }

        .header-right {
            display: flex;
            max-width: 60%;

            .programs {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .program {
                    padding: 6px;
                    background: $neutral-100;
                    border: 1px solid $neutral-300;
                    border-radius: 52px;
                    font-size: 12px;
                    line-height: 120%;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 160px;
                }
            }

            .more {
                transform: rotate(90deg);
                width: 15px;
            }

        }

    }

    .list-tasks {
        display: flex;
        background-color: $neutral-100;
        border: 1px solid $neutral-300;
        flex-direction: column;
        gap: 4px;
        padding: 8px;
    }

    .footer {
        background: $neutral-white;
        border-radius: 0 0 16px 16px;
        border: 1px solid $neutral-300;
        border-top: none;
        display: flex;
        align-items: center;
        padding: 12px 16px;

        .footer-info {
            flex-grow: 1;
            font-size: 14px;
            color: $neutral-500;
        }

        .load-more {
            color: $neutral-black;
            display: flex;
            align-items: center;
            gap: 4px;
            height: 32px;

            .chevron.up {
                transform: rotate(180deg);
            }
        }

        .add-resource-button {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;

            button {
                display: flex;
                align-items: center;
                gap: 6px;
            }
        }

    }

    ::v-deep .list-tasks-user-tabs {
        border-radius: 0;
        border-left: 1px solid $neutral-300;
        border-right: 1px solid $neutral-300;
        padding: 0;

        .ht-card-title {
            height: auto;
        }

        .ht-card-body {
            padding: 0;
        }

        .ht-tabs-list {
            overflow-x: auto;
            justify-content: flex-start;
            color: $neutral-600;
            height: auto;
            border-radius: 0;
            overflow-y: hidden;

            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            &-item {
                margin: 0 0 0 15px;
                flex: 0 0 auto;
                font-size: 14px;
                max-width: 160px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: 1.2em;
                height: 2.5em;
                font-weight: bold;

                &.active {
                    color: $black;
                }

                &:last-child {
                    margin-right: 15px;
                }
            }
        }

        .pseudo-border-bottom::after {
            top: calc(3.4rem - 2px);
        }

        @media (max-width: $tablet) {
            .ht-card-body {
                padding: 0;
            }
        }
    }
}
</style>
