export default {
    name: 'Card image left',
    image: 'card-img-left.png',
    fullPageBorder: false,
    content: `
        <section class="ht-section-card-img">
            <div class="card">
                <div class="card-image">
                    <img src="https://via.placeholder.com/400" alt="Placeholder Image" />
                </div>
                <div class="card-content">
                    <h2 class="title">Lorem ipsum dolor....</h2>
                    <p>... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias corporis deserunt ducimus labore non omnis quibusdam sapiente suscipit voluptate?.</p>
                    <a class="more-link" href="#open-modal">Voir plus &rarr;</a>
                </div>
            </div>

            <!-- Modal -->
            <div id="open-modal" class="modal-window">
                <div>
                    <a class="modal-close" title="Close" href="#">Close</a>
                    <h2 class="title">Title</h2>
                    <!-- Modal Image --> <img style="max-width: 100%; height: auto;" src="https://via.placeholder.com/400" alt="Modal Placeholder Image" />
                    <p>Content</p>
                </div>
            </div>
        </section>
    `,
    css: `
        .ht-section-card-img {
            padding: 40px 0;
        }

        .card {
            background-color: white;
            border-radius: 12px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            display: flex;
            flex-direction: row;
            align-items: stretch; /* Changed to stretch to allow child elements to fill the height */
            padding: 10px;
            overflow: hidden;
            flex: 1; /* Chaque carte prend une largeur égale */
        }

        .card-image {
            flex: 1;
            max-width: 350px; /* Set a fixed width for the image container to maintain a square aspect */
            max-height: 350px; /* Set a fixed height equal to the width to maintain a square aspect */
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            overflow: hidden; /* Hide any part of the image that exceeds the container size */
        }

        .card-image img {
            height: 100%; /* Let the image fill the height of the container */
            width: 100%; /* Let the image fill the width of the container */
            object-fit: cover; /* Ensure the image covers the area, preserving aspect ratio */
            border-radius: 12px;
        }

        .card-content {
            flex: 2;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center; /* Center content vertically */
        }

        .title {
            font-size: 32px;
            line-height: 36px;
            font-weight: bold;
            color: var(--primary-base);
            margin-bottom: 32px;
        }

        .card-content p {
            font-size: 16px;
            line-height: 21px;
            font-weight: regular;
            margin: 0 0 16px 0;
        }

        .more-link {
            color: var(--primary-base);
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        .more-link:hover {
            text-decoration: underline;
        }

        @media (max-width: 768px) {
            .card {
                flex-direction: column;
                text-align: left; /* Align text to the left in mobile view */
            }

            .card-image {
                max-width: 100%; /* Ensure the image takes full width in mobile view */
                min-height: auto; /* Reset minimum height for mobile */
            }

            .card-content {
                padding: 20px 0 0 0;
            }
        }

        .modal-window {
            position: fixed;
            background-color: rgba(0, 0, 0, 0.8); /* Darkened background for the modal */
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .modal-window:target {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
            overflow-y: auto; /* Allows scrolling for the entire modal window if the content is larger than the screen */
        }

        .modal-window > div {
            width: 90%;
            max-width: 1200px;
            background: #fff;
            padding: 2em;
            border-radius: 12px;
            overflow-y: auto; /* Allows scrolling within the modal content */
            max-height: 90%; /* Limits the height of the modal content to prevent it from touching the edges of the viewport */
            box-sizing: border-box; /* Ensures padding is included in the total width and height */
        }

        @media (max-width: 768px) {
            .modal-window > div {
                width: 100%;
                max-height: 100%;
                margin: 0;
                border-radius: 0; /* Optional: Makes the modal full-width and without rounded corners on small screens */
                overflow-y: auto; /* Ensures content within the modal is scrollable on small devices */
            }
        }

        .modal-close {
            color: #aaa;
            line-height: 50px;
            font-size: 80%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 0;
            width: 70px;
            text-decoration: none;
        }

        .modal-close:hover {
            color: #000;
        }
    `,
};
