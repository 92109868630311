<template>
    <div
        class="ht-action-menu"
        :class="position"
    >
        <div @click.stop="open">
            <slot>
                <div class="dots-menu">
                    <FontAwesomeIcon :icon="['far', 'ellipsis-v']" />
                </div>
            </slot>
        </div>

        <div
            v-if="opened"
            class="overlay"
            @click="close"
        />
        <div
            v-if="opened"
            class="actions"
        >
            <div
                v-for="action in actions"
                :key="action.label"
                class="action"
                @click="event => actionClicked(event, action.id)"
            >
                <FontAwesomeIcon
                    v-if="action.icon"
                    :icon="action.icon"
                    class="mr-2"
                />
                <t>{{ action.label }}</t>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        position: {
            type: String,
            default: 'default',
            validator(position) {
                return ['default', 'top-right'].includes(position);
            },
        },
        actions: {
            type: Array,
            required: true,
            validator(actions) {
                return actions.every(
                    (action) => action.label !== undefined && action.id !== undefined,
                );
            },
        },
    },
    data() {
        return {
            opened: false,
        };
    },
    methods: {
        open(event) {
            event.cancelBubble = true;

            this.opened = true;
        },
        close(event) {
            event.cancelBubble = true;

            this.opened = false;
        },
        actionClicked(event, actionId) {
            this.$emit('action-clicked', actionId);
            this.close(event);
        },
    },
};
</script>

<style lang="scss" scoped>
.dots-menu {
    cursor: pointer;

    // Making the icon easier to click
    width: 3rem;
    height: 3rem;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    z-index: 1;
}

.actions {
    position: absolute;
    background: white;
    padding: 0.8rem;

    border: 1px solid #e5e6e6;
    box-shadow: 0px 4px 4px rgba(74, 74, 74, 0.05),
        0px 4px 24px rgba(74, 74, 74, 0.08);
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;

    // Z-index to 1 so that it doesn't get overlapped with the below HtActionMenu dots-menu
    z-index: 1;

    .action {
        cursor: pointer;
        padding: 0.8rem;
    }
}

.default .dots-menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ht-action-menu.default {
    position: relative;
}

.default .actions {
    bottom: 0;
    right: 0;
}

.top-right .dots-menu,
.top-right .actions {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
}
</style>
