<template>
    <div class="headbar-body container">
        <div class="headbar-body-meta">
            <div
                v-if="showVersion"
                class="headbar-body-meta-version"
            >
                <div v-if="getFrontVersion">
                    {{ frontVersion }}
                </div>
                <div v-if="getBackVersion">
                    {{ backVersion }}
                </div>
            </div>
            <div
                v-if="showIcon"
                class="headbar-body-meta-icon"
                @click="shared.page.iconAction"
            >
                <FontAwesomeIcon
                    :icon="shared.page.icon"
                />
            </div>
            <div
                class="headbar-body-meta-avatar"
            >
                <div
                    v-if="showAvatar"
                    v-user-image="shared.page.avatar"
                />
            </div>
            <div
                v-if="showTitle"
                class="headbar-body-meta-title"
            >
                {{ shared.page.title }}
            </div>
            <div
                v-if="showSubtitles"
                :class="showAvatar ? 'headbar-body-meta-job-position' : 'headbar-body-meta-subtitle'"
            >
                {{ getSubtitlesString }}
            </div>
            <div class="headbar-body-meta-toasts">
                <HtToast
                    v-if="showDisclaimerEnv"
                    type="warning"
                    :title="toastTitle"
                    :message="toastMessage"
                />
                <NewVersionAvailable />
            </div>
        </div>
    </div>
</template>

<script>
import NewVersionAvailable from '@/components/miscellaneous/NewVersionAvailable.vue';
import HtToast from '@/components/globals/HtToast.vue';

export default {
    name: 'HeadBarBody',
    components: {
        HtToast,
        NewVersionAvailable,
    },
    computed: {
        getFrontVersion() {
            return this.$env.get('VERSION');
        },
        frontVersion() {
            const version = this.$env.get('VERSION');
            return this.computedVersion('Frontend Version: ', version);
        },
        backVersion() {
            const version = this.getBackVersion;
            return this.computedVersion('Frontend Version: ', version);
        },
        showVersion() {
            return !this.$env.isProd() && !this.$env.isDev();
        },
        showDisclaimerEnv() {
            return !this.$env.isProd() && !this.$env.isQa();
        },
        showTitle() {
            return this.shared.page.title !== null;
        },
        showAvatar() {
            return this.shared.page.avatar !== null;
        },
        showIcon() {
            return this.shared.page.icon !== null;
        },
        showSubtitles() {
            return this.shared.page.subtitles !== null;
        },
        getSubtitlesString() {
            return this.shared.page.subtitles.map((subtitle) => subtitle).join(' > ');
        },
        toastTitle() {
            return this.translate('Attention');
        },
        toastMessage() {
            const env = this.$env.get('APP_ENV').toUpperCase();
            return this.translate(`Vous êtes sur un environnement ${env}`);
        },
    },

    methods: {
        computedVersion(prefix, version) {
            const fromNow = this.$Utils.moment(version, 'YYYYMMDDHHmm').add(120, 'minutes').fromNow();

            return `${prefix} ${version} : ${fromNow}`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.headbar-body {
    height: 11.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-meta {
        position: relative;
        display: flex;
        align-items: center;
        height: 9.5rem;
        width: 100%;

        &-version {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 0.6em;
            color: $color-text-grey;
        }

        &-icon {
            border-radius: 50%;
            background: $white;
            width: 34px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 2rem;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                transform: scale(1.1);
            }
        }

        &-avatar {

            > div {
                width: 5.5rem;
                height: 5.5rem;
                border-radius: 2rem;
                margin-right: 1.5rem;
            }
        }

        &-title {
            font-size: 3.2rem;
            color: $color-white;
        }

        &-subtitle {
            position: absolute;
            top: 6.5rem;
            font-size: 1.4rem;
            color: $color-text-grey;
        }

        &-toasts {
            display: flex;
            gap: 24px;
            margin-left: 24px;
        }

        &-job-position {
            font-size: 1.75em;
            margin-left: 1.5rem;
            color: $color-text-grey;
        }
    }
}
</style>
