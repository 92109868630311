<template>
    <HtBadge
        v-if="showMissing"
        size="large"
    >
        <HtIndicator status="error" />
        <t>Missing</t>
    </HtBadge>
    <div v-else>
        {{ userRequirement.value | empty }}
    </div>
</template>

<script>
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';

export default {
    name: 'NewUserRequirementTextRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
        userCategoryStatus: {
            type: String,
            default: () => CompanyUserRequirementCategory.STATUS_PENDING_USER,
        },
    },
    computed: {
        showMissing() {
            const allowedStatus = [
                CompanyUserRequirementCategory.STATUS_PENDING_USER,
                CompanyUserRequirementCategory.STATUS_DONE,
                CompanyUserRequirementCategory.STATUS_ARCHIVED,
            ];

            return allowedStatus.includes(this.userCategoryStatus) && !this.userRequirement.value;
        },
    },
};
</script>
