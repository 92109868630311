<template>
    <ResourceListing
        ref="listing"
        api-name="emails"
        :title="translate('Emails')"
        :formatter="formatter"
        permission="ModelCompanyEmailCustomTemplate"
        empty-message="There are no emails to display yet..."
        has-quick-actions
        @on-action-clicked="onActionClicked"
    >
        <template #modalable="{ selectedResourceId }">
            <ResourceEmailEdit
                :id="selectedResourceId"
                ref="emailEdit"
                :disabled-program-list="false"
                @onClose="fetchData"
                @onDelete="fetchData"
                @onSave="fetchData"
            />
        </template>
    </ResourceListing>
</template>

<script>
import api from '@/store/api';
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';
import ResourceEmailEdit from '@/components/resources/emails/ResourceEmailEdit.vue';

export default {
    permissions: [
        'ModelCompanyEmailCustomTemplate',
    ],

    components: {
        ResourceListing,
        ResourceEmailEdit,
    },

    methods: {
        fetchData() {
            this.$refs.listing.fetchData();
        },

        formatter(items) {
            return items.map((item) => {
                const quickActions = [];
                if (this.$canCreate('ModelCompanyEmailCustomTemplate', { id: -1 })) {
                    quickActions.push({
                        id: 'replicate',
                        label: 'Replicate',
                        icon: ['fal', 'copy'],
                    });
                }

                return {
                    ...item,
                    quick_actions: quickActions,
                };
            });
        },

        replicateEmail(email) {
            api.configuration.emails.replicate(email.id)
                .then(() => {
                    this.$Notifier('App')
                        .showInfo(this.translate('The resource << {resource_name} >> is replicated', {
                            resource_name: email.resource.name,
                        }));
                    this.fetchData();
                })
                .catch(() => {
                    this.$Notifier('App')
                        .showError(this.translate('The resource << {resource_name} >> is not replicated!', {
                            resource_name: email.resource.name,
                        }));
                });
        },

        onActionClicked({
            item,
            actionId: action,
        }) {
            switch (action) {
            case 'replicate':
                this.replicateEmail(item);
                break;
            }
        },
    },
};
</script>
