<template>
    <div class="main">
        <div class="head-row">
            <div class="title">
                <HtButton
                    type="secondary"
                    @click.native="$emit('on-close')"
                >
                    <FontAwesomeIcon
                        size="2x"
                        :icon="['fal', 'chevron-left']"
                        transform="shrink-9"
                    />
                </HtButton>

                <h2 class="mb-0">
                    {{ requirementName }}
                </h2>
            </div>

            <div
                v-if="hasSelectedItems"
                class="extra-action-container"
            >
                <HtButton
                    :loading="remindLoading"
                    :disabled="remindLoading"
                    @click.native="remindSelection"
                >
                    <t>Remind</t>
                </HtButton>
            </div>
        </div>

        <HtEntitiesFiltersWrapper
            v-if="!hasSelectedItems"
            v-model="filters.entities"
            :additional-filters-select-length="selectedAdditionalFiltersLength"
            @on-clear-filters="clearFilters"
        >
            <template #title>
                <HtFormSelector
                    id="custom-filter"
                    v-model="filters.only_where_i_am_participant"
                    name="custom-filter"
                    :options="[
                        {label: translate('All collaborators'), value: false},
                        {label: translate('My team'), value: true}
                    ]"
                    :placeholder="translate('None')"
                    is-single
                    track-by="value"
                    specific-key="value"
                    label-options="label"
                    :allow-empty="false"
                >
                    <template #prepend>
                        <FontAwesomeIcon
                            :icon="['far', 'filter']"
                        />
                    </template>
                </HtFormSelector>
            </template>

            <template #topFilter>
                <HtSearchField
                    id="search"
                    v-model="filters.search"
                    name="search"
                    class="search"
                    :placeholder="translate('Search user...')"
                />
            </template>

            <template #beforeFilters>
                <HtFormSelector
                    id="programs"
                    v-model="filters.programs"
                    name="programs"
                    :options="[...programOptions]"
                    :placeholder="translate('Programs')"
                    :selection-label="translate('{count} program | {count} programs',{count: filters.programs.length})"
                    open-direction="bottom"
                    class="program-top-filter"
                />
            </template>

            <template #afterFilters>
                <HtFormSelector
                    id="status"
                    v-model="filters.statuses"
                    :placeholder="translate('Status')"
                    :options="statuses"
                    track-by="slug"
                    name="userStatus"
                />
            </template>
        </HtEntitiesFiltersWrapper>

        <DashboardProgramsEmpty
            v-if="showEmpty"
            :show-remove-button="false"
        />
        <div
            v-else
            class="table"
        >
            <div class="table-scrollable-container">
                <HTable
                    :use-new-style="true"
                    :columns="columns"
                    :data="formattedItems"
                    :loading="loading"
                >
                    <template #header(checkbox)>
                        <HTableCellCheckbox
                            v-model="checkAll"
                            :indeterminate="indeterminate"
                            :is-header="true"
                        />
                    </template>

                    <template #cell(checkbox)="data">
                        <HTableCellCheckbox
                            v-if="showSelectionToRemind(data.row)"
                            :value="rowChecked(data.row.task_id)"
                            @input="toggleCheckbox(data.row)"
                        />
                    </template>

                    <template #cell(user)="data">
                        <HTableCellUser
                            :user="data.value"
                        />
                    </template>

                    <template #cell(program_name)="data">
                        <HTableCellTag
                            :key="data.value"
                            :label="data.value"
                            :is-uppercase="false"
                        />
                    </template>

                    <template #cell(required_action)="data">
                        <t>{{ getRequiredActionLabel(data.value) }}</t>
                    </template>

                    <template #cell(participants)="data">
                        <HTableCellSupervisors
                            :users="data.value"
                        />
                    </template>

                    <template #cell(task_status)="data">
                        <HTableCellTag
                            :key="data.value"
                            :label="translate(data.value)"
                            :color="getStatusColor(data.value)"
                            :is-uppercase="false"
                        />
                    </template>
                </HTable>
            </div>
            <div>
                <HtPagination
                    v-model="page"
                    :last-page="lastPage"
                    class="pagination"
                    @input="loadData"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {
    HTable,
    HTableCellCheckbox,
    HTableCellSupervisors,
    HTableCellTag,
    HTableCellStatusInfos,
    HTableCellUser,
} from '@/components/globals/table';
import HtPagination from '@/components/globals/HtPagination.vue';
import api from '@/store/api';
import HtCompletionBar from '@/components/globals/HtCompletionBar.vue';
import HtButton from '@/components/globals/HtButton.vue';
import debounce from 'lodash.debounce';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import DashboardProgramsEmpty from '@/components/pages/dashboard/components/DashboardProgramsEmpty.vue';
import moment from 'moment';
import HtEntitiesFiltersWrapper from '@/components/globals/filters/HtEntitiesFiltersWrapper.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import I18n from '@/modules/i18n/I18n';

export default {
    name: 'UserTaskReminderVue',

    components: {
        HtFormSelector,
        HtEntitiesFiltersWrapper,
        HTable,
        HtPagination,
        HTableCellCheckbox,
        HTableCellSupervisors,
        HTableCellTag,
        HTableCellStatusInfos,
        HTableCellUser,
        HtCompletionBar,
        HtButton,
        HtSearchField,
        DashboardProgramsEmpty,
    },

    data() {
        return {
            loading: false,
            remindLoading: false,
            requirementId: null,
            requirementName: null,
            items: [],
            selectedItems: [],
            perPage: 15,
            lastPage: 1,
            page: 1,
            filters: {
                statuses: [],
                entities: [],
                programs: [],
                search: '',
                only_where_i_am_participant: false,
            },
            statuses: [
                {
                    name: I18n.translate('Done'),
                    slug: 'done',
                },
                {
                    name: I18n.translate('Awaiting'),
                    slug: 'awaiting',
                },
                {
                    name: I18n.translate('Reminded'),
                    slug: 'reminded',
                },
                {
                    name: I18n.translate('Pending'),
                    slug: 'pending',
                },
                {
                    name: I18n.translate('Cancelled'),
                    slug: 'cancelled',
                },
                {
                    name: I18n.translate('Late'),
                    slug: 'late',
                },
            ],
        };
    },

    computed: {
        columns() {
            return [
                {
                    key: 'checkbox',
                    thStyle: {
                        zIndex: 2,
                        width: '44px',
                    },
                    tdStyle: {
                        zIndex: 2,
                    },
                },
                {
                    label: this.translate('Collaborator'),
                    iconClass: ['far', 'user'],
                    key: 'user',
                    sortable: false,
                    thStyle: {
                        width: '350px',
                    },
                },
                {
                    label: this.translate('Program'),
                    iconClass: ['far', 'chart-network'],
                    key: 'program_name',
                    sortable: false,
                    thStyle: {
                        width: '350px',
                    },
                },
                {
                    label: this.translate('Required actions'),
                    iconClass: ['far', 'chart-network'],
                    key: 'required_action',
                    sortable: false,
                    thStyle: {
                        width: '350px',
                    },
                },
                {
                    label: this.translate('To be done by'),
                    iconClass: ['far', 'users'],
                    key: 'participants',
                    sortable: false,
                    thStyle: {
                        width: '250px',
                    },
                },
                {
                    label: this.translate('Status'),
                    iconClass: ['far', 'alarm-clock'],
                    key: 'task_status',
                    sortable: false,
                    thStyle: {
                        width: '300px',
                    },
                },
            ];
        },

        hasSelectedItems() {
            return this.selectedItems.length > 0;
        },

        checkAll: {
            set() {
                if (this.selectedItems.length < this.items.length) {
                    this.selectedItems = this.items.filter((item) => this.showSelectionToRemind(item))
                        .map((item) => item.task_id);
                } else {
                    this.selectedItems = [];
                }
            },
            get() {
                return this.selectedItems.length === this.items.length;
            },
        },

        indeterminate() {
            return this.selectedItems.length > 0 && this.selectedItems.length !== this.items.length;
        },

        showEmpty() {
            return this.items.length === 0 && !this.loading;
        },

        formattedItems() {
            return this.items.map((item) => ({
                ...item,
                _rowVariant: this.rowChecked(item.task_id) ? 'branding-bg-highlight' : null,

                _cellVariants: {
                    checkbox: this.rowChecked(item.task_id) ? 'branding-bg' : null,
                },
            }));
        },

        programOptions() {
            return this.$store.state.programs.programs.map((program) => ({
                id: program.id,
                name: this.oldLocalize(program.locales, 'name'),
            }));
        },

        selectedAdditionalFiltersLength() {
            return this.filters.statuses.length + this.filters.programs.length;
        },
    },

    methods: {
        open(requirementId, requirementName) {
            this.requirementId = requirementId;
            this.requirementName = requirementName;

            this.statuses.forEach((status) => {
                if (this.$route.query.status && this.$route.query.status.includes(status.slug)) {
                    this.filters.statuses.push(status);
                }
            });

            this.loadData(1);
        },

        async loadData(page = 1) {
            this.loading = true;
            const response = await api.dashboard.getRequirementTasksToRemind(this.requirementId, page, this.perPage, this.filters);
            this.lastPage = response.data.last_page;
            this.items = response.data.data;
            this.loading = false;
        },

        rowChecked(id) {
            return this.selectedItems.includes(id);
        },

        toggleCheckbox(row) {
            const {
                task_id,
                task_status,
                last_reminder_at,
            } = row;

            if ((last_reminder_at === null && task_status !== 'late') || (last_reminder_at !== null && moment()
                .diff(last_reminder_at, 'days') < 7)) {
                return;
            }

            if (this.selectedItems.includes(task_id)) {
                this.selectedItems = this.selectedItems.filter((id) => id !== task_id);
            } else {
                this.selectedItems.push(task_id);
            }
        },

        remindSelection() {
            this.remindLoading = true;

            api.dashboard
                .sendTasksReminders(this.requirementId, {
                    tasks: this.selectedItems,
                })
                .then(() => {
                    this.selectedItems = [];
                    this.loadData();
                    this.$Notifier('App')
                        .showInfo(this.translate('The employees you selected have received a reminder to complete their tasks.'));
                })
                .finally(() => {
                    this.remindLoading = false;
                });
        },

        clearFilters() {
            this.filters.programs = [];
            this.filters.statuses = [];
        },

        showSelectionToRemind(row) {
            const {
                task_status,
                last_reminder_at,
            } = row;

            return task_status === 'late' || moment()
                .diff(last_reminder_at, 'days') >= 7;
        },

        getRequiredActionLabel(action) {
            switch (action) {
            case 'executor':
                return 'To complete';
            case 'signer':
                return 'To sign';
            case 'validator':
                return 'To validate';
            }

            return '-';
        },

        getStatusColor(status) {
            switch (status) {
            case 'done':
                return 'green';
            case 'awaiting':
            case 'reminded':
                return 'orange';
            case 'pending':
                return 'blue';
            case 'cancelled':
            case 'late':
                return 'red';
            default:
                return 'grey';
            }
        },
    },

    watch: {
        filters: {
            deep: true,
            handler: debounce(function () {
                this.loadData(1);
            }, 300),
        },
    },
};
</script>

<style lang="scss" scoped>
.head-row {
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;

    .title {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
        gap: 8px;
    }

    .search-container, .extra-action-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
        margin-left: auto;
    }
}

.table {
    margin-bottom: 80px;
    margin-top: 30px;

    .table-scrollable-container {
        overflow-y: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        table {
            width: 100%;
            white-space: nowrap;
            table-layout: auto !important;
        }
    }
}

.table-button {
    display: block;
}
</style>
