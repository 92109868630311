import CompanyFile from '@/models/CompanyFile';
import Notifier from '@/Notifier';

export default {
    data() {
        return {
            uploadInfoTpl: {
                name: '',
                type: '',
                loaded: 0,
                total: 0,
                percent: 0,
            },
            jwt: '',
            numspot_jwt: '',
            NUMSPOT_STORAGE: 'numspot',
            AWS_STORAGE: 'aws',
        };
    },
    methods: {
        getClient(isRequirement = false) {
            if (!isRequirement) {
                return this.$http;
            }

            if (this.isNumspotStorage() && this.numspot_jwt !== '') {
                this.$numspotHttp.defaults.headers['X-Numspot-Token'] = this.numspot_jwt;

                return this.$numspotHttp;
            }

            return this.$http;
        },
        async downloadUserRequirementFiles(userId, isRequirement = false) {
            if (isRequirement && this.isNumspotStorage()) {
                try {
                    await this.numspotSso();

                    await this.triggerDownloadZipCall(userId);
                } catch (error) {
                    Notifier.getInstance('App')
                        .showError('Zip cannot be downloaded');
                    console.error('Numspot SSO ERROR FOR ZIP DOWNLOAD : ', error);
                }
            } else {
                await this.triggerDownloadZipCall(userId);
            }
        },
        async triggerDownloadZipCall(userId) {
            try {
                const responseData = await this.getClient(true)
                    .post('company_user_requirement_category/download/zip', {
                        controller: 'CompanyUserRequirementCategoryController',
                        company_user_id: userId,
                        jwt: this.isNumspotStorage() ? this.numspot_jwt : this.jwt,
                        method: 'lookup',
                        isRequirement: true,
                    });

                if (responseData.status === 200) {
                    window.location = responseData.data.resource.link;
                }
            } catch (responseError) {
                console.error('[Numspot] Failed to download zip', responseError);
            }
        },
        isNumspotStorage() {
            return this.shared.session.storageMode === this.NUMSPOT_STORAGE;
        },
        async downloadCompanyFile(fileId, isRequirement = false) {
            if (isRequirement && this.isNumspotStorage()) {
                await this.numspotSso();
            }

            await this.triggerDownloadFileCall(fileId, isRequirement);
        },
        setNumspotJwt(jwt) {
            this.numspot_jwt = jwt;
        },
        async openAuthPopup(url) {
            this.numspot_jwt = null;

            return new Promise((resolve, reject) => {
                const popup = window.open(url, '', 'popup, location=no, toolbar=no, menubar=no, scrollbars=yes, resizable=no width=800, height=600');

                // Check if popup is closed manually by user
                const closePopupInterval = setInterval(() => {
                    if (popup.closed) {
                        clearInterval(closePopupInterval);

                        Notifier.getInstance('App')
                            .showError('Numspot SSO authentication failed');

                        reject(new Error('Numspot SSO authentication failed'));
                    }
                }, 500);

                const messageHandler = (event) => {
                    if (event.data.type === 'authDone') {
                        this.setNumspotJwt(event.data.jwt);

                        Notifier.getInstance('App')
                            .showInfo('Numspot SSO authentication OK');

                        clearInterval(closePopupInterval);

                        popup.close();
                        window.removeEventListener('message', messageHandler);

                        resolve();
                    } else if (event.data.type === 'authFailed') {
                        Notifier.getInstance('App')
                            .showError('Numspot SSO authentication failed');

                        clearInterval(closePopupInterval);

                        popup.close();
                        window.removeEventListener('message', messageHandler);

                        reject(new Error('Numspot SSO authentication failed'));
                    }
                };

                window.addEventListener('message', messageHandler);
            });
        },
        async numspotSso() {
            try {
                const responseData = await this.getMultiCloudToken();

                return new Promise((resolve, reject) => {
                    if (responseData.status === 200) {
                        this.jwt = responseData.data.message;
                        const url = `${this.shared.session.numspotSsoLoginUrl}?jwt=${responseData.data.message}`;

                        try {
                            const popupResponse = this.openAuthPopup(url);

                            resolve(popupResponse);
                        } catch (error) {
                            Notifier.getInstance('App')
                                .showError('Numspot SSO authentication failed');
                            reject(new Error('Numspot SSO authentication failed'));
                        }
                    } else {
                        throw new Error('Fail to fetch multi cloud token');
                    }
                });
            } catch (responseError) {
                console.error('Fail to fetch multi cloud token', responseError);
            }
        },
        async getMultiCloudToken() {
            return this.$http.get('multi-cloud/get-token');
        },
        extractFileNameFromContentDispositionHeader(headers) {
            const contentDisposition = headers['content-disposition'];
            let filename = 'downloaded_file';

            if (contentDisposition && contentDisposition.includes('attachment')) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            return filename;
        },
        async triggerDownloadFileCall(fileId, isRequirement = false) {
            try {
                const data = {
                    isRequirement,
                };
                const headers = {};

                if (isRequirement && this.isNumspotStorage()) {
                    headers.responseType = 'blob';
                }

                const response = await this.getClient(isRequirement)
                    .post(`company_file/download/${fileId}`, data, headers);

                if (response.status === 200 && isRequirement && this.isNumspotStorage()) {
                    const filename = this.extractFileNameFromContentDispositionHeader(response.headers);

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;

                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                } else if (response.status === 200) {
                    const file = new CompanyFile(['id', 'link']);
                    file.id = fileId;
                    file.link = response.data.link;
                    file.download();
                } else {
                    this.error = this.translate(response.message);
                    console.error('[Numspot] Failed to get download link (bad http code)', this.error);
                }
            } catch (responseError) {
                console.error('[Numspot] Failed to get download link', responseError.message);
            }
        },
        async uploadCompanyFile(file, isRequirement = false) {
            if (isRequirement && this.isNumspotStorage()) {
                try {
                    const responseData = await this.getMultiCloudToken();

                    return this.triggerUploadCompanyFile(file, isRequirement, responseData.data.message);
                } catch (responseError) {
                    console.error('Fail to fetch multi cloud token', responseError);
                }
            } else {
                return this.triggerUploadCompanyFile(file, isRequirement);
            }
        },
        async triggerUploadCompanyFile(file, isRequirement = false, jwt = false) {
            const uploadInfo = this.$Utils.clone(this.uploadInfoTpl, true);

            uploadInfo.name = file.name;
            uploadInfo.size = file.size;

            const fd = new FormData();

            let client = this.API;
            let uploadUrl = this.ressource;

            if (isRequirement && this.isNumspotStorage()) {
                client = this.NumspotAPI;
                uploadUrl = 'numspot/company_file';
                fd.append('jwt', jwt);
            }

            fd.append('file', file);

            return new Promise((resolve, reject) => {
                client.post(uploadUrl, fd, {
                    onUploadProgress: (progressEvent) => {
                        uploadInfo.total = progressEvent.total;
                        uploadInfo.loaded = progressEvent.loaded;
                        uploadInfo.percent = Math
                            .round((progressEvent.loaded * 100) / progressEvent.total);
                        this.$emit('onUploadProgress', uploadInfo);
                    },
                    200: (data) => {
                        uploadInfo.size = this.$Utils.convertByteToMegabyte(file.size);
                        this.$emit('onUploadEnd', uploadInfo, data);
                        resolve({
                            name: uploadInfo.name,
                        });
                    },
                    onCancel: () => {
                        this.$emit('onUploadCancel', uploadInfo);
                        reject(new Error('Upload canceled'));
                    },
                    onError: (data, code) => {
                        this.$emit('onUploadError', code, uploadInfo);
                        reject(new Error('Upload failed'));
                    },
                });
            });
        },
    },
};
