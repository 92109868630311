<template>
    <HtBadge
        v-if="shouldShowMissing"
        size="large"
    >
        <HtIndicator status="error" />
        <t>Missing</t>
    </HtBadge>
    <div v-else>
        {{ userRequirement.value }}
    </div>
</template>

<script>
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';

export default {
    name: 'NewUserRequirementStringRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
        userCategoryStatus: {
            type: String,
            default: () => CompanyUserRequirementCategory.STATUS_PENDING_USER,
        },
    },
    computed: {
        getValue() {
            if (this.userRequirement.value) {
                return this.userRequirement.value;
            }

            return '-';
        },

        shouldShowMissing() {
            const allowedStatus = [
                CompanyUserRequirementCategory.STATUS_PENDING_USER,
                CompanyUserRequirementCategory.STATUS_DONE,
                CompanyUserRequirementCategory.STATUS_ARCHIVED,
            ];

            return allowedStatus.includes(this.userCategoryStatus) && !this.userRequirement.value;
        },
    },
};
</script>
