import CompanyEntity from '@/models/CompanyEntity';
import CompanyEntityValueCustom from '@/models/CompanyEntityValueCustom';

/**
 * @typedef {name: String, slug: String} Entity
 * @param {{entity: Entity}[]} entities
 * @return {{name: String, label: String}[]}
 */
export function getEntityVariables(entities) {
    return entities.map((entity) => {
        const { name } = entity.entity;
        const { slug } = entity.entity;
        return {
            name: !CompanyEntity.DEFAULT_ENTITY_SLUGS.includes(slug)
                ? `${CompanyEntityValueCustom.VARIABLE_SLUG_PREFIX}${slug}`
                : `${slug.replace('company_', '')
                    .replace('office', 'site')}_name`,
            label: name,
        };
    });
}
