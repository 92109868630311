export default {
    data() {
        return {
            taskColor: {
                draft: 'orange',
                pending: 'purple',
                done: 'green',
                cancelled: 'red',
            },
        };
    },

    methods: {
        statusText(status, datetime = null, validatorType = 'human') {
            const { moment } = this.$Utils;

            switch (status) {
            case 'done':
                return this.translate('Done');
            case 'cancelled':
                return this.translate('Cancelled');
            case 'draft':
                return this.translate('Draft');
            case 'pending':
                if (validatorType === 'system') {
                    const momentDatetime = this.getTime(datetime);

                    if (momentDatetime <= moment()) {
                        return this.translate('Will be automaticaly executed in a few minutes');
                    }

                    return this.translate('Will be automaticaly executed on {date}', { date: momentDatetime.format('LL') });
                }
                return this.translate('Pending');
            default:
                return this.translate('To do');
            }
        },

        getTime(datetime) {
            const { moment } = this.$Utils;
            return moment(datetime);
        },
    },
};
