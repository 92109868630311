<template>
    <div v-if="companyUserSoftwares.isLoaded()">
        <HtAccordion
            :title="translate('Software')"
            :count="companyUserSoftwares.$.models.length"
        >
            <HtTable
                v-if="companyUserSoftwares.$.models.length > 0"
                :items="companyUserSoftwares.$.models"
                :columns="columns"
                cypress="user-activities"
                @onCellClicked="openItemModal"
            >
                <template #cell(name)="scope">
                    <div>{{ scope.item.company_software.resource.name }}</div>
                    <div v-if="scope.item.company_software.resource.description">
                        {{ scope.item.company_software.resource.description }}
                    </div>
                </template>
                <template #cell(display_status)="scope">
                    <HtTag :color="taskColor[scope.item.company_user_program_task.status]">
                        {{ statusText(scope.item.company_user_program_task.status) }}
                    </HtTag>
                </template>
            </HtTable>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No software added</t>
                </strong>
            </div>
        </HtAccordion>

        <modalable
            ref="modalableUserItem"
            class="modalable-1 small"
            :mode="2"
        >
            <UserSoftwareItem
                :id="currentId"
                ref="userItem"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
    </div>
</template>
<script>
import CompanyUserSoftwareCollection from '@/models/CompanyUserSoftwareCollection';
import UserSoftwareItem from '@/components/pages/dashboard/modals/software/UserSoftwareItem.vue';
import HtAccordion from '@/components/globals/HtAccordion.vue';
import TaskStatusMixin from '@/mixins/TaskStatusMixin';
import HtTag from '@/components/globals/HtTag.vue';

export default {
    name: 'UserSoftwareProfile',

    components: {
        UserSoftwareItem,
        HtAccordion,
        HtTag,
    },

    mixins: [
        TaskStatusMixin,
    ],

    props: {
        companyUserId: {
            type: Number,
        },
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('status'),
                    key: 'display_status',
                    sortable: false,
                },
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                },
            ],
            currentId: null,
            companyUserSoftwares: new CompanyUserSoftwareCollection([
                'id',
                'company_user_id',
                'company_software_id',
                'status',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select(['id', 'datetime_start', 'status', 'datetime_end']);
                },
                companySoftware: (query) => {
                    query.select(['id']).with({
                        resource: (query) => {
                            query.select([
                                'id',
                                'name',
                                'description',
                            ]);
                        },
                    }).withTrashed(true);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]).orderBy([['name', 'asc']]),
        };
    },

    created() {
        this.companyUserSoftwares.get().then(() => {
            this.$emit('on-load', this.companyUserSoftwares.$.models.length);
        });
    },

    methods: {
        onUpdate() {
            this.companyUserSoftwares.get();
        },

        onDelete() {
            this.companyUserSoftwares.get();
        },

        openItemModal(row) {
            this.currentId = row.item.id;
            this.$refs.modalableUserItem.open();
        },
    },
};
</script>
<!-- TODO: check if needed -->
<style lang="scss" scoped>
    @import '~@/styles/var';

    .item-content.limit-width {
        max-width: 420px;

        span {
            word-wrap: break-word !important;
        }
    }

    .head {
        margin-top: 0.5em;
        font-family: $lato-bold;
        body:not(.old-ui) &{
            font-family: var(--poppins);
            font-weight: 600;
        }
    }
    .item-resource-icon {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .image-wrapper {
        height: 100%;
    }
</style>
