import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyRequirementCategory from './CompanyRequirementCategory';
import CompanyUserRequirement from './CompanyUserRequirement';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import CompanyUserRequirementGroup from './CompanyUserRequirementGroup';

export default class CompanyUserRequirementCategory extends Model {
    static STATUS_NOT_CONCERNED = 'not_concerned';

    static STATUS_DONE = 'done';

    static STATUS_PENDING_USER = 'pending_user';

    static STATUS_ARCHIVED = 'archived';

    modelConfig() {
        return {
            controller: 'CompanyUserRequirementCategoryController',
        };
    }

    modelInConvertor() {
        return {
            display_survey: (n) => Boolean(n),
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyRequirementCategory: () => this.hasOne(CompanyRequirementCategory, 'id', 'company_requirement_category_id'),
            companyUserRequirement: () => this.hasMany(CompanyUserRequirement, 'company_user_requirement_category_id', 'id'),
            companyUserRequirementGroup: () => this.hasMany(CompanyUserRequirementGroup, 'company_user_requirement_category_id', 'id'),
            tasks: () => this.hasMany(CompanyUserProgramTask, 'id', 'resource_id'),
        };
    }
}
