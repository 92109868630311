<template>
    <div class="user-requirement-edit">
        <component
            :is="component"
            :user-requirement="userRequimentModel"
            :requirement="userRequimentModel"
            :value="{html_content: userRequirement.value}"
            :is-preview="true"
            :show-only-file="true"
            :user-id="userId"
        />
    </div>
</template>

<script>
import UserRequirementFileToFillRead from '@/components/pages/onboarding/type/fileToFill/UserRequirementFileToFillRead.vue';
import CompanyUserRequirement from '@/models/CompanyUserRequirement';
import DynamicDocument from '@/components/globals/DynamicDocument.vue';
import UserRequirementTextRead from './type/UserRequirementTextRead.vue';
import UserRequirementFileToSignRead from './type/UserRequirementFileToSignRead.vue';
import UserRequirementStringRead from './type/UserRequirementStringRead.vue';
import UserRequirementDateRead from './type/UserRequirementDateRead.vue';
import UserRequirementAddressRead from './type/UserRequirementAddressRead.vue';
import UserRequirementSingleSelectRead from './type/UserRequirementSingleSelectRead.vue';
import UserRequirementMultipleSelectRead from './type/UserRequirementMultipleSelectRead.vue';
import UserRequirementIbanRead from './type/UserRequirementIbanRead.vue';
import UserRequirementFileRead from './type/file/UserRequirementFileRead.vue';
import UserRequirementHexapostRead from './type/UserRequirementHexapostRead.vue';
import UserRequirementPhoneRead from './type/UserRequirementPhoneRead.vue';

export default {
    name: 'UserRequirementRead',
    components: {
        UserRequirementTextRead,
        UserRequirementFileToSignRead,
        UserRequirementFileToFillRead,
        UserRequirementStringRead,
        UserRequirementDateRead,
        UserRequirementAddressRead,
        UserRequirementSingleSelectRead,
        UserRequirementMultipleSelectRead,
        UserRequirementIbanRead,
        UserRequirementFileRead,
        UserRequirementHexapostRead,
        DynamicDocument,
    },

    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
    },

    computed: {
        userRequimentModel() {
            if (this.userRequirement?.constructor?.name === 'CompanyUserRequirement') return this.userRequirement;
            const newUserRequirement = { ...this.userRequirement };
            newUserRequirement.value = (new CompanyUserRequirement(['id', 'type', 'value', 'question_multiple_value']))
                .fill(this.userRequirement)
                .modelInConvertor().value(this.userRequirement.value);
            return newUserRequirement;
        },
        component() {
            switch (this.userRequirement.type) {
            case 'text':
            case 'text_with_control':
            case 'social security number':
                return UserRequirementTextRead;
            case 'phone':
                return UserRequirementPhoneRead;
            case 'string':
                return UserRequirementStringRead;
            case 'file':
                return UserRequirementFileRead;
            case 'date':
                return UserRequirementDateRead;
            case 'iban':
                return UserRequirementIbanRead;
            case 'address':
                return UserRequirementAddressRead;
            case 'single_select':
                return UserRequirementSingleSelectRead;
            case 'multiple_select':
                return UserRequirementMultipleSelectRead;
            case 'file_to_sign':
                return UserRequirementFileToSignRead;
            case 'file_to_fill':
                return UserRequirementFileToFillRead;
            case 'hexapost':
                return UserRequirementHexapostRead;
            case 'dynamic_document':
                return DynamicDocument;
            default:
                return 'div';
            }
        },
    },

};
</script>

<style lang="scss" src="./UserRequirementEdit.scss" scoped />
