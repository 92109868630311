<template>
    <div
        class="form-message"
    >
        <ChatFormFiles
            v-if="canSendFile"
            ref="formFiles"
            v-model="files[channelActiveId]"
        />
        <div class="form-line">
            <HtFormExtensibleTextarea
                id="message"
                ref="message"
                v-model.trim="messages[channelActiveId]"
                v-validate.disable="hasFiles ? '' : 'required'"
                type="text"
                name="message"
                cypress="message"
                class="input-message"
                @onKeyupEnter="onSubmit"
            />
            <HtButton
                class="send-button"
                :disabled="!hasFiles && !messages[channelActiveId]"
                :loading="loading"
                @click.native="onSubmit"
            >
                <FontAwesomeIcon
                    :icon="['fal', 'paper-plane']"
                    class="send-icon"
                />
            </HtButton>
        </div>
        <div
            v-if="canSendFile || canSendChatByMail"
            class="form-line"
        >
            <div
                v-if="canSendFile"
                class="upload-buttons"
            >
                <HtButton
                    type="tertiary"
                    @click.native="uploadFiles"
                >
                    <FontAwesomeIcon :icon="['fal', 'paperclip']" />
                </HtButton>
                <HtButton
                    type="tertiary"
                    @click.native="openVideoModal"
                >
                    <FontAwesomeIcon :icon="['fal', 'video']" />
                </HtButton>
            </div>

            <div
                v-if="canSendChatByMail"
                class="switch-by-mail"
                :title="translate('Not available when sending files')"
            >
                <t>Send the next message by email too.</t>
                <HtFormSwitch
                    id="sendByMail"
                    v-model="sendByMail"
                    :show-optional="false"
                    :disabled="hasFiles"
                    name="sendByMail"
                    :tooltip="translate(
                        'The toggle is disabled because there are files waiting to be sent'
                    )"
                />
            </div>
        </div>
        <Modalable
            ref="formVideoModal"
            :options="{title: translate('Record a video')}"
            class="modal modalable-3"
            :mode="2"
            style="z-index:999999999"
        >
            <ChatFormVideo ref="formVideo" />
        </Modalable>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyChatChannelMessage from '@/models/CompanyChatChannelMessage';
import CompanyChatChannelCollection from '@/models/CompanyChatChannelCollection';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import HtFormTextarea from '@/components/globals/HtFormTextarea.vue';
import HtFormExtensibleTextarea from '@/components/globals/HtFormExtensibleTextarea.vue';
import ChatFormVideo from './ChatFormVideo.vue';
import HtButton from '../globals/HtButton.vue';
import ChatFormFiles from './ChatFormFiles.vue';
import NewChannelMixin from './mixins/NewChannelMixin';

export default {
    name: 'ChatFormMessage',
    permissions: [
        'AbstractSendFilesInChat',
        'AbstractSendChatByMail',
    ],
    components: {
        HtFormExtensibleTextarea,
        HtFormTextarea,
        ChatFormVideo,
        HtFormSwitch,
        HtButton,
        FontAwesomeIcon,
        ChatFormFiles,
    },
    mixins: [NewChannelMixin],
    props: {
        isAiBotChannel: {
            type: Boolean,
            default: false,
        },
    },
    shared: {
        socket: {
            channelCollection: {
                type: CompanyChatChannelCollection,
                default: null,
            },
            channelMessageCollection: {
                type: Object,
                default: {},
            },
        },
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },

    data() {
        return {
            messages: {},
            sendByMail: false,
            files: {},
            loading: false,
        };
    },
    computed: {
        channelActive() {
            if (!this.shared.socket.channelCollection) return null;
            return this.shared.socket.channelCollection.channelActive;
        },
        channelActiveId() {
            if (!this.channelActive) return null;
            return this.channelActive.id;
        },
        channelMessageObject() {
            return this.shared.socket.channelMessageCollection[this.channelActiveId];
        },
        hasFiles() {
            return Boolean(this.files[this.channelActiveId]?.length);
        },
        canSendFile() {
            return this.$canRead('AbstractSendFilesInChat') && this.isAiBotChannel === false;
        },
        canSendChatByMail() {
            return this.$canRead('AbstractSendChatByMail') && this.isAiBotChannel === false;
        },
    },
    watch: {
        channelActiveId() {
            this.setChannel();
        },
        hasFiles() {
            if (this.hasFiles) {
                this.sendByMail = false;
            }
        },
    },
    created() {
        this.setChannel();
    },
    methods: {
        openVideoModal() {
            this.$refs.formVideoModal.open();
        },
        duplicateChannel(channel) {
            const duplicatedChannel = this.shared.socket.channelCollection.new();
            duplicatedChannel.created_by_company_user_id = channel.created_by_company_user_id;

            for (let i = 0; i < channel.company_chat_channel_user.models.length; i += 1) {
                const channelUser = channel.company_chat_channel_user.models[i];
                duplicatedChannel.company_chat_channel_user.models.push(channelUser);
            }
            duplicatedChannel.type = channel.type;
            duplicatedChannel.name = channel.name;
            duplicatedChannel.avatar_image_id = channel.avatar_image_id;

            return duplicatedChannel;
        },
        onSubmit() {
            if (this.loading) return;

            this.$validator.validateAll()
                .then((result) => {
                    if (result) {
                        if (this.channelActive.id === 'new') {
                            this.saveNewChannel()
                                .then(() => {
                                    this.setChannel();
                                    this.$set(this.messages, this.channelActiveId, this.messages.new);
                                    delete this.messages.new;
                                    this.$set(this.files, this.channelActiveId, this.files.new);
                                    delete this.files.new;
                                    this.$nextTick(() => {
                                        this.sendMessage();
                                    });
                                });
                        } else {
                            this.sendMessage();
                        }
                    }
                });
        },
        sendMessage() {
            this.loading = true;

            const companyChatChannelMessage = new CompanyChatChannelMessage([
                'id', 'type', 'text', 'company_chat_channel_id', 'company_user_id',
            ]).with({
                companyUser: (query) => {
                    query.select([
                        'id', 'firstname', 'lastname', 'image',
                    ]);
                },
                files: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
            });

            // Date du message
            const createdAt = this.$Utils.moment()
                .utc()
                .toISOString();
            // Création et sauvegarde du message
            if (this.hasFiles && this.canSendFile) {
                companyChatChannelMessage.type = 'file';
                companyChatChannelMessage.files = this.files[this.channelActiveId];
            } else {
                companyChatChannelMessage.type = 'text';
            }
            if (this.messages[this.channelActiveId]) {
                companyChatChannelMessage.text = this.messages[this.channelActiveId];
            }
            companyChatChannelMessage.company_chat_channel_id = this.channelActiveId;
            companyChatChannelMessage.sendByMail = this.sendByMail;
            companyChatChannelMessage.enabledCapiChat = this.isAiBotChannel;
            companyChatChannelMessage.save()
                .then(() => {
                    // Reset du formulaire
                    this.sendByMail = false;

                    // Notification de message envoyé
                    this.$emit('onMessageSent', companyChatChannelMessage);
                })
                .catch(() => {
                    const failedMessage = this.channelMessageObject.messages.pop();
                    this.$set(this.files, this.channelActiveId, failedMessage.files?.models);
                    this.$set(this.messages, this.channelActiveId, failedMessage.text);
                })
                .finally(() => {
                    this.loading = false;
                });

            this.$validator.pause();
            this.$set(this.files, this.channelActiveId, []);
            this.$set(this.messages, this.channelActiveId, '');

            // Push du message dans le chat
            companyChatChannelMessage.company_user = this.shared.session.companyUser;
            companyChatChannelMessage.company_user_id = this.shared.session.companyUser.id;
            companyChatChannelMessage.created_at = createdAt;
            this.channelMessageObject.messages.push(companyChatChannelMessage);

            // Mise à jour du channel
            this.channelActive.last_message_at = createdAt;

            // Notification de message en cours d'envoi
            this.$emit('onMessageSending');
        },
        setChannel() {
            if (this.channelActiveId === null) return;

            if (!this.messages[this.channelActiveId]) {
                this.$set(this.messages, this.channelActiveId, '');
            }
        },
        uploadFiles() {
            if (this.canSendFile) this.$refs.formFiles.$children[0]?.open?.();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.form-message {
    padding: 10px 10px 0 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: transparent;
    margin: 0 20px 10px 10px;

    @media (max-width: $phone) {
        background-color: white;
        position: fixed;
        padding-bottom: 10px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        z-index: 40;
    }

    .input-message {
        width: 100%;
        flex-grow: 1;

        .ht-form-input-wrapper-field {
            padding-right: 50px !important;
        }
    }

    .send-button {
        position: absolute;
        right: 7px;
        padding: 8px;

        .send-icon {
            height: 16px;
            width: 16px;
        }

        ::v-deep .loading-icon {
            min-height: auto;
            height: 16px;
            width: 16px;
        }
    }

    .upload-buttons {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .form-line {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        ::v-deep .ht-form {
            margin-bottom: unset;
            position: relative;

            .ht-form-error {
                position: absolute;
                margin-top: 0 !important;
                font-size: 12px;
                top: -4px;
                transform: translateY(-100%);
            }
        }

        .switch-by-mail {
            flex-grow: 1;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;

            ::v-deep .switch {
                gap: 0;
            }
        }
    }

}
</style>
