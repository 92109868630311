import CompanyEmailTemplate from '@/models/CompanyEmailTemplate';

/**
 * @typedef {id: Number, name: String, type: String} Template
 * @param {{id: Number, key: String, value: String|Boolean, template: Template}[]} companyFields
 * @return {{name: String, label: String|Boolean}[]}
 */
export function getCustomFieldVariables(companyFields) {
    return companyFields.map((companyField) => ({
        name: `${CompanyEmailTemplate.CUSTOM_FIELD_VARIABLES_PREFIX}${companyField.key}_${companyField.template.id}`,
        label: companyField.template.name,
    }));
}
