<template>
    <div class="piloting-widget">
        <header class="widget-header">
            <span class="widget-title"><t>Tracking by user</t></span>
            <span class="widget-subtitle">
                <t>Track statistical details by program</t>
            </span>
        </header>
        <div class="widget-body">
            <HtTabs class="widget-tabs">
                <template #default>
                    <button
                        type="button"
                        class="tab"
                        :class="{active: selectedTab === 'late'}"
                        @click="selectedTab = 'late'"
                    >
                        {{ translate('Most late') }}
                    </button>
                    <button
                        type="button"
                        class="tab"
                        :class="{active: selectedTab === 'future'}"
                        @click="selectedTab = 'future'"
                    >
                        {{ translate('Futures newcomers') }}
                    </button>
                </template>
            </HtTabs>
            <UsersTrackingLate v-if="selectedTab === 'late'" />
            <UsersTrackingFuture v-if="selectedTab === 'future'" />
        </div>
        <button
            v-if="$can('PageDashboardPrograms')"
            type="button"
            class="widget-footer"
            @click="goToAll"
        >
            <t>Discover the rest of the users</t>
        </button>
    </div>
</template>

<script>

import HtTabs from '@/components/miscellaneous/HtTabs.vue';
import UsersTrackingLate from './UsersTrackingLate.vue';
import UsersTrackingFuture from './UsersTrackingFuture.vue';

export default {
    name: 'UsersTracking',
    components: {
        HtTabs,
        UsersTrackingLate,
        UsersTrackingFuture,
    },
    data() {
        return {
            selectedTab: 'late',
        };
    },
    methods: {
        goToAll() {
            if (this.selectedTab === 'late') {
                this.$store.dispatch('dashboard/programs/clearAllFilters');
                this.$store.dispatch('dashboard/transferFiltersToPrograms');
                this.$router.push({
                    name: 'ProgramsTracking',
                    query: {
                        status: ['late'],
                    },
                });
            } else if (this.selectedTab === 'future') {
                this.$store.dispatch('dashboard/programs/clearAllFilters');
                this.$store.dispatch('dashboard/transferFiltersToPrograms');
                this.$router.push({
                    name: 'ProgramsTracking',
                    query: {
                        status: ['invited'],
                    },
                });
            }
        },
    },

};
</script>

<style lang="scss" scoped src="./PilotingWidget.scss" />
