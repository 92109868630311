<template>
    <div
        v-if="isLoaded"
        class="full-width"
    >
        <HtAccordion
            ref="accordion"
            :title="translate('Email | Emails', {count: companyUserEmailCustomCollection.models.length})"
            :count="companyUserEmailCustomCollection.$.models.length"
            :has-button="companyUserEmailCustomCollection.$.models.length === 0"
            :button-label="addButtonLabel"
            :is-branding="false"
            :is-opened="missing"
            cypress="email"
            @buttonClick="openSelectModal()"
        >
            <template v-if="companyUserEmailCustomCollection.$.models.length">
                <CardList
                    :options="{
                        type: configs.type,
                        perRow: configs.perRow,
                    }"
                >
                    <Card
                        v-for="(item, index) in companyUserEmailCustomCollection.$.models"
                        :key="index"
                        :options="{
                            type: configs.type,
                            perRow: configs.perRow,
                        }"
                        :class="{'missing': (!item.sender_company_user_id || item.company_user_email_custom_recipient.models.length === 0)}"
                        :item="item"
                        :data-cy="`email-card-${index}`"
                        @click="openEditModal(item)"
                    >
                        <div slot="image">
                            <div class="item-image image-small">
                                <div
                                    class="small-image"
                                    :style="{'background-image': `url(/static/icons/email.svg)`}"
                                />
                            </div>
                        </div>

                        <div slot="title">
                            <t>{{ item.name }}</t>
                        </div>

                        <div
                            slot="extra"
                            class="email-recipients"
                        >
                            <ParticipantList
                                :users="item.company_user_email_custom_recipient.models"
                                has-group
                                :position="'right'"
                            />
                        </div>
                    </Card>
                </CardList>
                <div class="mt-4 text-center">
                    <Button
                        type="clear"
                        cypress="email-add-button"
                        @click="openSelectModal()"
                    >
                        <slot :name="'buttonText'">
                            {{ translate(addButtonLabel) }}
                        </slot>
                    </Button>
                </div>
            </template>

            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No Emails added</t>
                </strong>
            </div>
        </HtAccordion>

        <modalable
            ref="modalableSelect"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserEmailCustomSelect
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                :selected-ids="selectedIds"
                @onUpdate="onUpdate"
            />
        </modalable>

        <modalable
            ref="modalableEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserEmailCustomEdit
                :id="currentId"
                :from="'bop'"
                :intelligent-selection="intelligentSelection"
                :company-user-program-id="companyUserProgramId"
                :company-user-id="companyUserId"
                :variables="availableVariables"
                @onUpdate="onUpdate"
                @onDelete="onDelete"
            />
        </modalable>
    </div>
</template>

<script>
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserEmailCustomSelect from '@/components/pages/dashboard/modals/emailCustom/UserEmailCustomSelect.vue';
import UserEmailCustomEdit from '@/components/pages/dashboard/modals/emailCustom/UserEmailCustomEdit.vue';
import CompanyUserEmailCustomCollection from '@/models/CompanyUserEmailCustomCollection';

import moment from 'moment';
import CompanyRole from '@/models/CompanyRole';
import CompanyUser from '@/models/CompanyUser';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import { getEntityVariables } from '@/helpers/models/CompanyEntity';
import { getCustomFieldVariables } from '@/helpers/models/CompanyField';

export default {
    name: 'UserEmailCustomFlow',
    components: {
        HtAccordion,
        UserEmailCustomSelect,
        UserEmailCustomEdit,
    },
    props: {
        options: {
            type: Object,
            default: () => {
            },
        },
        companyUserId: {
            type: [Number, String],
            required: true,
        },
        companyUserProgramId: {
            type: [Number, String],
            required: true,
        },
        intelligentSelection: {
            type: Array,
            required: false,
        },
        missing: {
            type: Boolean,
            default: false,
        }, // This determines if all events have organisers
    },

    data() {
        return {
            configs: {
                ...{
                    title: 'Company Email Templates',	// Title of SectionBar
                    showAddButton: true,				// Show add button of SectionBar,
                    type: 'long',
                    perRow: 3,							// How many cards per row
                },
                ...this.options,
            },
            currentId: null,
            addButtonLabel: 'Add email',
            companyUserEmailCustomCollection: new CompanyUserEmailCustomCollection([
                'id', 'name', 'company_email_custom_template_id', 'sender_company_user_id', 'permissions',
            ])
                .with({
                    companyUserEmailCustomRecipient: (query) => {
                        query.select(['id', 'company_user_email_custom_id', 'company_role_id', 'company_group_id', 'company_user_id'])
                            .with({
                                companyUser: (query) => {
                                    query.select(['id', 'firstname', 'lastname', 'email', 'image', 'professional_email']);
                                },
                                companyGroup: (query) => {
                                    query.select(['id', 'name', 'alias']);
                                },
                            });
                    },
                    companyUserSender: (query) => {
                        query.select(['id', 'firstname', 'lastname', 'email', 'image', 'professional_email']);
                    },
                })
                .where([
                    ['company_user_program_id', '=', this.companyUserProgramId],
                ]),

            companyUser: new CompanyUser([
                'id',
                'firstname',
                'lastname',
                'arrival_date',
            ]).with({
                company: (query) => {
                    query.select(['name']);
                },
                companyDepartment: (query) => {
                    query.select(['name']);
                },
                companyOffice: (query) => {
                    query.select(['name']);
                },
                companyRoleUserEmployee: (query) => {
                    query
                        .select([
                            'id',
                            'company_role_user_id',
                        ])
                        .with({
                            roleUser: (query) => {
                                query
                                    .select([
                                        'id',
                                        'company_user_id',
                                        'company_role_id',
                                    ])
                                    .with({
                                        user: (query) => {
                                            query.select(['firstname', 'lastname']);
                                        },
                                        role: (query) => {
                                            query.select(['name', 'alias']);
                                        },
                                    });
                            },
                        });
                },
            })
                .where([['id', '=', this.companyUserId]]),

            companyUserProgram: new CompanyUserProgram([
                'id',
                'company_program_id',
            ]).with({
                companyUser: (query) => {
                    query.with({
                        customFields: (query) => {
                            query.select([
                                'id',
                                'key',
                                'text',
                                'value',
                                'boolean',
                                'company_field_template_id',
                            ])
                                .with({
                                    template: (query) => {
                                        query.select([
                                            'id',
                                            'type',
                                            'name',
                                        ]);
                                    },
                                });
                        },
                    });
                },
                mainKeyDate: (query) => {
                    query.select(['starts_at']);
                },
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.select(['id', 'company_program_id'])
                                    .with({
                                        locales: (query) => query
                                            .select(['id', 'name', 'language_key']),
                                    });
                            },
                            locales: (query) => {
                                query.select(['id', 'name', 'language_key']);
                            },
                            entities: (query) => {
                                query.with({
                                    entity: (query) => {
                                        query.select(['id', 'slug', 'name']);
                                    },
                                });
                            },
                        });
                },
            })
                .where([['id', '=', this.companyUserProgramId]]),
        };
    },

    computed: {
        isLoaded() {
            return this.companyUserEmailCustomCollection.isLoaded() && this.companyUserProgram.isLoaded();
        },

        selectedIds() {
            return this.companyUserEmailCustomCollection.models.map((item) => item.company_email_custom_template_id);
        },

        availableVariables() { // EMAIL VARIABLE DEFINITIONS
            const { companyUser } = this;
            /** @type {CompanyRoleUser[]} */
            const supervisors = companyUser.company_role_user_employee.models.map((company_role_user_employee) => company_role_user_employee.role_user);

            const keyDateType = this.companyUserProgram.company_program.key_date.locales.models.filter((locale) => locale.language_key === this.shared.session.company.company_language.key)[0].name;

            const programName = this.companyUserProgram.company_program.locales.models.filter((locale) => (locale.language ? (locale.language.key === this.shared.session.company.company_language.key) : []))[0].name;

            const buddy = supervisors.find((supervisor) => supervisor.role.name === CompanyRole.NAME_BUDDY);
            const manager = supervisors.find((supervisor) => supervisor.role.name === CompanyRole.NAME_MANAGER);
            const adminResp = supervisors.find((supervisor) => supervisor.role.name === CompanyRole.NAME_ADMINISTRATIVE_MANAGER);

            const defaultVariables = [
                ...(companyUser ? [{
                    name: 'enrollee_firstname',
                    label: 'Enrollee firstname',
                    value: companyUser.firstname,
                }] : []),
                ...(companyUser ? [{
                    name: 'enrollee_lastname',
                    label: 'Enrollee lastname',
                    value: companyUser.lastname,
                }] : []),
                ...(this.companyUserProgram ? [{
                    name: 'key_date',
                    label: 'Key date',
                    value: moment(this.companyUserProgram.datetime_start)
                        .format('DD MMMM'),
                }] : []),
                ...(keyDateType ? [{
                    name: 'key_date_type',
                    label: 'Key date type',
                    value: keyDateType,
                }] : []),
                ...(programName ? [{
                    name: 'Program name',
                    label: 'Program name',
                    value: programName,
                }] : []),
                ...(buddy ? [{
                    name: 'buddy_name',
                    label: 'Buddy name',
                    value: `${buddy.user.firstname} ${buddy.user.lastname}`,
                }] : []),
                ...(manager ? [{
                    name: 'manager_name',
                    label: 'Manager name',
                    value: `${manager.user.firstname} ${manager.user.lastname}`,
                }] : []),
                ...(companyUser.company_department ? [{
                    name: 'department',
                    label: 'Department name',
                    value: companyUser.company_department.name,
                }] : []),
                ...(companyUser.company_office ? [{
                    name: 'site_location',
                    label: 'Site location',
                    value: companyUser.company_office.name,
                }] : []),
                ...(companyUser.company ? [{
                    name: 'company_name',
                    label: 'Company name',
                    value: companyUser.company.name,
                }] : []),
                ...(adminResp ? [{
                    name: 'admin_responsible_name',
                    label: 'Admin responsible name',
                    value: `${adminResp.user.firstname} ${adminResp.user.lastname}`,
                }] : []),
            ];

            const customFieldsVariables = getCustomFieldVariables(
                this.companyUserProgram.company_user.custom_fields.models,
            );

            const entityVariables = getEntityVariables(
                this.companyUserProgram.company_program.entities.models,
            );

            return [
                ...defaultVariables,
                ...entityVariables,
                ...customFieldsVariables,
            ];
        },
    },

    async created() {
        this.loadResource();
        this.companyUser.get();
        this.companyUserProgram.get();
    },

    methods: {
        loadResource() {
            this.companyUserEmailCustomCollection.get()
                .then(() => {
                    const hasMissingSender = this.companyUserEmailCustomCollection.models.some((email) => !email.sender_company_user_id || email.company_user_email_custom_recipient.models.length === 0);
                    this.$emit('update:missing', hasMissingSender);
                });
        },

        openEditModal(item) {
            this.currentId = item.id;
            this.$refs.modalableEdit.open();
        },

        openSelectModal() {
            this.currentId = null;
            this.$refs.modalableSelect.open();
        },

        openAccordion() {
            this.$refs.accordion.open();
        },

        onUpdate() {
            this.loadResource();
        },

        onDelete() {
            this.loadResource();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.missing {
    border-color: $primary-light !important;
}

.email-recipients {
    margin-left: 1.5em;
}

.notification-section {
    margin-top: 2em;
}

.notification-section-label {
    font-family: $lato-bold;
    font-size: 2rem;

    body:not(.old-ui) & {
        font-family: var(--poppins);
    }
}
</style>
