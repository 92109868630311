<template>
    <div>
        <transition leave-active-class="leave-fade-dashboard">
            <ActionProgramListItems
                :title="title"
                :status="status"
                :action="item"
                :is-togglable="isTogglable"
                :show-multi-select="showMultiSelect"
                :is-selectable="isSelectable"
                :is-selected="isSelected"
                :can-validate="canValidate"
                :is-clickable="isClickable"
                :cypress="cypress"
                :task="item"
                :subtask="true"
                @onSelectedAction="onSelectedAction"
                @onToggle="onToggle"
                @onClick="onClick"
            />
        </transition>
        <NewUserWrapperModal
            ref="userWrapperModal"
            :size="getSize"
            :is-edit="isEditable"
            :cypress="cypress"
            @onUpdate="onUpdate"
            @onDelete="onDelete"
        />
    </div>
</template>

<script>
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import ActionProgramListItems from '@/components/pages/actions/program/ActionProgramListItems.vue';
import NewUserWrapperModal from '@/components/miscellaneous/NewUserWrapperModal.vue';
import CypressMixin from '@/mixins/CypressMixin';

export default {
    name: 'ActionProgramTaskWrapperItem',
    components: {
        ActionProgramListItems,
        NewUserWrapperModal,
    },
    mixins: [CypressMixin],
    props: {
        from: {
            type: String,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedActionList: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            groupedToggled: false,
        };
    },

    computed: {
        title() {
            return this.item.full_title;
        },

        participants() {
            return this.item.company_user_program_task_participant.models;
        },

        getSize() {
            let size = null;

            switch (this.item.resource) {
            case 'company_planning':
            case 'company_document':
            case 'company_doc':
            case 'company_software':
            case 'company_equipment':
            case 'company_requirement_category':
            case 'custom':
                size = (this.from === 'bop') ? 'medium' : 'small';
                break;
            case 'company_quiz':
            case 'company_user_quiz':
                if (this.from === 'bop') {
                    size = 'medium';
                } else {
                    size = this.isFillModal === true ? 'large' : 'small';
                }
                break;
            default:
                size = 'medium';
            }

            return size;
        },

        canValidate() {
            return !(this.item.resource === 'company_requirement_category' && this.item.company_user_requirement_category.display_survey === true);
        },

        status() {
            return this.item.status;
        },

        isOwner() {
            return this.item.company_user_id == this.shared.session.companyUser.id;
        },

        isEditable() {
            return this.from != 'bop';
        },

        isTogglable() {
            if (this.from != 'bop') {
                switch (this.item.resource) {
                // THESE RESOURCES DO NOT HAVE 1-CLICK VALIDATION
                case 'company_requirement_category':
                case 'company_email':
                case 'company_email_custom':
                case 'company_survey':
                    return false;
                case 'company_document':
                    return this.isOwner || this.canModify;
                case 'custom':
                    return this.participants.length === 0 || this.isTaskParticipant || this.canModify;
                default:
                    return this.isTaskParticipant || this.canModify;
                }
            }
            return false;
        },

        isTaskParticipant() {
            return this.item.company_user_program_task_participant.models.some(
                (participant) => participant.company_user_id == this.shared.session.companyUser.id,
            );
        },

        isSelectable() {
            const { resource } = this.item;
            const event = this.item.company_planning_event;

            if (resource === 'company_planning' && event.is_delegated === true) return false;

            return resource !== 'company_software'
                && resource !== 'company_survey'
                && resource !== 'company_requirement_category'
                && resource !== 'company_user_quiz'
                && resource !== 'company_email_custom';
        },

        isSelected() {
            return this.selectedActionList.includes(this.item.id);
        },

        isRequirementViaSurveyPending() {
            const { resource } = this.item;
            const requirement = this.item.company_user_requirement_category;

            return (resource === 'company_requirement_category' && requirement.display_survey === true && this.item.status !== CompanyUserProgramTask.STATUS_DONE);
        },

        isClickable() {
            return !this.isRequirementViaSurveyPending;
        },

        getTagLabel() {
            if (this.isRequirementViaSurveyPending) {
                return this.translate('Via survey');
            }

            return null;
        },
    },

    methods: {
        onSelectedAction() {
            if (this.selectedActionList.includes(this.item.id)) {
                this.selectedActionList.splice(this.selectedActionList.indexOf(this.item.id), 1);
            } else {
                this.selectedActionList.push(this.item.id);
            }
        },

        onToggle(status) {
            this.item.status = status ? 'done' : 'pending';

            const currentTask = new CompanyUserProgramTask([
                'id',
                'status',
            ]);
            currentTask.fill(this.item);

            this.$handleValidationTasks(currentTask);
        },

        onClick() {
            if (!this.showMultiSelect) {
                this.$refs.userWrapperModal.open(this.item);
            }
        },

        onDelete() {
            this.shared.program.removeIncrementTask++;
        },

        onUpdate() {
            this.shared.program.updateIncrementTask++;
        },
    },
};
</script>
