import moment from 'moment';

/**
 * @param {String} value - May come from user input
 * @param {String} language
 * @returns {String}
 */
export function parseDateByValueAndLanguage(
    value,
    language,
) {
    const currentLocale = moment.localeData(language);
    const currentFormatByLocale = currentLocale.longDateFormat('L');

    return moment(
        getCurrentDateFromInputValue(value),
        currentFormatByLocale,
        language,
    )
        .format('YYYY-MM-DD');
}

/**
 * @param {String} value - May come from user input
 * @param {String} language
 * @param {String} format
 * @returns {String}
 */
export function parseDateByValueAndLanguageWithSpecificFormat(
    value,
    language,
    format,
) {
    return moment(
        getCurrentDateFromInputValue(value),
        format,
    )
        .locale(language)
        .format('L');
}

/**
 * note: in case the value is empty we apply the current date
 * @param {String} value - can be empty
 * @returns {moment.Moment|String}
 */
export function getCurrentDateFromInputValue(value) {
    return value || moment();
}
