<template>
    <HtCard
        class="ht-tabs"
        :no-body="noBody"
    >
        <template #title>
            <div class="ht-tabs-list">
                <div
                    v-for="(tab, i) in tabs"
                    :key="i"
                    class="ht-tabs-list-item"
                    :class="customClass(tab)"
                    @click="setActive(tab)"
                >
                    {{ tab.title }}
                </div>
            </div>
            <div class="headerRightContent">
                <slot name="headerRightContent" />
            </div>
        </template>

        <template #default>
            <slot />
        </template>

        <template
            v-if="hasFooterSlot"
            #footer
        >
            <slot name="footer" />
        </template>
    </HtCard>
</template>

<script>
import HtCard from './HtCard.vue';

export default {

    name: 'HtTabs',
    components: { HtCard },

    provide() {
        return {
            tabs: this,
        };
    },
    data() {
        return {
            tabs: [],
        };
    },
    computed: {
        activeTab() {
            return this.tabs.find((t) => t.active);
        },
        noBody() {
            return this.activeTab ? this.activeTab.noBody : false;
        },
        hasFooterSlot() {
            return 'footer' in this.$slots;
        },
    },
    watch: {
        'activeTab.disabled': function (value) {
            if (value === true) {
                const newTabActive = this.tabs.find((t) => t.disabled === false);

                if (newTabActive) {
                    this.setActive(newTabActive);
                }
            }
        },
    },
    methods: {
        customClass(tab) {
            return {
                active: tab.active,
                disabled: tab.disabled,
                'branding-color': tab.active,
                'pseudo-border-bottom': tab.active,
            };
        },

        addTab(tab) {
            this.tabs.push(tab);

            tab.active = this.activeTab === undefined && tab.disabled === false;
        },

        removeTab(tab) {
            if (tab.active) {
                const newTabActive = this.tabs.find((t) => t._uid !== tab._uid && t.disabled === false);

                if (newTabActive) {
                    this.setActive(newTabActive);
                }
            }

            const indexTab = this.tabs.findIndex((t) => t._uid === tab._uid);

            this.tabs.splice(indexTab, 1);
        },

        changeTab(tab) {
            if (tab.disabled) {
                return;
            }

            this.tabs.forEach((tab) => {
                tab.active = false;
            });

            tab.active = true;
        },

        /**
         * @param {null|{onChangeTab?: Function, disabled?: bool, active?: bool}} tab
         * @returns {Promise<void>|void}
         */
        setActive(tab = null) {
            if ('onChangeTab' in this.$listeners) {
                return new Promise((resolve) => {
                    this.$emit('onChangeTab', resolve);
                }).then(() => {
                    this.changeTab(tab);
                });
            }

            this.changeTab(tab);
        },
    },
};
</script>

<style lang="scss" scoped src="./HtTabs.scss"></style>
