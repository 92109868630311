import debounce from 'lodash.debounce';
import CompanyFile from '@/models/CompanyFile';
import I18n from '@/modules/i18n/I18n';
import api from '@/store/api';
import { showNotification } from '@/helpers/ui';
import FieldValidator from '../FieldValidator';

export default {
    data() {
        return {
            adminEditor: false,
            selectedPage: 1,
            pdfFormTemplate: null,
            startResize: false,
            parentHeight: 0,
            before_width: 0,
            before_height: 0,
            itemOnDrag: null,
            resizeObserver: null,
            timeOutFunction: null,
            fieldTypes: [],
            items: [],
            cursorPos: {
                x: 0,
                y: 0,
            },
            scale: 100,
        };
    },

    props: {
        documentId: {
            type: Number,
        },
        companyFile: {
            type: [CompanyFile, Object],
            required: true,
        },
    },

    async created() {
        this.loading = true;
        if (this.documentId) {
            new Promise((resolve, reject) => {
                api.pdfForm.get(this.route, this.documentId)
                    .then((response) => {
                        if ('file_translations' in response.data.data && !response.data.data.file_translations[this.editingLang]) {
                            api.pdfForm.addFileTranslation(this.route, this.documentId, {
                                company_file_id: this.companyFile.id,
                                lang: this.editingLang,
                            })
                                .then((response) => {
                                    resolve(response.data.data);
                                })
                                .catch(reject);
                        } else {
                            resolve(response.data.data);
                        }
                    });
            })
                .then(this.fetchCallback)
                .finally(() => {
                    this.loading = false;
                });
        } else {
            api.pdfForm.create(this.route, {
                company_file_id: this.companyFile.id,
                lang: this.editingLang,
            })
                .then((response) => {
                    this.fetchCallback(response.data.data);
                    this.$emit('update:documentId', this.pdfFormTemplate.id);
                })
                .catch(this.onErrors)
                .finally(() => {
                    this.loading = false;
                });
        }
    },

    computed: {
        imgHeight() {
            if (!document.querySelector('#dropzone img')) {
                return 0;
            }
            return document.querySelector('#dropzone img').offsetHeight;
        },
    },
    watch: {
        parentHeight(value) {
            if (value) {
                this.attachObserver();
            }
        },
        scale() {
            this.onResize();
        },
    },
    methods: {
        onErrors(err) {
            if ('message' in err) {
                showNotification(I18n.translate(err.message), 'error');
            } else {
                showNotification(
                    I18n.translate('A problem occurred while trying to update this user'),
                    'error',
                );
            }
            this.modal.close();
        },
        onPageChange() {
            this.startResize = true;
            this.resizePage();
        },
        attachObserver() {
            if (this.resizeObserver !== null) {
                return;
            }

            this.resizeObserver = new ResizeObserver(this.onResize);
            this.resizeObserver.observe(document.querySelector('#dropzone img'));
        },
        getRatios() {
            const elem = document.querySelector('#dropzone img');
            const ratioW = elem.offsetWidth / this.editingSizes.edited_width;
            const ratioH = elem.offsetHeight / this.editingSizes.edited_height;
            return {
                ratioW,
                ratioH,
            };
        },
        getFontRatio() {
            const elem = document.querySelector('#dropzone img');

            if (elem && this.pdfFormTemplate) {
                const fontRatio = (elem.offsetHeight - this.pdfSizes.height) / this.pdfSizes.height;
                return (Math.sign(fontRatio) > 0 ? fontRatio + 1 : 1 - Math.abs(fontRatio))
                    * (this.scale / 100);
            }

            return 0;
        },
        onResize: debounce(function () {
            this.startResize = true;
            clearTimeout(this.timeOutFunction);
            this.timeOutFunction = setTimeout(this.resize, 500);
        }, 1),
        resizeFields({ fields }) {
            for (const field of fields) {
                this.resizeField({
                    field,
                });
            }
        },
        async resizeField({ field }) {
            await this.checkSizeIsSet();
            Object.assign(field, {
                left_position: field.left_position * this.getRatios().ratioW,
                top_position: field.top_position * this.getRatios().ratioH,
                font_ratio: this.getFontRatio(),
            });
        },
        endResize() {
            this.$nextTick(() => {
                const elem = document.querySelector('#dropzone img');

                if (elem) {
                    this.refreshSizes(elem);
                }
            });
        },
        resize: debounce(function () {
            if (this.pdfFormTemplate !== null) {
                // eslint-disable-next-line guard-for-in
                for (const [page] in this.documentFields) {
                    this.resizeFields({
                        fields: this.documentFields[page],
                    });
                }
                this.endResize();
            }
        }, 200),
        resizePage: debounce(function () {
            setTimeout(this.resizeFields({
                fields: this.documentFields[this.selectedPage - 1],
            }), 5000);

            this.endResize();
        }, 200),
        getPosition(evt) {
            const rect = evt.target.getBoundingClientRect();
            this.cursorPos = {
                x: evt.clientX - rect.left,
                y: evt.clientY - rect.top,
            };
        },
        setItems() {
            this.items = Object.keys(this.fieldTypes)
                .map((type) => ({
                    id: 0,
                    name: this.fieldTypes[type].name,
                    title: this.translate(this.fieldTypes[type].name),
                    hint: this.translate(this.fieldTypes[type].hint),
                    type,
                    is_mandatory: false,
                    completer_id: null,
                    width: this.fieldTypes[type].width,
                    height: this.fieldTypes[type].height,
                    allowed: this.fieldTypes[type].isEnrolleeAllowed,
                }))
                .filter((type) => {
                    if (!this.adminEditor) {
                        return type.allowed;
                    }
                    return type.name !== 'Variable';
                });
        },
        startDrag(evt, item) {
            // eslint-disable-next-line no-param-reassign
            evt.dataTransfer.dropEffect = 'move';
            // eslint-disable-next-line no-param-reassign
            evt.dataTransfer.effectAllowed = 'move';
            evt.dataTransfer.setData('itemID', (Math.random() + 1).toString(36)
                .substring(7));
            this.itemOnDrag = {
                ...item,
                uuid: this.$Utils.uniqId(),
                rule_params: {},
            };
        },
        onDrop(evt, pageKey) {
            const pos = {
                left_position: evt.layerX - this.cursorPos.x,
                top_position: evt.layerY - this.cursorPos.y,
            };
            const fields = this.documentFields;
            const items = fields.find((elem, index) => index === pageKey);
            const owner_id = this.shared.session.companyUser.id;
            items.push({
                ...this.itemOnDrag,
                ...pos,
                font_ratio: this.getFontRatio(),
                owner_id,
                id: null,
                page_number: pageKey,
            });

            this.itemOnDrag = null;
        },
        remove({
            pageKey,
            key,
        }) {
            this.startResize = true;
            const pageFields = this.documentFields;
            const items = pageFields.find((elem, index) => index === pageKey);
            const filtered = items.filter((elem, index) => index !== key);
            this.$set(pageFields, pageKey, filtered);
            this.$set(this, 'documentFields', pageFields);

            this.$nextTick(() => {
                this.startResize = false;
            });
        },
        mandatorize({
            pageKey,
            key,
        }) {
            const pageFields = this.documentFields;
            const items = pageFields.find((elem, index) => index === pageKey);

            items[key].is_mandatory = !items[key].is_mandatory;

            this.$set(pageFields, pageKey, items);
            this.$set(this, 'documentFields', pageFields);
        },

        setSpecificRule({
            v,
            pageKey,
            key,
        }) {
            const pageFields = this.documentFields;
            const items = pageFields.find((elem, index) => index === pageKey);

            if (!items[key].rule_params) {
                items[key].rule_params = {};
            }
            FieldValidator.specificTextRules()
                .forEach((r) => {
                    this.$set(items[key].rule_params, r.key, null);
                    delete items[key].rule_params[r.key];
                });

            if (v) {
                this.$set(items[key].rule_params, v, '');
            }

            this.$set(pageFields, pageKey, items);
            this.$set(this, 'documentFields', pageFields);
        },

        setTitle({
            v,
            pageKey,
            key,
        }) {
            const pageFields = this.documentFields;
            const items = pageFields.find((elem, index) => index === pageKey);

            items[key].title = v;

            this.$set(pageFields, pageKey, items);
            this.$set(this, 'documentFields', pageFields);
        },

        setRole({
            roleId,
            pageKey,
            key,
        }) {
            const pageFields = this.documentFields;
            const items = pageFields.find((elem, index) => index === pageKey);

            items[key].completer_id = roleId;

            this.$set(pageFields, pageKey, items);
            this.$set(this, 'documentFields', pageFields);
        },

        setRule({
            ruleInfos,
            pageKey,
            key,
        }) {
            const pageFields = this.documentFields;
            const items = pageFields.find((elem, index) => index === pageKey);

            if (!items[key].rule_params) {
                items[key].rule_params = {};
            }

            if (!ruleInfos?.value || ruleInfos.value == '') {
                this.$set(items[key].rule_params, ruleInfos.key, null);
                delete items[key].rule_params[ruleInfos.key];
            } else {
                this.$set(items[key].rule_params, ruleInfos.key, ruleInfos.value);
            }

            this.$set(pageFields, pageKey, items);
            this.$set(this, 'documentFields', pageFields);
        },

        async checkSizeIsSet() {
            const timeout = (ms) => new Promise((resolve) => {
                setTimeout(resolve, ms);
            });

            if (
                document.querySelector('#dropzone img')
                && document.querySelector('#dropzone img').offsetWidth
                && document.querySelector('#dropzone img').offsetHeight
                && this.pdfFormTemplate
                && this.editingSizes.edited_width
                && this.editingSizes.edited_height
            ) {
                return true;
            }
            await timeout(500);
            return this.checkSizeIsSet();
        },
    },
};
