<template>
    <div>
        <ResultRequirementItem
            v-for="answer in answers"
            :key="answer.id"
            class="item"
            :firstname="answer.participant.firstname"
            :lastname="answer.participant.lastname"
            :image="answer.participant.image"
            @click="onClick(answer)"
        />
    </div>
</template>

<script>
import freeResultsProps from '../freeResultsProps';
import ResultRequirementItem from './ResultRequirementItem.vue';

export default {
    name: 'ResultsRequirement',
    components: { ResultRequirementItem },

    mixins: [
        freeResultsProps,
    ],

    data() {
        return {
            clicked: false,
        };
    },

    methods: {
        /**
         * @param {Object} answer
         */
        onClick(answer) {
            this.$emit('click-requirement-result', answer);
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.item {
    &:not(:last-child) {
        margin-bottom: 2%;
    }
    cursor: pointer;
    &:hover {
        border: 1px solid $grey-1;
    }
}
</style>
