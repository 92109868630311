<template>
    <div v-if="permissionsLoaded">
        <template v-if="isCollectionLoaded">
            <div class="profile-team-list">
                <div
                    v-for="(item, index) in teamList"
                    :key="`current-${index}`"
                    class="profile-team-list-item"
                >
                    <div
                        v-if="item.company_user_id"
                        class="profile-team-item"
                        @click="$router.push(`/Profile/${item.user.id}`)"
                    >
                        <HtAvatar
                            :user="$Utils.getAvatarData(item.user)"
                        />
                        <div class="team-item-content">
                            <div class="team-item-name">
                                {{ item.user.firstname }} {{ item.user.lastname.toUpperCase() }}
                            </div>
                            <div class="team-item-role">
                                {{ canEdit ? item.role.alias_translated : item.role }}
                            </div>
                        </div>
                        <div
                            v-if="!loading"
                            class="team-item-icons"
                        >
                            <button
                                v-if="canEdit"
                                @click="remove($event, item)"
                            >
                                <HtIcon
                                    size="20"
                                    stroke-width="2"
                                    name="xmark"
                                />
                            </button>
                            <button
                                v-if="canEdit"
                                @click="change($event, item)"
                            >
                                <HtIcon
                                    size="20"
                                    stroke-width="2"
                                    name="edit-pencil"
                                />
                            </button>
                            <HtIcon
                                size="20"
                                stroke-width="2"
                                name="nav-arrow-right"
                            />
                        </div>
                    </div>
                    <button
                        v-else
                        class="profile-team-add"
                        @click="change($event, item)"
                    >
                        <HtIcon
                            stroke-width="2"
                            name="add-circle"
                        />

                        <div
                            class="team-role-text"
                        >
                            <t>Add</t>
                            <span v-if="item.role"> {{ item.role.alias_translated }}</span>
                        </div>
                    </button>
                </div>
                <div
                    v-if="teamList.length === 0"
                    class="empty-component"
                >
                    <t>No team</t>
                </div>
            </div>
        </template>
        <template v-else>
            <HtIconLoading />
        </template>
    </div>
</template>
<script>
import modalMixin from '@/components/globals/modals/modalMixin';
import CompanyRoleUserCollection from '@/models/CompanyRoleUserCollection';
import CompanyRoleCollection from '@/models/CompanyRoleCollection';
import CompanyRole from '@/models/CompanyRole';
import api from '@/store/api';

export default {
    name: 'ProfileTeamList',

    permissions: [
        'ModelCompanyUser',
    ],

    mixins: [
        modalMixin,
    ],

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: false,
            companyRoleCollection: new CompanyRoleCollection([
                'id', 'name', 'is_heyteam', 'alias', 'is_guest',
            ])
                .where([
                    ['name', '!=', CompanyRole.NAME_EMPLOYEE],
                    ['name', '=', null, 'or'],
                ]).where([
                    ['is_assignable_program', '=', true],
                ]),
            companyRoleUserCollection: new CompanyRoleUserCollection([
                'id',
                'company_user_id',
                'company_role_id',
            ]).with({
                user: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']);
                    query.with({
                        fromDelegations: (query) => {
                            query.select(['id', 'from_user_id', 'to_user_id', 'start_date', 'end_date']);
                        },
                    });
                },
                role: (query) => {
                    query.select(['id', 'name', 'alias', 'is_heyteam', 'is_guest']);
                },
            }).whereHas({
                employees: (query) => {
                    query.where([
                        ['company_user_id', '=', this.companyUserId],
                    ]);
                },
            }),
        };
    },
    computed: {
        isCollectionLoaded() {
            if (this.canEdit) {
                return this.companyRoleUserCollection.isLoaded()
                    && this.companyRoleCollection.isLoaded();
            }

            return this.companyRoleUserCollection.isLoaded();
        },

        getTeamWithoutEmptyRole() {
            const supervisors = [];

            if (this.isCollectionLoaded) {
                this.companyRoleUserCollection.$.models.each((roleUser) => {
                    const member = supervisors.find((supervisor) => supervisor.company_user_id === roleUser.company_user_id);
                    if (member) {
                        const role = roleUser.role.alias_translated;
                        member.role += `, ${role}`;
                    } else {
                        const role = roleUser.role.alias_translated;
                        supervisors.push({
                            ...roleUser.toObject(),
                            role,
                            user: {
                                ...roleUser.user.toObject(),
                                from_delegations: roleUser.user.fromDelegations?.toArray(),
                            },
                        });
                    }
                });
            }

            return supervisors;
        },

        getTeamWithEmptyRoles() {
            const supervisors = [];

            if (this.isCollectionLoaded) {
                this.companyRoleCollection.$.models.each((role) => {
                    const currentSupervisor = this.companyRoleUserCollection.$.models.find((roleUser) => roleUser.company_role_id === role.id);

                    if (!currentSupervisor) {
                        supervisors.push({
                            company_role_id: role.id,
                            role: {
                                ...role.toObject(),
                                alias_translated: role.is_heyteam === true ? this.translate(role.alias) : role.alias,
                            },
                            company_user_id: null,
                        });
                    } else {
                        supervisors.push({
                            ...currentSupervisor.toObject(),
                            role: {
                                ...currentSupervisor.role.toObject(),
                                alias_translated: role.is_heyteam === true ? this.translate(role.alias) : role.alias,
                            },
                            user: {
                                ...currentSupervisor.user.toObject(),
                                from_delegations: currentSupervisor.user.fromDelegations?.toArray(),
                            },
                        });
                    }
                });
            }

            return supervisors;
        },

        teamList() {
            return this.canEdit ? this.getTeamWithEmptyRoles : this.getTeamWithoutEmptyRole;
        },
    },

    created() {
        if (this.canEdit) {
            this.companyRoleCollection.get();
        }
        this.getSupervisors();
    },

    methods: {
        getSupervisors() {
            this.companyRoleUserCollection.get();
        },

        // Button actions
        change(e, item) {
            e.stopPropagation();

            if (this.loading === true) {
                return;
            }

            this.$emit('change', item);
        },

        remove(e, item) {
            e.stopPropagation();

            if (this.loading === true) {
                return;
            }

            this.genericConfirm(
                this.translate('Are you sure you want to delete this role ?'),
                this.translate(
                    'By deleting it, all pending task attributed to this role will be deleted. You will still be able to manually add them.',
                ),
            ).then(() => {
                this.$emit('remove', item);
            });
        },

        onValidate() {
            this.loading = true;
            const supervisors = this.teamList.filter((supervisor) =>  Number.isInteger(supervisor.company_user_id));

            return api.supervisor.updateSupervisors({
                supervisors,
                enrollee_user_id: this.companyUserId,
            }).then(() => {
                this.$Notifier('App').showInfo(this.translate('Successfully updated'));
            }).catch(() => {
                this.$Notifier('App').showError(this.translate('An error occured'));
            }).finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/ds/typography";
@import '~@/styles/var';

.profile-team-list {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 14px 8px;
    border-radius: var(--radius-block);
    background-color: var(--fill-primary);
    box-shadow: var(--shadow-1);
}

.profile-team-list-item {
    flex: 0 1 calc(100% - 12px);
    margin: 0 6px;
    margin-bottom: 12px;
}

.profile-team-item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px;
    border-radius: var(--radius);
    background-color: var(--fill-primary);
    border: 1px solid var(--border-primary);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    transition: var(--transition-s);
    transition-property: transform, box-shadow;
    cursor: pointer;
    .ht-avatar {
        margin-right: 12px;
        flex-shrink: 0;
    }
    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
    }
}

.team-item-content {
    display: flex;
    flex-direction: column;
    margin-right: auto;
}

.team-item-name {
    @include ht-body;
}

.team-item-role {
    @include ht-caption;
    color: var(--text-secondary);
}

.team-item-icons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.profile-team-add {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    border: 1px dashed var(--border-secondary);
    border-radius: var(--radius);
    @include ht-body;
    transition: var(--transition-s);
    transition-property: transform, box-shadow;
    cursor: pointer;
    .ht-icon {
        margin-right: 8px;
    }
    &::before {
        content: '';
        position: absolute;
        inset: -1px;
        border: 1px solid var(--border-secondary);
        border-radius: var(--radius);
        opacity: 0;
        transition: var(--transition-s);
        transition-property: opacity;
        pointer-events: none;
    }
    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
        &::before {
            opacity: 1;
        }
    }
}

@media (min-width: $tablet-large) {
    .profile-team-list-item {
        flex: 0 1 calc(50% - 12px);
    }
}
@media (min-width: $desktop-small) {
    .profile-team-list-item {
        flex: 0 1 calc(33% - 12px);
    }
}
</style>
