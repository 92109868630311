<template>
    <div>
        <Modalable
            ref="modal"
            :class="['modalable-1', size]"
            :mode="2"
            :options="{title: title}"
        >
            <component
                :is="resources[resource]"
                v-if="!isModalBottom"
                :id="resourceId"
                :show-button-delete="true"
                :disabled-program-list="disabledProgramList"
                @onSave="onSave(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </Modalable>
        <HtModalBottom
            ref="modalBottom"
            :size="'large'"
            @onClose="modalClosed"
        >
            <component
                :is="resources[resource]"
                v-if="isModalBottom"
                :id="resourceId"
                ref="component"
                :hided-tabs="hidedTabs"
                :show-list-on-end="false"
                :show-button-delete="true"
                @onSave="onSave(...arguments)"
                @onDelete="onDelete(...arguments)"
                @on-close-stepper="close"
            />
        </HtModalBottom>
    </div>
</template>

<script>
// EDIT
import PlanningEventTemplateEdit from '@/components/resources/planningEvent/PlanningEventTemplateEdit.vue';
import EquipmentEdit from '@/components/resources/equipment/EquipmentEdit.vue';
import TrainingEdit from '@/components/resources/training/TrainingEdit.vue';
import SoftwareEdit from '@/components/resources/software/SoftwareEdit.vue';
import TaskEdit from '@/components/resources/task/TaskEdit.vue';
import SharedDocumentEdit from '@/components/resources/sharedDocument/SharedDocumentEdit.vue';
import NudgeEdit from '@/components/resources/nudge/NudgeEdit.vue';
import ResourceEmailEdit from '@/components/resources/emails/ResourceEmailEdit.vue';
import SurveyEdit from '../pages/resources/surveys/form/SurveyForm.vue';
import RequirementCategoryEdit from '../pages/resources/requirements/form/RequirementForm.vue';
import QuizEdit from '../../router/pages/resources/quizzes/QuizEdit.vue';

export default {
    name: 'WrapperModal',
    data() {
        return {
            resources: {
                company_equipment: EquipmentEdit,
                company_planning: PlanningEventTemplateEdit,
                company_software: SoftwareEdit,
                company_document: TrainingEdit,
                company_quiz: QuizEdit,
                company_nudge: NudgeEdit,
                company_shared_document: SharedDocumentEdit,
                company_email_custom_template: ResourceEmailEdit,
                company_program_task: TaskEdit,
                company_survey: SurveyEdit,
                company_requirement_category: RequirementCategoryEdit,
            },

            resource: '',
            hidedTabs: [],
            resourceId: null,
            isVisible: false,
        };
    },

    computed: {
        disabledProgramList() {
            /*
             * note: From the moment we are in resource editing from program editing,
             * then we are not supposed to be able to change the program resource as in creation
             */
            return this.$route.name === 'ProgramsForm';
        },
        isModalBottom() {
            const valideComponent = ['company_survey', 'company_requirement_category'];
            return this.resource != '' && valideComponent.includes(this.resource);
        },

        size() {
            return 'medium';
        },

        title() {
            return this.resource === 'company_planning'
                ? this.translate('Edit an event')
                : null;
        },
    },

    methods: {
        getHidedTabs() {
            switch (this.resource) {
            case 'company_survey':
                return ['results'];
            default:
                return [];
            }
        },

        close() {
            this.$refs.modalBottom.close();
            this.resource = '';
            this.resourceId = null;
        },

        modalClosed() {
            if (this.$refs.component && this.$refs.component.close) {
                this.$refs.component.close();
            }
        },

        open(resource, resourceId) {
            this.resource = resource;
            this.resourceId = resourceId;
            this.hidedTabs = this.getHidedTabs();
            if (this.isModalBottom === true) {
                this.$refs.modalBottom.open();
            } else {
                this.$refs.modal.open();
            }
        },

        onSave(withClose = true) {
            if (this.isModalBottom === true && withClose === true) {
                this.$refs.modalBottom.close();
            }
            this.$emit('onSave');
            this.resource = '';
            this.resourceId = null;
        },

        onDelete() {
            if (this.isModalBottom === true) {
                this.$refs.modalBottom.close();
            }
            this.$emit('onDelete');
            this.resource = '';
            this.resourceId = null;
        },
    },
};
</script>
