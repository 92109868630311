<template>
    <div>
        <HtAccordion
            :title="translate('Trainings')"
            :count="filteredUserTraining.length"
        >
            <HtTable
                v-if="filteredUserTraining.length > 0"
                :items="filteredUserTraining"
                :columns="columns"
                cypress="user-activities"
                @onCellClicked="openItemModal"
            >
                <template #cell(display_status)="scope">
                    <HtTag :color="taskColor[scope.item.company_user_program_task.status]">
                        {{ statusText(scope.item.company_user_program_task.status) }}
                    </HtTag>
                </template>
            </HtTable>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No training added</t>
                </strong>
            </div>
        </HtAccordion>

        <Modalable
            ref="modalableItem"
            :mode="2"
            class="modalable-1 small"
        >
            <UserTrainingItem
                :id="id"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate()"
                @onDelete="onDelete()"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyUserTrainingCollection from '@/models/CompanyUserTrainingCollection';
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserTrainingItem from '@/components/pages/dashboard/modals/training/UserTrainingItem.vue';
import HtTag from '@/components/globals/HtTag.vue';
import TaskStatusMixin from '@/mixins/TaskStatusMixin';

export default {
    name: 'UserTrainingProfile',

    components: {
        UserTrainingItem,
        HtAccordion,
        HtTag,
    },

    mixins: [
        TaskStatusMixin,
    ],

    props: {
        isEdit: { type: Boolean, default: false },
        companyUserId: { type: Number, required: true },
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('status'),
                    key: 'display_status',
                    sortable: false,
                },
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                },
                {
                    label: this.translate('Program linked'),
                    key: 'company_user_program.company_program.name_translated',
                    sortable: false,
                },
            ],
            id: null,
            companyUserTrainingCollection: new CompanyUserTrainingCollection([
                'id', 'company_user_id', 'name', 'status',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select(['id', 'status', 'resource_id', 'resource', 'status', 'datetime_availability']);
                },
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]).orderBy([
                ['name', 'asc'],
            ]),
        };
    },

    computed: {
        filteredUserTraining() {
            return this.companyUserTrainingCollection.$.models.filter((item) => ((this.shared.session.companyUser.id == this.companyUserId)
                ? this.$Utils.moment().isSameOrAfter(this.$Utils.moment(item.company_user_program_task.datetime_availability))
                : true));
        },
    },

    created() {
        this.companyUserTrainingCollection.company_user_id = this.companyUserId;
        this.companyUserTrainingCollection.get().then(() => {
            this.$emit('on-load', this.companyUserTrainingCollection.$.models.length);
        });
    },

    methods: {
        onDelete() {
            this.companyUserTrainingCollection.get();
        },

        onUpdate() {
            this.companyUserTrainingCollection.get();
        },

        openItemModal(row) {
            this.id = row.item.id;
            this.$refs.modalableItem.open();
        },
    },
};
</script>
