<template>
    <NewPageWithSidebar v-if="!isOldUi && !isHoldingPage">
        <template #sidebar>
            <NewHtPageSidebar :pages="items" />
        </template>

        <router-view />
    </NewPageWithSidebar>
    <PageWithSidebar v-else>
        <template #sidebar>
            <HtPageSidebar :pages="items" />
        </template>

        <router-view />
    </PageWithSidebar>
</template>

<script>
import HtPageSidebar from '@/components/globals/HtPageSidebar.vue';
import NewHtPageSidebar from '@/components/globals/NewHtPageSidebar.vue';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';
import NewPageWithSidebar from '@/layouts/NewPageWithSidebar.vue';
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';
import { isHoldingPage } from '@/helpers/route';

export default {
    name: 'HoldingSettings',

    components: {
        HtPageSidebar,
        NewHtPageSidebar,
        PageWithSidebar,
        NewPageWithSidebar,
    },

    data() {
        return {
            items: [
                {
                    label: this.translate('Users'),
                    name: 'HoldingSettingsUsersCount',
                },
            ],
        };
    },

    computed: {
        isHoldingPage() {
            return isHoldingPage(this.$route);
        },

        isOldUi() {
            return !hasFeatureEnabled(FEATURE_NEW_UI);
        },
    },
};
</script>
