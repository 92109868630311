<template>
    <div class="d-flex document-editor">
        <div class="w-25 px-4 d-flex flex-column document-editor-sidebar">
            <div class="document-editor-sidebar-title mt-5 mb-3 text-center">
                <t>Available variables</t>
            </div>
            <div class="document-editor-sidebar-description text-center mb-3">
                <t>Position the different controls you need in drag and drop.</t>
            </div>

            <HtFormRange
                id="scale"
                v-model="scale"
                name="scale"
                :label="translate('Scale')"
                :show-optional="false"
                class="mt-5 mb-4"
                :min="0"
                :max="200"
                :step="10"
            />
            <div class="document-editor-sidebar-variables">
                <div class="mt-2 document-editor-sidebar-subtitle mb-3">
                    <t>Input fields to complete</t>
                </div>
                <div class="document-editor-sidebar-description text-justify mb-3">
                    <t>
                        The fields completed by the editor will not be editable by the person to whom the task will be
                        assigned.
                    </t>
                </div>
                <div class="mb-3">
                    <div
                        v-for="item in items"
                        :key="item.title"
                        :title="item.hint || ''"
                        class="drag-el d-flex align-items-center"
                        draggable
                        @dragstart="startDrag($event, {is_admin:true, ...item})"
                        @click="getPosition"
                    >
                        <div class="square" />
                        <div class="label">
                            {{ item.title }}
                        </div>
                    </div>
                </div>
                <div class="document-editor-sidebar-subtitle mb-3">
                    <t>Autofill fields</t>
                </div>
                <div class="document-editor-sidebar-description text-justify mb-3">
                    <t>
                        The values of these fields will be completed automatically with the information of the person to
                        whom the task will be assigned.
                    </t>
                </div>
                <div>
                    <div
                        v-for="item in availableVariables"
                        :key="item.title"
                        class="drag-el d-flex align-items-center"
                        draggable
                        @dragstart="startDrag($event, item)"
                        @click="getPosition"
                    >
                        <div class="square" />
                        <div class="label">
                            <t>{{ $Utils.capitalize(item.title) }}</t>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-75">
            <div class="d-flex align-items-center p-5">
                <div class="d-flex align-items-center justify-content-center document-editor-file-icon">
                    <FontAwesomeIcon
                        class="branding-color"
                        :size="'1x'"
                        :icon="['fal', 'file']"
                    />
                </div>
                <div class="ml-4 document-editor-file-name">
                    {{ companyFile.original_name }}
                </div>
            </div>
            <template v-if="pdfFormTemplate">
                <div
                    id="dropzone"
                    class="mx-5"
                >
                    <Dropzone
                        :editing-lang="editingLang"
                        :selected-page="selectedPage"
                        :parent-height="parentHeight"
                        :pdf-form-template="pdfFormTemplate"
                        :start-resize="startResize"
                        :is-admin-component="adminEditor"
                        :resize="resize"
                        class="document-editor-dropzone"
                        :files.sync="documentFiles"
                        :document-fields.sync="documentFields"
                        :field-validator="fieldValidator"
                        @delete="remove"
                        @mandatorize="mandatorize"
                        @setTitle="setTitle"
                        @setRole="setRole"
                        @setRule="setRule"
                        @setSpecificRule="setSpecificRule"
                        @on-drop="onDrop"
                        @on-image-load="resize"
                    />
                </div>
                <HtPagination
                    v-if="editingLang in pdfFormTemplate.translations.files"
                    v-model="selectedPage"
                    :last-page="Object.keys(documentFiles).length || 0"
                    @input="onPageChange"
                />
                <div class="d-flex justify-content-center my-5">
                    <Button
                        :state=" loading ? 'loading' : 'idle'"
                        @click="update"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </template>
            <IconLoading v-else />
        </div>
    </div>
</template>

<script>
import HtPagination from '@/components/globals/HtPagination.vue';
import Dropzone from '@/components/DocumentEditor/Dropzone.vue';
import DocumentEditor from '@/components/DocumentEditor/mixins/DocumentEditor';
import api from '@/store/api';
import { isEmpty } from '@/helpers/_';
import { parseDateByValueAndLanguage, parseDateByValueAndLanguageWithSpecificFormat } from '@/helpers/date';
import HtFormRange from '../globals/HtFormRange.vue';
import FieldValidator from './FieldValidator';

export default {
    name: 'AdminEditor',
    components: {
        HtPagination,
        Dropzone,
        HtFormRange,
    },
    mixins: [DocumentEditor],
    inject: ['modal'],
    props: {
        editingLang: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            fieldValidator: null,
            adminEditor: true,
            loading: false,
            availableVariables: [],
        };
    },

    computed: {
        route() {
            return api.pdfForm.ROUTES.ADMIN;
        },
        editingSizes() {
            return {
                edited_width: this.pdfFormTemplate.translations.edited_width[this.editingLang],
                edited_height: this.pdfFormTemplate.translations.edited_height[this.editingLang],
            };
        },
        pdfSizes() {
            return {
                width: this.pdfFormTemplate.translations.width[this.editingLang],
                height: this.pdfFormTemplate.translations.height[this.editingLang],
            };
        },
        draggableItems() {
            return [
                ...this.items,
                ...this.availableVariables,
            ];
        },
        documentFiles() {
            return this.pdfFormTemplate.translations.files[this.editingLang] || [];
        },
        documentFields: {
            get() {
                return this.pdfFormTemplate?.fields || [[]];
            },
            set(value) {
                return this.pdfFormTemplate.fields = value;
            },
        },
    },

    async created() {
        this.fieldTypes = (await api.pdfForm.loadAvailableTypes()).data.data;
        this.setItems();
        this.loadAvailableVariables();
        this.fieldValidator = new FieldValidator(this.fieldTypes);
    },

    methods: {
        refreshSizes(elem) {
            this.pdfFormTemplate.translations.edited_width[this.editingLang] = elem.offsetWidth;
            this.pdfFormTemplate.translations.edited_height[this.editingLang] = elem.offsetHeight;
            this.parentHeight = elem.offsetHeight;
            this.startResize = false;
        },
        fetchCallback(data) {
            this.pdfFormTemplate = data;
            this.startResize = true;
            this.before_width = data.translations.edited_width[this.editingLang];
            this.before_height = data.translations.edited_height[this.editingLang];
            if (data.translations.scale[this.editingLang]) {
                this.scale = data.translations.scale[this.editingLang];
            }
            this.processFields();
        },
        getPostData() {
            const elem = document.querySelector('#dropzone img');
            return {
                lang: this.editingLang,
                ...this.pdfFormTemplate,
                fields: this.pdfFormTemplate.fields.flat()
                    .map((field) => {
                        if (field.type !== 'date' || isEmpty(field.value)) {
                            return { ...field };
                        }

                        return {
                            ...field,
                            value: parseDateByValueAndLanguageWithSpecificFormat(
                                field.value,
                                this.editingLang,
                                'YYYY-MM-DD',
                            ),
                        };
                    }),
                edited_size: {
                    height: elem.offsetHeight,
                    width: elem.offsetWidth,
                },
                scale: this.scale,
            };
        },

        processFields() {
            const paginatedFields = this.pdfFormTemplate.files.map(() => []);
            if (this.pdfFormTemplate.fields) {
                this.pdfFormTemplate.fields.forEach((field) => {
                    if (!paginatedFields[field.page_number]) {
                        paginatedFields[field.page_number] = [];
                    }
                    paginatedFields[field.page_number].push({
                        ...field,
                        uuid: this.$Utils.uniqId(),
                        top: field.original_top,
                        left: field.original_left,
                        value: field.type !== 'date' || isEmpty(field.value)
                            ? field.value
                            : parseDateByValueAndLanguage(
                                field.value,
                                this.editingLang,
                            ),
                    });
                });
            }

            this.pdfFormTemplate.fields = paginatedFields;
        },

        update() {
            this.loading = true;
            const data = this.getPostData();
            api.pdfForm.update(this.route, this.pdfFormTemplate.id, data)
                .then((response) => {
                    this.fetchCallback(response.data.data);
                    this.resize();
                })
                .catch(this.onErrors)
                .finally(() => {
                    this.loading = false;
                    this.modal.close();
                });
        },

        loadAvailableVariables() {
            this.loading = true;
            api.pdfForm.loadAvailableVariables()
                .then((response) => {
                    this.availableVariables = response.data.data.map((name) => ({
                        title: name,
                        sub_type: name,
                        type: 'variable',
                        value: name,
                        is_admin: false,
                        width: 30,
                        height: 12,
                        completer_id: null,
                    }));
                })
                .catch(this.onErrors)
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.document-editor {

    .drag-el {
        cursor: pointer;
        padding: 0.5em;
        margin-bottom: 10px;

        .label {
            font-size: 14px;
        }

        .square {
            width: 24px;
            height: 24px;
            border-radius: 6px;
            margin-right: 10px;
            background: $blue-1;
        }
    }

    &-sidebar {
        background: $grey-light;
        min-height: 100vh;

        &-title {
            font-size: 20px;
            font-weight: 700;
            color: $black;
        }

        &-subtitle {
            font-size: 16px;
            font-weight: 700;
            color: $black;
        }

        &-variables {
            position: sticky;
            top: 20px;
        }

        &-description {
            font-size: 14px;
            color: $black;
        }
    }

    &-file-icon {
        width: 36px;
        height: 36px;
        background: $primary-alpha;
        border-radius: 6px;
    }

    &-file-name {
        font-size: 14px;
        font-weight: 700;
        color: $black;
    }

    &-dropzone {
        border: 1px solid $grey-ultra-light
    }

}
</style>
