<template>
    <div class="status-icon">
        <div class="icon">
            <FontAwesomeIcon
                v-if="status === 'done'"
                :class="'color-green'"
                :icon="['fa', 'check-circle']"
            />
            <FontAwesomeIcon
                v-else-if="status === 'cancelled'"
                :class="'color-primary'"
                :icon="['fa', 'exclamation-triangle']"
            />
            <FontAwesomeIcon
                v-else
                :class="'color-grey-lighter'"
                :icon="['far', 'check-circle']"
            />
        </div>
        {{ statusText }}
    </div>
</template>
<script>

export default {
    name: 'HtStatusLabel',

    props: {
        status: {
            type: String,
            required: true,
        },
        validatorType: {
            type: String,
            default: 'human',
        },
        datetime: {
            required: false,
            type: [Object, String],
            default: () => '',
        },
    },
    computed: {
        getTime() {
            const { moment } = this.$Utils;
            return moment(this.datetime);
        },
        statusText() {
            const { moment } = this.$Utils;
            switch (this.status) {
            case 'done':
                return this.translate('Done');
            case 'cancelled':
                return this.translate('Cancelled');
            case 'draft':
                return this.translate('Draft');
            case 'pending':
                if (this.validatorType === 'system') {
                    if (this.getTime <= moment()) {
                        return this.translate('Will be automaticaly executed in a few minutes');
                    }

                    return this.translate('Will be automaticaly executed on {date}', { date: this.getTime.format('LL') });
                }
            default:
                return this.translate('To do');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.status-icon {
        display: flex;

        .icon {
            margin-right: 1em;
        }
    }
</style>
