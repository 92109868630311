<template>
    <div class="task-status-wrapper">
        <div
            v-if="withAnimation"
            ref="doneAnimation"
            class="done-animation"
        />
        <div
            class="task-status"
            :class="[status, {late: isLate}]"
        >
            <FontAwesomeIcon
                v-if="status == 'unavailable'"
                icon="lock"
                class="lock"
            />
            <span
                v-else-if="status == 'disabled'"
                class="disabled"
            />
            <FontAwesomeIcon
                v-else
                icon="check"
                class="check"
            />
        </div>
    </div>
</template>

<script>
import taskDoneAnimation from '@/assets/lottieAnimations/taskDone.json';
// Put in vue context when implementing next animation
import lottie from 'lottie-web';

export default {
    name: 'HtTaskStatus',
    props: {
        status: {
            type: String,
            required: false,
            default: 'pending',
            validator: (value) => ['pending', 'done', 'unavailable', 'disabled'].includes(value),
        },
        isLate: {
            type: Boolean,
            required: false,
            default: false,
        },
        withAnimation: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            animation: null,
        };
    },
    watch: {
        status() {
            if (this.animation && this.status === 'done') {
                this.animation.goToAndPlay(0);
            }
        },
    },
    mounted() {
        if (this.withAnimation) {
            this.animation = lottie.loadAnimation({
                container: this.$refs.doneAnimation,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: taskDoneAnimation,
            });
            this.animation.addEventListener('complete', () => {
                this.$emit('on-is-task-animation-completed', true);
            });
        }
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.task-status-wrapper {
    position: relative;

    .task-status {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: var(--border-secondary) solid 1px;
        background-color: var(--fill-primary);
        width: 17px;
        height: 17px;
        cursor: pointer;
        position: relative;
        z-index: 2;

        &.late {
            border-color: var(--border-error);
        }

        .check {
            display: none;
            width: 9px;
            height: 9px;
            color: var(--icon-secondary);
        }

        .disabled {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: var(--fill-disabled);
        }

        &:hover {
            .check {
                display: block;
            }
        }

        &.done {
            background-color: var(--fill-success);
            border: none;

            .check {
                display: block;
                color: color-mix(in srgb, var(--icon-success) 10%, #fff);
            }
        }

        &.unavailable {
            cursor: not-allowed;
            background-color: var(--fill-inactive);
            border: none;

            .check {
                display: none;
            }
        }
    }

    .done-animation {
        position: absolute;
        top: 0px;
        left: 0;
        height: 30px;
        width: 30px;
        transform: translate(0, -45%);
        z-index: 1;
    }
}

</style>
