<template>
    <HtFormSelector
        id="htProgramList"
        v-validate.disable="required ? 'required': ''"
        specific-key="id"
        :value="value"
        :label="withLabel ? translate('Program targeted') : null"
        :options="getCompanyProgramList"
        :placeholder="translate('No program linked to this resource')"
        :disabled="disabled"
        name="htProgramList"
        class="flex-1"
        :is-single="true"
        :data-vv-as="translate('program')"
        :show-asterisk="required"
        :allow-empty="!required"
        cypress="program-list"
        @input="onSelectProgram"
    />
</template>

<script>
import HtProgramType from '@/models/HtProgramType';
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';

export default {
    name: 'HtProgramList',
    components: {
        HtFormSelector,
        HtFormMultiSelect,
    },
    inject: ['$validator'],

    props: {
        value: {
            type: [Number, null],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        withDefault: {
            type: Boolean,
            default: true,
        },
        withLabel: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        getCompanyProgramList() {
            return this.shared.queries.companyProgramCollection.isLoaded() && this.shared.data.companyProgramCollection
                ? this.shared.data.companyProgramCollection.models.filter((program) => this.withDefault || program.ht_program_type.slug !== HtProgramType.SLUG_DEFAULT)
                    .map((program) => ({
                        id: program.id,
                        name: program.name_translated,
                    })) : [];
        },
    },

    created() {
        if (this.shared.queries.companyProgramCollection.isLoaded() === false) {
            this.shared.queries.companyProgramCollection.get()
                .then((data) => {
                    this.shared.data.companyProgramCollection = data;
                });
        }
    },

    methods: {
        /**
         * @param {Number} programId
         * @returns {void}
         */
        onSelectProgram(programId) {
            this.$emit('input', programId);
            this.$eventHub.$emit('program-change');
        },
    },
};
</script>
