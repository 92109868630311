<template>
    <div class="profile-item">
        <div
            v-if="$slots.title || title"
            class="profile-item-title"
        >
            <slot name="title">
                <span>{{ title }}</span>
            </slot>
        </div>
        <div class="profile-item-content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewProfileItem',

    inject: ['$validator'],

    props: {
        title: {
            type: String,
            default: () => '',
        },
    },
};
</script>
