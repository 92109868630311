<template>
    <StepperTabLayout>
        <template #main>
            <HtFormCard>
                <div class="mb-4">
                    <HtFormMultiSelect
                        id="filters"
                        v-model="selectedProgramFilters"
                        name="filters"
                        :options="filterOptions"
                        :placeholder="translate('Filters')"
                        :label="translate('Filters')"
                        :label-options="'name'"
                        :show-optional="false"
                        @onSelect="onChangeFilter(...arguments)"
                        @onRemove="onRemoveFilter(...arguments)"
                    >
                        <div slot="noOptions">
                            <t>All filters already selected</t>
                        </div>
                    </HtFormMultiSelect>
                    <div class="input-notice mb-4">
                        <t>
                            These entities might be used in the configuration of program invitations. If you delete
                            them, you will need to update the related emails to ensure they work correctly.
                        </t>
                    </div>

                    <!-- Following notice should be removed after resources harmonisation is complete -->
                    <div
                        v-if="unselectedMandatoryRoles.length"
                        class="unselected-mandatory-roles"
                    >
                        <div class="unselected-mandatory-roles-title ht-form-label">
                            <t>New roles added via resources</t>
                        </div>
                        <div class="unselected-mandatory-roles-subtitle input-notice">
                            <t>
                                Those roles have been added via resources but
                                are not linked to the program yet.
                            </t>
                        </div>
                        <div class="unselected-mandatory-roles-list">
                            <div
                                v-for="role in unselectedMandatoryRoles"
                                :key="role.id"
                                class="unselected-mandatory-roles-list-item"
                            >
                                {{ role.alias }}
                            </div>
                            <div class="unselected-mandatory-roles-list-button">
                                <Button
                                    :state="buttonState"
                                    @click="addUnselectedRoles"
                                >
                                    <t>Add</t>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <!-- End of removable part -->

                    <HtFormMultiSelect
                        id="roles"
                        v-model="selectedProgramRoles"
                        name="roles"
                        :options="roleOptions"
                        :placeholder="translate('Roles')"
                        :label="translate('Roles')"
                        :label-options="'alias'"
                        :show-optional="false"
                        @onSelect="onChangeRole(...arguments)"
                        @onRemove="onRemoveRole(...arguments)"
                    >
                        <div slot="noOptions">
                            <t>All filters already selected</t>
                        </div>
                    </HtFormMultiSelect>
                    <div class="input-notice">
                        <t>
                            Roles that are linked to resources can't be removed.
                            Modify the resources of the program to remove those roles.
                        </t>
                    </div>
                </div>

                <div v-if="customFields.length > 0">
                    <hr class="separator mb-4">

                    <HtFormSelect
                        id="customFieldChoice"
                        v-model="selectedProgramCustomField"
                        name="customFieldChoice"
                        :options="customFieldsChoices"
                        :placeholder="translate('Condition the invitation of existing users')"
                        :label="translate('Condition the invitation of existing users')"
                        :label-options="'alias'"
                        :allow-empty="true"
                        :show-optional="false"
                    >
                        <div slot="noOptions">
                            <t>No custom field selected</t>
                        </div>
                    </HtFormSelect>

                    <HtFormSelect
                        id="customFieldChoiceValue"
                        v-model="selectedProgramCustomFieldValue"
                        v-validate.disable="customFieldChoiceValueRules"
                        name="customFieldChoiceValue"
                        :data-vv-as="translate('Conditioning value')"
                        :options="customFieldsValueChoices"
                        :placeholder="translate('Must be equal to')"
                        :label="translate('Must be equal to')"
                        :label-options="'alias'"
                        :disabled="selectedProgramCustomField === null"
                        :show-optional="false"
                    >
                        <div slot="noOptions">
                            <t>No custom field value selected</t>
                        </div>
                    </HtFormSelect>
                </div>
            </HtFormCard>
        </template>
        <template #buttonsFooter>
            <Button
                :state="buttonState"
                @click="onSave"
            >
                <t>Continue</t>
            </Button>
        </template>
    </StepperTabLayout>
</template>

<script>
import CompanyRole from '@/models/CompanyRole';
import CompanyRoleProgram from '@/models/CompanyRoleProgram';
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import CompanyProgramCollection from '@/models/CompanyProgramCollection';
import CompanyEntityCollection from '@/models/CompanyEntityCollection';
import api from '@/store/api';
import Button from '../../../globals/Button.vue';
import CompanyProgram from '../../../../models/CompanyProgram';
import HtFormCard from '../../../globals/HtFormCard.vue';
import HtFormMultiSelect from '../../../globals/HtFormMultiSelect.vue';
import HtFormSelect from '../../../globals/HtFormSelect.vue';

export default {
    name: 'ProgramFormTarget',
    components: {
        Button,
        HtFormCard,
        HtFormMultiSelect,
        HtFormSelect,
        StepperTabLayout,
    },
    props: {
        item: {
            type: CompanyProgram,
            required: true,
        },
    },
    shared: {
        data: {
            companyProgramCollection: {
                type: CompanyProgramCollection,
                default: null,
            },
        },
        queries: {
            companyProgramCollection: {
                type: CompanyProgramCollection,
                default: null,
            },
        },
    },
    data() {
        return {
            selectedProgramFilters: [],
            selectedProgramRoles: [],
            entitiesCollection: new CompanyEntityCollection([
                'id',
                'name',
                'slug',
                'is_heyteam',
            ]).where([['is_enabled', '=', true]]),
            mandatoryRoleIds: [],
            unselectedMandatoryRoles: [],
            selectedProgramCustomField: null,
            selectedProgramCustomFieldValue: null,
            customFieldsChoices: [],
            customFields: [],
            customFieldsValueChoices: [
                {
                    id: true,
                    name: this.translate('Yes'),
                },
                {
                    id: false,
                    name: this.translate('No'),
                },
            ],
        };
    },
    computed: {
        buttonState() {
            return this.item._state.isSaving ? 'loading' : 'idle';
        },
        filterOptions() {
            return this.$Utils.getArrayDifference(
                this.entitiesCollection.models.map((entity) => ({
                    id: entity.id,
                    name: entity.is_heyteam
                        ? this.translate(entity.name)
                        : entity.name,
                    entity,
                    company_entity_id: entity.id,
                    is_deletable: !entity.is_heyteam,
                    $isDisabled: entity.is_heyteam,
                })),
                this.selectedProgramFilters,
                'company_entity_id',
            );
        },

        roleOptions() {
            return this.$store.state.roles.roles
                .filter((r) => r.is_assignable_program)
                .map((r) => ({
                    ...r,
                    alias: r.is_heyteam
                        ? this.translate(r.alias)
                        : r.alias,
                    is_deletable: this.isDeletableRole(r),
                    $isDisabled: !this.isDeletableRole(r),
                }));
        },
        customFieldChoiceValueRules() {
            return this.selectedProgramCustomField ? 'required' : '';
        },
    },
    watch: {
        selectedProgramCustomField(newVal) {
            if (newVal === null) {
                this.selectedProgramCustomFieldValue = null;
            }
        },
    },
    async created() {
        await this.setUpCustomFields();

        await this.$store.dispatch('roles/fetchRoles');
        this.mandatoryRoleIds = await this.getMandatoryRoleIds();
        this.entitiesCollection.get()
            .then(() => {
                this.selectedProgramFilters = this.item.entities.models.map(
                    (programEntity) => ({
                        ...programEntity,
                        id: programEntity.company_entity_id,
                        company_entity_id: programEntity.company_entity_id,
                        name: programEntity.entity.is_heyteam
                            ? this.translate(programEntity.entity.name)
                            : programEntity.entity.name,
                        $isDisabled: !programEntity.is_deletable,
                    }),
                );

                if (this.item.role_program.models.length === 0) {
                    this.setMandatoryRoles();
                }
                this.selectedProgramRoles = this.item.role_program.models
                    .filter((roleProgram) => (
                        CompanyRole.NAME_EMPLOYEE !== roleProgram.company_role.name
                        && CompanyRole.NAME_GUEST !== roleProgram.company_role.name
                    ))
                    .map((programRole) => ({
                        ...programRole.company_role,
                        is_deletable: this.isDeletableRole(
                            programRole.company_role,
                        ),
                        $isDisabled: !this.isDeletableRole(
                            programRole.company_role,
                        ),
                    }));
                this.unselectedMandatoryRoles = this.getUnselectedMandatoryRoles();
            });
    },
    methods: {
        async onSave() {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                await this.item.save();

                const data = await this.shared.queries.companyProgramCollection.get();
                this.shared.data.companyProgramCollection = data;

                await this.saveCustomFields();

                this.$emit('goTo', 'program');
            }
        },

        onChangeFilter(option) {
            const existingProgramEntity = this.item.entities.models.find(
                (item) => item.company_entity_id === option.company_entity_id,
            );

            this.item.entities.add({
                ...option,
                id: existingProgramEntity ? existingProgramEntity.id : null,
            });
        },

        onRemoveFilter(option) {
            const toRemove = this.item.entities.models.find(
                (item) => item.company_entity_id === option.company_entity_id,
            );

            this.item.entities.remove(toRemove);
        },

        onChangeRole(option) {
            const programRole = new CompanyRoleProgram();
            programRole.company_program_id = this.item.id;
            programRole.company_role = option;

            const existingProgramRole = this.item.role_program.models.find(
                (item) => item.company_role_id === option.id,
            );

            this.item.role_program.add({
                ...programRole,
                id: existingProgramRole ? existingProgramRole.id : null,
            });
        },

        onRemoveRole(option) {
            const toRemove = this.item.role_program.models.find(
                (item) => item.company_role_id === option.id,
            );

            this.item.role_program.remove(toRemove);
        },

        isMandatoryRole(role) {
            return this.mandatoryRoleIds.includes(role.id);
        },

        isDeletableRole(role) {
            return !this.isMandatoryRole(role);
        },

        getMandatoryRoleIds() {
            return new Promise((resolve, reject) => {
                this.$http
                    .get(`programs/${this.item.id}/mandatory_roles`)
                    .then((data) => resolve(data.data.mandatory_role_ids))
                    .catch((err) => reject(err));
            });
        },
        setMandatoryRoles() {
            this.mandatoryRoleIds.forEach((id) => {
                const role = this.roleOptions
                    .filter(
                        (r) => r.name !== CompanyRole.NAME_EMPLOYEE
                            && r.name !== CompanyRole.NAME_GUEST,
                    )
                    .find((r) => parseInt(r.id, 10) === parseInt(id, 10));
                if (role) {
                    this.onChangeRole(role);
                }
            });
        },
        // this function should be removed when resources will have totally been harmonised.
        // ATM it should only be used for roles added by resources of type :
        // Requirements, Emails, Quizzes and Events.
        getUnselectedMandatoryRoles() {
            return this.mandatoryRoleIds.filter(
                // Get roleIds that are not already in selectedProgramRoles AND are in roleOptions
                (mandatoryId) => !this.selectedProgramRoles
                    .map((selectedProgramRole) => selectedProgramRole.id)
                    .includes(mandatoryId)
                    && this.roleOptions.map((roleOption) => roleOption.id)
                        .includes(mandatoryId),
            )
                // Then map with corresponding roleOption items.
                .map((roleId) => this.roleOptions.find((roleOption) => roleOption.id === roleId));
        },
        // Same goes for the following
        addUnselectedRoles() {
            this.unselectedMandatoryRoles.forEach((role) => {
                this.onChangeRole(role);
            });
            this.onSave();
        },
        async setUpCustomFields() {
            const customFieldsResponse = await api.settings.customFields.all({ type: 'boolean' });
            this.customFields = customFieldsResponse.data.data.map((customFieldChoice) => ({
                id: customFieldChoice.id,
                key: customFieldChoice.key,
                name: customFieldChoice.name,
            }));
            this.customFieldsChoices = [
                {
                    id: null,
                    key: 'no-conditioning',
                    name: this.translate('No conditioning'),
                },
                ...this.customFields,
            ];

            if (this.customFields.length > 0 && this.item.id) {
                const launchConditionResponse = await api.configuration.programs.getProgramsLaunchCondition(this.item.id);
                const launchCondition = launchConditionResponse.data.data;
                if (launchCondition) {
                    this.selectedProgramCustomField = launchCondition.id;
                    this.selectedProgramCustomFieldValue = launchCondition.value;
                }
            }
        },
        async saveCustomFields() {
            const launchConditionResponse = await api
                .configuration
                .programs
                .saveProgramLaunchCondition({
                    programId: this.item.id,
                    customFieldKey: this.customFieldsChoices.find((el) => el.id === this.selectedProgramCustomField)?.key,
                    customFieldTemplateId: this.selectedProgramCustomField,
                    customFieldValue: this.selectedProgramCustomFieldValue,
                });

            const launchCondition = launchConditionResponse.data.data;
            if (launchCondition) {
                this.selectedProgramCustomField = launchCondition.id;
                this.selectedProgramCustomFieldValue = launchCondition.value;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.input-notice {
    padding: 0.7rem;
    font-size: .8em;
    color: darkgray;
}

.ht-form + .input-notice {
    margin-top: -3rem;
}

.unselected-mandatory-roles {
    margin-top: 20px;
    margin-bottom: 30px;

    &-list {
        display: flex;
        flex-direction: row;
        background-color: white;
        padding: .7rem;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        align-items: center;

        &-item {
            display: block;
            background-color: #f0fbfa;
            padding: 0.4em 1em 0.4em 1em !important;
            color: #4A4A4A;
            border-radius: 5px;
            margin-right: 10px;
        }

        &-button {
            display: flex;
            margin-left: auto;
        }
    }
}
</style>
