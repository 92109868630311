<template>
    <div
        class="task-wrapper"
        :class="{minimalLayout}"
    >
        <div
            class="task-tile"
            :class="{'is-dependent': isDependent}"
            @click="onClickTask"
        >
            <div class="task-header">
                <div
                    v-if="selectMode && isSelectable(task)"
                    class="multiselect-checkbox"
                >
                    <div class="multiselect-checkbox-wrapper">
                        <div :class="{'checked': task.selected, 'half': task.partiallySelected}" />
                    </div>
                </div>
                <div class="wrapper-task-icon">
                    <HtEmojiIcon
                        size="32"
                        font-size="13"
                        :style="{backgroundColor: resourcesInfo[task.resource].color }"
                    >
                        <HtIcon
                            :name="resourcesInfo[task.resource].iconName"
                            stroke-width="2"
                            size="16"
                            :style="{color: resourcesInfo[task.resource].iconColor }"
                        />
                    </HtEmojiIcon>
                </div>
                <div class="wrapper-task-desc">
                    <div class="task-title">
                        {{ title }}
                    </div>
                    <div class="task-subtitle">
                        <span
                            v-if="isLate"
                            class="task-subtitle-late"
                        >
                            <span>{{
                                translate('Late ({date})', {
                                    date: getFormattedDate(task.datetime_end),
                                })
                            }}</span>
                        </span>
                        <span v-else>
                            {{ getFormattedDate(task.datetime_end) }}
                        </span>
                    </div>
                    <div
                        v-if="!hideProgram"
                        class="task-program"
                    >
                        <HtAvatar
                            :user="$Utils.getAvatarData(task.user)"
                            size="xs"
                        />
                        {{
                            companyUserId === task.user.id ?
                                translate('My {programName}', {
                                    programName: task.user_program.program.name,
                                }) :
                                translate(`{userName}'s {programName}`, {
                                    userName: task.user.firstname,
                                    programName: task.user_program.program.name,
                                })
                        }}
                    </div>
                </div>
                <div
                    class="wrapper-task-status"
                    @click="toggleValidation(task, $event)"
                >
                    <HtAvatarGroup
                        v-if="task.participants && task.participants.length > 1"
                        :users="$Utils.getAvatarGroupData(task.participants.map(p => p.user))"
                    />
                    <TaskStatus
                        v-if="isDependent"
                        status="unavailable"
                    />
                    <TaskStatus
                        v-else-if="isEventNotToPlan(task)"
                        status="disabled"
                    />
                    <TaskStatus
                        v-else-if="(!selectMode || !isSelectable(task)) && statusTasks[task.id]"
                        :status="statusTasks[task.id]"
                        :is-late="isLate"
                        with-animation
                        @on-is-task-animation-completed="onTaskAnimation"
                    />
                    <div
                        v-else-if="isGroupedTask"
                        class="task-show-sub-tasks"
                    >
                        <HtIcon
                            name="nav-arrow-right"
                            size="24"
                            class="toggle-icon"
                            :class="{'opened': showSubTasks}"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="file && !minimalLayout"
                @click.stop
            >
                <div
                    v-if="isVideo(file.detected_mime_type)"
                    class="task-video-wrapper"
                >
                    <video
                        :key="`video-${task.id}`"
                        controls
                        controlsList="nodownload"
                        width="100%"
                        :src="file.link"
                        @click.stop
                    />
                </div>
                <div
                    v-else-if="canPreview(file.detected_mime_type)"
                    class="task-file-preview-wrapper"
                    @click.stop="togglePreviewer"
                >
                    <img
                        v-if="isImage(file.detected_mime_type)"
                        :src="file.link_preview"
                    >
                    <iframe
                        v-else
                        :src="file.link_preview"
                        frameborder="0"
                    />
                </div>
                <FilePreviewer
                    ref="file-previewer"
                    :files="[file]"
                />
            </div>
            <div
                v-else-if="isYoutube && !minimalLayout"
                class="task-video-wrapper"
            >
                <iframe
                    :src="$Utils.embedVideo(url)"
                    frameborder="0"
                    allow="fullscreen"
                />
            </div>
        </div>
        <transition name="fade-height">
            <div
                v-if="isGroupedTask && showSubTasks"
                class="task-grouped-tasks"
                @click.stop
            >
                <div
                    v-for="subTask in task.tasks"
                    :key="`subtask-${subTask.id}`"
                    class="task-sub-task"
                    @click="onClickSubTask(subTask)"
                >
                    <HtIcon
                        name="long-arrow-down-right"
                        size="24"
                        class="subtask-icon"
                    />
                    <div class="task-tile">
                        <div class="task-header">
                            <div class="wrapper-task-icon">
                                <HtEmojiIcon
                                    size="32"
                                    font-size="13"
                                    :style="{
                                        backgroundColor: resourcesInfo[subTask.resource].color }
                                    "
                                >
                                    <HtIcon
                                        :name="resourcesInfo[subTask.resource].iconName"
                                        stroke-width="2"
                                        size="16"
                                        :style="{color: resourcesInfo[subTask.resource].iconColor }"
                                    />
                                </HtEmojiIcon>
                            </div>
                            <div class="wrapper-task-desc">
                                <div class="task-title">
                                    {{ subTask.title }}
                                </div>
                                <div class="task-subtitle">
                                    <span
                                        v-if="isTaskLate(subTask)"
                                        class="task-subtitle-late"
                                    >
                                        <HtIcon
                                            name="warning-triangle"
                                            size="16"
                                            class="warning-icon"
                                        />
                                        <span>{{
                                            translate('Late ({date})', {
                                                date: getFormattedDate(subTask.datetime_end),
                                            })
                                        }}</span>
                                    </span>
                                    <span v-else>
                                        {{ getFormattedDate(subTask.datetime_end) }}
                                    </span>
                                </div>
                                <div class="task-program">
                                    <HtAvatar
                                        :user="$Utils.getAvatarData(task.user)"
                                        size="xs"
                                    />
                                    {{
                                        companyUserId === task.user.id ?
                                            translate('My {programName}', {
                                                programName: task.user_program.program.name,
                                            }) :
                                            translate(`{userName}'s {programName}`, {
                                                userName: task.user.firstname,
                                                programName: task.user_program.program.name,
                                            })
                                    }}
                                </div>
                            </div>
                            <div
                                class="wrapper-task-status"
                                @click="toggleValidation(subTask, $event)"
                            >
                                <TaskStatus
                                    v-if="(!selectMode || !isSelectable(subTask))
                                        && statusTasks[subTask.id]"
                                    :status="statusTasks[subTask.id]"
                                    :is-late="isTaskLate(subTask)"
                                    with-animation
                                    @on-is-task-animation-completed="onTaskAnimation"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div
            v-if="task.dependenciesTasks && task.dependenciesTasks.length"
            class="task-dependent-tasks"
        >
            <div
                v-for="dependentTask in task.dependenciesTasks"
                :key="`dependent-task-${dependentTask.id}`"
                class="task-sub-task"
            >
                <HtIcon
                    name="long-arrow-down-right"
                    size="24"
                    class="subtask-icon"
                />
                <div class="task-tile is-dependent is-not-clickable">
                    <div class="task-header">
                        <div class="wrapper-task-icon">
                            <HtEmojiIcon
                                size="32"
                                font-size="13"
                                :style="
                                    {backgroundColor: resourcesInfo[dependentTask.resource].color }
                                "
                            >
                                <HtIcon
                                    :name="resourcesInfo[dependentTask.resource].iconName"
                                    stroke-width="2"
                                    size="16"
                                    :style="
                                        {color: resourcesInfo[dependentTask.resource].iconColor }
                                    "
                                />
                            </HtEmojiIcon>
                        </div>
                        <div class="wrapper-task-desc">
                            <div class="task-title">
                                {{ dependentTask.title }}
                            </div>
                            <div class="task-subtitle">
                                <HtEventToPlanTag
                                    v-if="isEventToPlan(dependentTask)"
                                    :label="!minimalLayout"
                                />
                            </div>
                        </div>
                        <div class="wrapper-task-status">
                            <HtIcon
                                name="lock"
                                size="24"
                                class="lock-icon"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NewUserWrapperModal
            ref="newUserWrapperModal"
            :size="resourcesInfo[task.resource].modalSize"
            is-fill
            :is-edit="isEditModal"
            @onUpdate="onUpdate"
            @onDelete="onDelete"
            @on-task-done="handleTaskDone"
        />
    </div>
</template>

<script>
import FilePreviewer from '@/components/globals/FilePreviewer/FilePreviewer.vue';
import HtResourceTag from '@/components/globals/Tag/HtResourceTag.vue';
import NewUserWrapperModal from '@/components/miscellaneous/NewUserWrapperModal.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import filePreviewerMixin from '@/components/globals/FilePreviewer/filePreviewerMixin';
import HtEventToPlanTag from '@/components/globals/Tag/HtEventToPlanTag.vue';
import CompanyUser from '@/models/CompanyUser';
import TaskStatus from './TaskStatus.vue';

export default {
    name: 'TasksListItem',
    components: {
        HtResourceTag,
        TaskStatus,
        NewUserWrapperModal,
        FilePreviewer,
        HtEventToPlanTag,
    },
    mixins: [filePreviewerMixin],
    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },
    props: {
        task: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        selectMode: {
            type: Boolean,
            required: false,
            default: false,
        },
        minimalLayout: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideProgram: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            statusTasks: {},
            openModalTaskId: null,
            resourcesInfo: {
                custom: {
                    label: 'Action',
                    name: 'company_program_task',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#FFE3AC',
                    iconName: 'task-list',
                    iconColor: '#997328',
                },
                company_survey: {
                    label: 'Survey',
                    name: 'company_survey',
                    modalSize: 'medium',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => false,
                    color: '#FFDCCE',
                    iconName: 'chat-bubble-question',
                    iconColor: '#9A3F21',
                },
                company_user_quiz: {
                    label: 'Quiz',
                    name: 'company_quiz',
                    modalSize: 'large',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#EDE5DE',
                    iconName: 'chat-lines',
                    iconColor: '#694F4F',
                },
                company_nudge: {
                    label: 'Nudge',
                    name: 'company_nudge',
                    modalSize: 'medium',
                    validateOnClick: true,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#F5F4F4',
                    iconName: 'playstation-gamepad',
                    iconColor: '#504F4F',
                },
                company_doc: {
                    label: 'Document',
                    name: 'company_shared_document',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: true,
                    isSelectable: () => true,
                    color: '#FFE2E2',
                    iconName: 'submit-document',
                    iconColor: '#B73E23',
                },
                company_document: {
                    label: 'Training',
                    name: 'company_document',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#D7F5F3',
                    iconName: 'bookmark-book',
                    iconColor: '#4F7075',
                },
                company_planning: {
                    label: 'Event',
                    name: 'company_planning',
                    modalSize: 'small',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#D9EEFF',
                    iconName: 'group',
                    iconColor: '#305E95',
                },
                company_email_custom: {
                    label: 'Email',
                    name: 'company_email_custom',
                    modalSize: 'medium',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => true,
                    color: '#FDF2DD',
                    iconName: 'mail',
                    iconColor: '#E6A456',
                },
                company_equipment: {
                    title: this.translate('Install the following equipment | Install the following equipments', { count: this.task.tasks?.length }),
                    label: 'Equipment',
                    name: 'company_equipment',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: true,
                    isSelectable: () => true,
                    color: '#E3DCFF',
                    iconName: 'computer',
                    iconColor: '#51397A',
                },
                company_software: {
                    title: this.translate('Install the following software | Install the following softwares', { count: this.task.tasks?.length }),
                    label: 'Software',
                    name: 'company_software',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: true,
                    isSelectable: (task) => task.software?.type === 'company_software_custom_http',
                    color: '#D0FFF4',
                    iconName: 'pc-mouse',
                    iconColor: '#31B59E',
                },
                company_requirement_category: {
                    title: this.translate('Administrative file to be completed | Administrative files to be completed', { count: this.task.tasks?.length }),
                    label: 'Requirement',
                    name: 'company_requirement_category',
                    modalSize: 'small',
                    validateOnClick: false,
                    useFullTitle: true,
                    isSelectable: () => false,
                    color: '#FFF9C7',
                    iconName: 'journal',
                    iconColor: '#A69712',
                },
            },
            showSubTasks: false,
            isTaskAnimationCompleted: false,
        };
    },
    computed: {
        companyUserId() {
            return this.shared.session.companyUser.id;
        },
        title() {
            if (this.isGroupedTask) {
                return this.resourcesInfo[this.task.resource].title;
            }
            return this.resourcesInfo[this.task.resource].useFullTitle
                ? this.task.full_title : this.task.title;
        },
        isGroupedTask() {
            return !!this.task.tasks?.length;
        },
        isYoutube() {
            if (this.task.training?.url) {
                return this.$Utils.isYoutubeUrl(this.task.training.url);
            }
            return false;
        },
        isLate() {
            return this.$Utils.moment(this.task.datetime_end) <= this.$Utils.moment();
        },
        file() {
            if (this.task.training) {
                return this.task.training.file;
            }
            if (this.task.document) {
                return this.task.document.file;
            }
            return undefined;
        },
        url() {
            if (this.task.training) {
                return this.task.training.url;
            }
            return undefined;
        },
        isDependent() {
            return this.task.dependent_status
                && this.task.dependent_status !== CompanyUserProgramTask.STATUS_DONE;
        },
        isEditModal() {
            return this.task.resource === 'company_requirement_category';
        },
    },
    watch: {
        task() {
            this.setStatusTask();
        },
    },
    mounted() {
        this.setStatusTask();
    },
    methods: {
        onTaskAnimation(isTaskAnimationCompleted = false) {
            this.isTaskAnimationCompleted = isTaskAnimationCompleted;
            this.reloadTasks();
        },
        setStatusTask() {
            if (this.isGroupedTask) {
                for (let i = 0; i < this.task.tasks.length; i += 1) {
                    this.$set(this.statusTasks, this.task.tasks[i].id, this.task.tasks[i].status);
                }
            } else {
                this.$set(this.statusTasks, this.task.id, this.task.status);
            }
        },
        onClickTask() {
            if (this.selectMode && this.isSelectable(this.task)) {
                const selectedTasks = this.isGroupedTask
                    ? this.task.tasks.filter(
                        (oneTask) => this.resourcesInfo[oneTask.resource].isSelectable(oneTask),
                    )
                        .map((oneTask) => oneTask.id)
                    : [this.task.id];
                this.$emit('select', selectedTasks);
            } else if (this.isGroupedTask) {
                this.toggleShowSubTasks();
            } else if (!this.isDependent) {
                this.openTaskModal(this.task);
            }
        },
        onClickSubTask(subTask) {
            if (this.selectMode) {
                if (this.isSelectable(subTask)) {
                    this.$emit('select', [subTask.id]);
                }
            } else {
                this.openTaskModal(subTask);
            }
        },
        openTaskModal(task) {
            if (task.resource === 'company_requirement_category' && task.requirement_category.display_survey) {
                return;
            }

            this.openModalTaskId = task.id;
            this.$refs.newUserWrapperModal.open({
                id: task.id,
                company_user_id: task.user.id,
                company_user_program_id: task.user_program.id,
                resource: task.resource,
                resource_id: task.resource_id,
            });
        },
        togglePreviewer() {
            this.$refs['file-previewer'].togglePreviewer();
        },
        toggleShowSubTasks() {
            this.showSubTasks = !this.showSubTasks;
        },
        isSelectable(task) {
            if (task.tasks) {
                return task.tasks.some((subTask) => this.resourcesInfo[subTask.resource]
                    .isSelectable(subTask));
            }
            return this.resourcesInfo[task.resource].isSelectable(task);
        },
        getFormattedDate(date) {
            return this.$Utils.momentLocalized(date, this.shared.session.companyUser.company_language.key)
                .format('DD MMMM');
        },
        onUpdate() {
            this.$emit('update', this.openModalTaskId);
        },
        onDelete() {
            this.$emit('delete', this.openModalTaskId);
        },
        handleTaskDone(isTaskDone = false) {
            // note: to avoid unnecessary page reloading
            if (this.isCurrentTaskDone === isTaskDone) {
                return;
            }

            this.isCurrentTaskDone = isTaskDone;
            this.reloadTasks();
        },
        reloadTasks() {
            if (!this.isCurrentTaskDone || !this.isTaskAnimationCompleted || !this.isEditModal) {
                return;
            }

            this.isCurrentTaskDone = false;
            this.isTaskAnimationCompleted = false;
            this.$emit('on-task-refreshed');
        },
        isEventNotToPlan(task) {
            return task.planning_event && !task.planning_event?.is_delegated;
        },
        isEventToPlan(task) {
            return task.planning_event?.is_delegated;
        },
        toggleValidation(task, $event) {
            if (this.resourcesInfo[task.resource].validateOnClick
                && task.validator_type === 'human'
                && !this.isDependent
            ) {
                const newStatus = task.status === CompanyUserProgramTask.STATUS_DONE
                    ? CompanyUserProgramTask.STATUS_PENDING
                    : CompanyUserProgramTask.STATUS_DONE;
                if (newStatus !== this.statusTasks[task.id]) {
                    this.$set(this.statusTasks, task.id, newStatus);
                    this.$handleValidationTasks({
                        id: task.id,
                        status: newStatus,
                    })
                        .then(() => {
                            this.$emit('updateStatus', {
                                taskId: task.id,
                                status: newStatus,
                            });
                        })
                        .catch(() => {
                            this.$set(this.statusTasks, task.id, task.status);
                        });
                }
                $event.stopPropagation();
            }
        },
        isTaskLate(task) {
            return this.$Utils.moment(task.datetime_end) <= this.$Utils.moment();
        },
    },
};
</script>

<style lang="scss" scoped src="./tasksListItem.scss" />
