<template>
    <div>
        <div
            class="friezes multiple"
        >
            <div
                v-for="(program, programIndex) in shownPrograms"
                :key="program.id"
                class="frieze"
                @click="onViewProgram(program)"
            >
                <div class="wrapper-heading">
                    <div class="heading">
                        <div class="ht-heading-4">
                            <t>My progress in my program</t>
                        </div>
                        <div class="ht-heading-3">
                            {{ getProgramDescription(program) }}
                        </div>
                    </div>
                    <!-- to decomment when we have the data for late tasks -->
                    <!-- <div
                        v-if="!isMobile && shownPrograms.length === 1"
                        class="late-tasks"
                    >
                        <span>{{ translate('{nbtasks} late tasks', {
                            nbtasks: 2,
                        }) }}</span>
                    </div> -->
                </div>
                <div class="wrapper-body">
                    <HtTooltip
                        position="block-end"
                        align="center"
                        :offset="-4"
                    >
                        <div class="frieze-progress-bar">
                            <div class="progress-bar-placeholder" />
                            <div
                                class="progress-bar-indicator"
                                :style="
                                    `width: calc(${programs[programIndex].enrollee_completion}*1%);`
                                "
                            />
                        </div>
                        <template #target>
                            {{ translate('{completion}% of tasks completed', {
                                completion: programs[programIndex].enrollee_completion,
                            }) }}
                        </template>
                    </HtTooltip>
                    <!-- to decomment when we have the data for late tasks -->
                    <!-- <div
                        v-if="isMobile || shownPrograms.length > 1"
                        class="late-tasks"
                    >
                        <HtIcon
                            name="warning-triangle"
                            size="16"
                        />
                        <span>{{ translate('{nbtasks} late tasks', {
                            nbtasks: 2,
                        }) }}</span>
                    </div> -->
                </div>
            </div>
            <div
                v-if="isMobile"
                class="wrapper-other-links"
            >
                <router-link
                    :to="{ name: 'TeamMyTeam', label: 'My team'}"
                    class="other-link"
                >
                    <HtEmojiIcon
                        size="30"
                        font-size="16"
                        class="other-link-icon"
                    >
                        👥
                    </HtEmojiIcon>
                    <span class="other-link-text"><t>Discover my team</t></span>
                    <HtIcon
                        class="other-link-arrow"
                        name="nav-arrow-right"
                        size="24"
                    />
                </router-link>
            </div>
        </div>
        <router-link
            class="other-programs"
            :to="{
                label: 'Programs',
                name: 'ProfileProgram',
                params: {
                    company_user_id: shared.session.companyUser.id,
                },
            }"
        >
            <span>{{ programs.length > 2 ? getRemainingPrograms : translate('View my program') }}</span>
        </router-link>
    </div>
</template>

<script>

export default {
    name: 'FriezeMultiple',
    props: {
        programs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            currentProgramIndex: 0,
            isMobile: false,
        };
    },
    computed: {
        shownPrograms() {
            return this.programs.length >= 2 ? this.programs.slice(0, 2) : this.programs;
        },
        getRemainingPrograms() {
            return this.programs.length > 2 ? this.translate('You have {requests} other request(s) in progress', {
                requests: this.programs.length - 2,
            }) : '';
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        getProgramPhase(program) {
            const diffDays = this.$Utils.moment().diff(this.$Utils.moment(program.starts_at), 'days');

            if (diffDays < 0) {
                const keyDates = this.$store.getters['config/getKeyDatesBySlug'];
                const keyDateName = this.translate(
                    keyDates[program.program_key_date_slug]?.name,
                );
                return this.translate('before {key_date_name}', { key_date_name: keyDateName.toLowerCase() });
            }
            if (diffDays === 0) return this.translate('1st day');
            if (diffDays > 0 && diffDays <= 7) return this.translate('1st week');
            if (diffDays > 7 && diffDays <= 30) return this.translate('1st month');
            if (diffDays > 30) return this.translate('after 1st month');
            return '';
        },
        getProgramDescription(program) {
            if (program.enrollee_completion !== 100) {
                return this.translate('Current phase : {phase}', {
                    phase: this.getProgramPhase(program),
                });
            }
            return this.translate('Program completed and finalized.');
        },
        onResize() {
            this.isMobile = window.innerWidth < 1024;
        },
        onViewProgram(program) {
            this.$router.push({
                name: 'ProfileProgram',
                params: {
                    company_user_id: this.shared.session.companyUser.id,
                    company_user_program_id: program.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" src="./Frieze.scss" scoped />
