<template>
    <div v-if="showOnlyFile || hasGeneratedFile">
        <UploadBar
            v-if="hasGeneratedFile"
            :value="requirement.company_file"
            :with-edit="false"
            :with-trash="false"
            :media="['pdf']"
        />

        <div v-else>
            -
        </div>
    </div>

    <div v-else-if="isLoaded">
        <HtButton
            class="button"
            :disabled="buttonLoading"
            @click.native="isPreview ? preview() : open()"
        >
            <t>{{ getButtonLabel }}</t>
        </HtButton>

        <HtButton
            v-if="showEditButton"
            :disabled="!canEditPdf || buttonLoading"
            class="button"
            :loading="buttonLoading"
            @click.native="editPdf()"
        >
            <t>Edit PDF</t>
        </HtButton>

        <HtButton
            v-if="showGenerateButton"
            :disabled="!canEditPdf || buttonLoading"
            class="button"
            :loading="buttonLoading"
            @click.native="generatePdf()"
        >
            <t>Generate PDF</t>
        </HtButton>

        <HtToast
            v-if="requirement?.status === 'done' && !requirement?.company_file_id"
            type="info"
            class="mt-5"
            :title="translate('Generating PDF...')"
            :message="translate('The document is not yet generated. It will be available once the generation is completed.')"
        />

        <HtToast
            v-else-if="isPreview && !hasRequirementVariablesCompleted"
            :class="'mb-4 mt-4'"
            type="info"
            :title="translate('Generate PDF')"
            :message="translate('These mandatory requirements must be completed and validated to generate or edit the document :')"
        >
            <ul>
                <li
                    v-for="requirement in incompleteRequirements"
                    :key="requirement.id"
                >
                    - {{ requirement.name }}
                </li>
            </ul>
        </HtToast>

        <ModalableLarge
            ref="documentEditorModal"
            :mode="2"
            @on-close="editionMode = false"
        >
            <DragCol
                :left-percent="getLeftPercent"
                :slider-width="getWidthStroke"
                width="100%"
                height="100%"
                slider-bg-color="#F0F0F0"
                slider-color="#F0F0F0"
                slider-hover-color="#F0F0F0"
                slider-bg-hover-color="#F0F0F0"
            >
                <template #left>
                    <div class="editor-sidebar">
                        <HtTabs>
                            <HtTab :title="translate('Available variables')">
                                <DynamicDocumentSettingVariables
                                    v-if="!isPreview"
                                    :program-id="programId"
                                    :display-survey="displaySurvey"
                                    :used-variables="dynamicDocument.variables"
                                    :show-list="showList"
                                    :categories="categories"
                                    @addVariable="addVariable"
                                />
                            </HtTab>
                            <HtTab :title="translate('Advanced settings')">
                                <DynamicDocumentSettingMargins
                                    :used-advanced-setting="dynamicDocument.advancedSetting"
                                    @changeSettings="changeSettings"
                                />
                            </HtTab>
                        </HtTabs>
                    </div>
                </template>

                <template #right>
                    <div class="main-editor">
                        <div class="mb-5">
                            <t>Document settings</t>
                        </div>

                        <Editor
                            ref="editor"
                            :value="dynamicDocument.html_content"
                            :init="init"
                            :disabled="isPreviewMode"
                            :class="{'no-toolbar': false}"
                            @input="text => onInput(text)"
                        />

                        <HtToast
                            v-if="isEditMode"
                            type="warning"
                            class="mt-5"
                            :title="translate('Warning !')"
                            :message="translate('Any modification to this document will freeze it. No variable whose value is not already present could be automatically valued.')"
                        />

                        <HtButton
                            v-if="!isPreviewMode"
                            class="save-button"
                            :disabled="editionLoading || !isValidMargins"
                            :loading="editionLoading"
                            @click.native="editionMode ? save() : close()"
                        >
                            <t>Save</t>
                        </HtButton>
                    </div>
                </template>
            </DragCol>
        </ModalableLarge>
    </div>
</template>

<script>
import ModalableLarge from '@/components/globals/ModalableLarge.vue';
import Editor from '@tinymce/tinymce-vue';
import DefaultEditorMixin from '@/components/mixins/DefaultEditorMixin';
import api from '@/store/api';
import HtButton from '@/components/globals/HtButton.vue';
import UserRequirementEditMixin from '@/components/pages/onboarding/type/UserRequirementEditMixin';
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';
import get from 'lodash.get';
import { DragCol } from 'vue-resizer';
import ConditionalBlockDynamicDocumentMixin from '@/components/mixins/ConditionalBlockDynamicDocumentMixin';
import HtToast from '@/components/globals/HtToast.vue';
import CompanyResourceProgramParticipant from '@/models/CompanyResourceProgramParticipant';
import DynamicDocumentSettingVariables from '@/components/globals/DynamicDocumentSettingVariables.vue';
import DynamicDocumentSettingMargins from '@/components/globals/DynamicDocumentSettingMargins.vue';
import CompanyUser from '@/models/CompanyUser';
import modalMixin from './modals/modalMixin';

export default {
    name: 'DynamicDocument',
    components: {
        HtToast,
        UploadBar,
        HtButton,
        DynamicDocumentSettingVariables,
        Editor,
        ModalableLarge,
        DragCol,
        DynamicDocumentSettingMargins,
    },

    mixins: [
        DefaultEditorMixin,
        ConditionalBlockDynamicDocumentMixin,
        UserRequirementEditMixin,
        modalMixin,
    ],

    props: {
        isPreview: {
            type: Boolean,
            default: () => false,
        },
        showOnlyFile: {
            type: Boolean,
            default: () => false,
        },
        programId: {
            type: Number,
            default: () => null,
        },
        displaySurvey: {
            type: Boolean,
            default: () => false,
        },
        userId: {
            type: Number,
            default: () => null,
        },
        value: {
            type: Object,
            default: () => {},
        },
        requirement: {
            type: Object,
            default: () => null,
        },
        editingLanguage: {
            type: String,
            default: () => '',
        },
        canUpdate: {
            type: Boolean,
            default: false,
        },
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },

    data() {
        return {
            isValidMargins: true,
            editionMode: false,
            editionLoading: false,
            dynamicDocument: {
                html_content: '',
                variables: [],
                advancedSetting: {},
            },
            hasAlreadyPreparedData: false,
            hasAlreadyClickOnGenerated: false,
            incompleteRequirements: [],
            isLoaded: false,
            buttonLoading: false,
            variables: [],
            hasCategorySupervisors: false,
            isOneOfCategorySupervisor: false,
            showList: {},
            categories: {
                user: [],
                company: [],
                program: [],
                keydate: [],
                supervisor: [],
                entity: [],
                requirement: [],
            },
        };
    },

    computed: {
        isEditMode() {
            return this.editionMode && this.isPreview;
        },
        isPreviewMode() {
            return !this.editionMode && this.isPreview;
        },
        getLeftPercent() {
            return this.isPreview ? 0 : 30;
        },
        getWidthStroke() {
            return this.isPreview ? 0 : 5;
        },
        hasGeneratedFile() {
            return this.companyUserRequirement?.company_file?.id;
        },
        hasRequirementVariablesCompleted() {
            return this.incompleteRequirements.length === 0;
        },
        showEditButton() {
            if (this.isPreview && this.requirement) {
                if (this.hasCategorySupervisors) {
                    return this.isOneOfCategorySupervisor && this.hasRequirementVariablesCompleted;
                }

                return this.showGenerateButton;
            }

            return false;
        },
        showGenerateButton() {
            if (this.isPreview && this.canUpdate && this.requirement) {
                const executorTask = this.requirement.tasks.find((task) => task.participants.find((p) => p.type === CompanyResourceProgramParticipant.TYPE_EXECUTOR));

                const isParticipant = get(executorTask, 'participants[0].company_user_id') === this.shared.session.companyUser.id || this.canUpdate;

                return isParticipant && this.hasRequirementVariablesCompleted;
            }

            return false;
        },
        canEditPdf() {
            return !this.hasAlreadyClickOnGenerated && this.companyUserRequirement.status !== 'done';
        },
        getButtonLabel() {
            return this.isPreview ? 'Preview document' : 'Open document editor';
        },
        // Overwrite getCustomCss computed in the file DefaultEditorMixin.js
        getCustomCss() {
            let css = '';

            if (this.isPreview) {
                css += `
                    .variable-label, .variable-translated {
                        display: none;
                    }

                    .variable-not-found {
                        padding: 0.1rem 0.5rem;
                        background-color: #FCDADA;
                        color: #8F2929;
                        border-radius: 4px;
                    }
                `;
            } else {
                css += `
                    span[class^="tag-document-type"] {
                        cursor: not-allowed;
                        font-size: 1rem;
                        padding: 0.1rem 0.5rem;
                        border-radius: 4px;
                    }

                    .variable-key, .variable-label {
                        display: none;
                    }

                    .variable-red {
                        background-color: #FCDADA;
                        color: #8F2929;
                    }

                    .variable-pink {
                        background-color: #FCDAE4;
                        color: #8F2950;
                    }

                    .variable-grey {
                        background-color: #E9E9E9;
                        color: #575757;
                    }

                    .variable-green {
                        background-color: #DAFCE2;
                        color: #298F4D;
                    }

                    .variable-blue {
                        background-color: #DAE4FC;
                        color: #29418F;
                    }

                    .variable-yellow {
                        background-color: #FBFCDA;
                        color: #8f7E29;
                    }
                `;
            }

            return css;
        },
        getMarginCss() {
            return `
                body {
                    margin-top: ${this.dynamicDocument?.advancedSetting?.margin_top || '0cm'};
                    margin-right: ${this.dynamicDocument?.advancedSetting?.margin_right || '0cm'};
                    margin-bottom: ${this.dynamicDocument?.advancedSetting?.margin_bottom || '0cm'};
                    margin-left: ${this.dynamicDocument?.advancedSetting?.margin_left || '0cm'};
                }
            `;
        },
    },

    watch: {
        value(val) {
            this.dynamicDocument = val;
        },
    },

    created() {
        this.dynamicDocument = this.value;

        if (this.isPreview) {
            this.getRequirementVariablesCompletion();
            this.getSupervisorIdList();
        } else {
            this.translateVariableLabelsContainedInDocument(this.value.html_content);
            this.isLoaded = true;
        }
    },

    methods: {
        async getVariables() {
            if (!this.programId || this.hasAlreadyPreparedData) {
                return;
            }

            const response = (await api.variables.getFromProgram(this.programId)).data;
            this.variables = response.data;

            const roleNameList = this.$store.state.roles.roles.map((role) => ({
                id: role.id,
                name: role.is_heyteam ? this.translate(role.alias) : role.alias,
            }));

            this.variables.forEach((variable) => {
                const prefix = variable.key.split('_');

                switch (prefix[0]) {
                case 'date':
                    this.categories.keydate.push(variable);
                    break;
                case 'supervisor':
                    const roleName = roleNameList.find((role) => role.id == prefix[1]).name;

                    if (this.categories.supervisor[roleName] === undefined) {
                        this.$set(this.categories, 'supervisor', { [roleName]: [], ...this.categories.supervisor });
                        this.$set(this.showList, roleName, false);
                    }

                    this.categories.supervisor[roleName].push(variable);
                    break;
                case 'requirement':
                    if (this.categories.requirement[variable.meta_data.company_requirement_category_label] === undefined) {
                        this.$set(this.categories, 'requirement', { [variable.meta_data.company_requirement_category_label]: [], ...this.categories.requirement });
                        this.$set(this.showList, variable.meta_data.company_requirement_category_label, false);
                    }
                    this.categories.requirement[variable.meta_data.company_requirement_category_label].push(variable);
                    break;
                case 'custom':
                    break;
                default:
                    this.categories[prefix[0]].push(variable);
                }
            });

            this.hasAlreadyPreparedData = true;
        },

        async getConditionalPropertyValues() {
            const response = (await api.variables.getPropertyValues(this.programId, this.editingLanguage)).data;
            this.cbPropertiesValues = response.data;
        },

        async getSupervisorIdList() {
            const response = (await api.user.requirementCategories.getSupervisorIdList(this.companyUserRequirement.company_user_id, this.companyUserRequirement.company_user_requirement_category_id)).data;
            this.hasCategorySupervisors = response.has_category_supervisors;
            this.isOneOfCategorySupervisor = response.is_one_of_category_supervisor;

            this.isLoaded = true;
        },

        async getRequirementVariablesCompletion() {
            const response = (await api.variables.verifyCompletion(this.companyUserRequirement.id)).data;
            response.incomplete_requirements.forEach((r, index) => {
                this.$set(this.incompleteRequirements, index, r);
            });
        },

        translateVariableLabelsContainedInDocument(html) {
            const div = document.createElement('div');
            div.innerHTML = html;

            const spans = div.querySelectorAll('span.variable-label');
            const spanList = Array.from(spans);

            if (!spanList.length) {
                return;
            }

            for (let i = 0; i < spans.length; i++) {
                let spanTranslated = spans[i].parentElement.querySelector('span.variable-translated');

                // Todo remove the if part once development has gone into production
                if (!spanTranslated) {
                    spanTranslated = document.createElement('span');
                    spanTranslated.classList.add('variable-translated');
                    spanTranslated.textContent = this.translate(spans[i].textContent);
                    spans[i].parentElement.appendChild(spanTranslated);
                } else {
                    spanTranslated.textContent = this.translate(spans[i].textContent);
                }
            }

            this.dynamicDocument.html_content = div.innerHTML;
        },

        async open() {
            await this.getVariables();
            await this.getConditionalPropertyValues();
            this.$refs.documentEditorModal.open();
        },

        async preview() {
            const response = await api.variables.getPreview(this.companyUserRequirement.id);
            this.dynamicDocument = { html_content: response.data, variables: [], advancedSetting: this.companyUserRequirement.advancedSetting };
            this.translateVariableLabelsContainedInPreview(response.data);
            await this.open();
        },

        async editPdf() {
            this.editionMode = true;
            await this.preview();
        },

        translateVariableLabelsContainedInPreview(html) {
            const div = document.createElement('div');
            div.innerHTML = html;

            const spans = div.querySelectorAll('span.variable-not-found');
            const spanList = Array.from(spans);

            if (!spanList.length) {
                return;
            }

            for (let i = 0; i < spans.length; i++) {
                const parentSpan = spans[i].parentElement.parentElement;
                if (!parentSpan.classList.contains('tag-document-type-requirement')) {
                    const variableLabel = parentSpan.querySelector('span.variable-label').textContent;
                    spans[i].textContent = this.translate(variableLabel);
                }
            }

            this.dynamicDocument.html_content = div.innerHTML;
        },

        async save() {
            this.editionLoading = true;

            try {
                await this.genericConfirm(
                    this.translate('Confirmation needed'),
                    this.translate("You're about to freeze this document. No variable that is not already valued could be valued by the system and should be replaced manually."),
                );

                const updateMethode = this.isEditMode ? this.updateRequirementWithoutTask : this.updateRequirement;

                updateMethode().then(() => {
                    this.$emit('on-update-requirement', this.companyUserRequirement);
                    this.$emit('onChangeValue', this.requirement.id, true);
                    this.editionLoading = false;
                    this.editionMode = false;
                    this.close();
                });
            } catch (refused) {
                this.editionLoading = false;
            }
        },

        close() {
            this.addCopiedVariables();
            this.$refs.documentEditorModal.close();
        },

        addCopiedVariables() {
            const matches = this.dynamicDocument.html_content.match(/\{([^}]+)}/g);
            const keys = matches ? matches.map((match) => match.slice(1, -1)) : [];

            keys.forEach((key) => {
                const variable = this.dynamicDocument.variables.find((v) => v.key === key);
                if (!variable) {
                    const copiedVariable = this.variables.find((v) => v.key === key);
                    if (copiedVariable) {
                        this.dynamicDocument.variables.push(copiedVariable);
                    }
                }
            });
        },

        generatePdf() {
            this.buttonLoading = true;

            const executorTask = this.requirement.tasks.find((task) => task.participants.find((p) => p.type === CompanyResourceProgramParticipant.TYPE_EXECUTOR));

            api.user.requirements
                .generate(this.userId, this.companyUserRequirement.id, { task: executorTask })
                .then(async ({ data }) => {
                    this.$emit('on-update-requirement', data.resource);
                    this.$emit('onChangeValue', this.requirement.id, true);
                    await this.$store.dispatch('requirementCategoryEditModal/loadCategory', {
                        userId: this.userId,
                        categoryId: this.requirement.company_user_requirement_category_id,
                    });
                    this.companyUserRequirement = this.$store.getters['requirementCategoryEditModal/requirement'](this.companyUserRequirement.id);
                    this.hasAlreadyClickOnGenerated = true;
                    this.buttonLoading = false;
                });
        },

        addVariable(variable, variableClass) {
            if (!this.dynamicDocument.variables.find((v) => v.key === variable.key)) {
                this.dynamicDocument.variables.push(variable);
            }

            const prefix = (variable.key.split('_'))[0];

            const spanMain = document.createElement('span');
            spanMain.setAttribute('contenteditable', 'false');
            spanMain.classList.add(`tag-document-type-${prefix}`);
            spanMain.classList.add(variableClass);

            // To keep the original label
            const spanLabel = document.createElement('span');
            spanLabel.classList.add('variable-label');

            // To give the user a pretty display
            const spanTranslated = document.createElement('span');
            spanTranslated.classList.add('variable-translated');

            if (typeof variable.meta_data === 'string') {
                const metaData = JSON.parse(variable.meta_data);
                spanLabel.textContent = `${metaData?.company_requirement_category_label} / ${variable.label}`;
                spanTranslated.textContent = `${metaData?.company_requirement_category_label} / ${variable.label}`;
            } else if (variable.meta_data.company_requirement_category_label !== undefined) {
                spanLabel.textContent = `${variable.meta_data?.company_requirement_category_label} / ${variable.label}`;
                spanTranslated.textContent = `${variable.meta_data?.company_requirement_category_label} / ${variable.label}`;
            } else {
                spanLabel.textContent = variable.label;
                spanTranslated.textContent = this.translate(variable.label);
            }

            // So that the backend can retrieve the key
            const spanKey = document.createElement('span');
            spanKey.textContent = `{${variable.key}}`;
            spanKey.classList.add('variable-key');

            spanMain.appendChild(spanTranslated);
            spanMain.appendChild(spanLabel);
            spanMain.appendChild(spanKey);

            const { editor } = this.$refs.editor;
            editor.execCommand('mceInsertContent', false, spanMain.outerHTML);
        },

        changeSettings(data) {
            const [key, value] = Object.entries(data.settings)[0];
            this.isValidMargins = data.isValidMargins;
            if (this.isValidMargins) {
                this.$set(this.dynamicDocument.advancedSetting, key, value);
            }
        },

        onInput(text) {
            this.dynamicDocument.html_content = text;
            if (this.companyUserRequirement) {
                this.companyUserRequirement.value = this.dynamicDocument.html_content;
            }
            this.$emit('input', {
                html_content: text,
                variables: this.dynamicDocument.variables,
                advancedSetting: this.dynamicDocument.advancedSetting,
            });
        },

        // Overwrite onPaste method in the file DefaultEditorMixin.js
        onPaste(node) {
            const div = document.createElement('div');
            div.innerHTML = node.content;

            // Detection of variables in paste content
            const spans = div.querySelectorAll('span.variable-label, span.variable-key');
            const spanList = Array.from(spans);

            // Variables must be added via the interface and not pasted, to avoid integration problems.
            // They are therefore removed from the paste
            if (spanList.length > 0) {
                for (let i = 0; i < spans.length; i++) {
                    const span = spans[i];
                    span.parentNode.removeChild(span);
                }
                node.content = div.innerHTML;
            }
        },
    },
};
</script>

<style lang="scss" scoped>

.modal {
    overflow-y: auto;
}

.button {
    display: block;
    width: 50%;
    margin-bottom: 1rem;
    border-radius: 20px;
}

.editor-sidebar {
  overflow-y: scroll;
  border-right: 1px solid #F0F0F0;
  background: #FBFBFB;
  padding-right: 2rem;
  height: 100vh;
}

.main-editor {
  width: 100%;
  padding: 5rem 3rem 0 3rem;
  display: flex;
  flex-direction: column;
}

.editor {
  padding-top: 3rem;
}

.save-button {
  display: inline-block;
  margin: 1.5rem 0;
  align-self: center;
}

</style>

<style lang="scss">
.drager_left {
    padding-right: 0;
}
.drager_right > div {
    overflow-y: scroll;
}
</style>
