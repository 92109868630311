import UserRequirementCategoryBus from '@/eventBus/UserRequirementCategoryBus';

export default {
    methods: {
        processFileToFill(isPdfForm, isComplete) {
            if (!isPdfForm) {
                this.companyUserRequirement.company_file_id = this.companyUserRequirement.company_file.id;
            }

            if (this.companyUserRequirement.sign_after_complete) {
                this.onUpdate(isComplete)
                    .then(() => {
                        this.callUpdateFileToFill('requirements/sign-after-completion/', {
                            completed_company_file_id: this.companyUserRequirement.company_file?.id,
                            completed_company_user_requirement_id: this.companyUserRequirement.id,
                            is_pdf_form: isPdfForm,
                        });
                    });
            } else {
                this.onUpdate(isComplete);
            }
        },

        callUpdateFileToFill(endpoint, params) {
            this.$http.post(endpoint, params)
                .then((response) => {
                    const newRequirement = response.data.requirement;
                    if (newRequirement) {
                        this.companyUserRequirement = newRequirement;
                        this.$emit('on-update-requirement', newRequirement);
                    }
                });
        },

        onUpdate(isComplete = true) {
            return new Promise((resolve, reject) => {
                this.updateRequirement()
                    .then(() => {
                        this.$emit('onChangeValue', this.companyUserRequirement.id, true, isComplete);
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
    },

    created() {
        UserRequirementCategoryBus.$on('reload', () => {
            this.$nextTick(() => {
                this.companyUserRequirement = this.$Utils.clone(this.requirement, true);
            });
        });
    },
};
