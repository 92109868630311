<template>
    <div
        v-if="companyUserConfidential && companyUserConfidential.isLoaded()"
    >
        <NewProfileBloc
            :is-edit.sync="isEdit"
            :can-edit="canUpdate"
            :button-state="buttonState"
            :title="translate('Remuneration')"
            @on-save="onUpdate"
        >
            <template #title-extra>
                <HtFormSwitch
                    :id="'toggle-show-salary'"
                    :value="showConfidential"
                    :name="'toggle-show-salary'"
                    :on-label="translate('On')"
                    :off-label="translate('Off')"
                    :show-optional="false"
                    class="mb-0 mr-3"
                    @input="toggleDelete"
                />
            </template>
            <div
                v-show="showConfidential"
                class="profile-row form-2"
            >
                <div class="profile-column-half">
                    <!-- ANNUAL SALARY -->
                    <NewProfileItem :title="translate('Annual salary')">
                        <span v-if="!isEdit">{{ companyUserConfidential.$.salary ? companyUserConfidential.$.salary + currencies[companyUserConfidential.$.salary_currency].symbol : '' | empty }}</span>

                        <div
                            v-else
                            class="row"
                        >
                            <HtFormInput
                                :id="'salary'"
                                v-model="companyUserConfidential.salary"
                                :type="'number'"
                                :name="'salary'"
                                class="col-md-6"
                            />
                            <HtFormSelect
                                :id="'salary_currency'"
                                v-model="companyUserConfidential.salary_currency"
                                :name="'salary_currency'"
                                :options="getCurrentieOptions"
                                class="col-md-6"
                            />
                        </div>
                    </NewProfileItem>

                    <!-- STOCK OPTIONS -->
                    <NewProfileItem :title="translate('Stock Option')">
                        <span v-if="!isEdit">{{ companyUserConfidential.$.stock_option || '-' }}</span>

                        <HtFormTextarea
                            v-else
                            :id="'stock_option'"
                            v-model="companyUserConfidential.stock_option"
                            :name="'stock_option'"
                        />
                    </NewProfileItem>
                </div>

                <div class="profile-column-half">
                    <!-- EXTRA -->
                    <NewProfileItem :title="translate('Extra')">
                        <span v-if="!isEdit">{{ companyUserConfidential.$.extra ? companyUserConfidential.$.extra + currencies[companyUserConfidential.$.extra_currency].symbol : '' | empty }}</span>

                        <div
                            v-else
                            class="row"
                        >
                            <HtFormInput
                                :id="'extra'"
                                v-model="companyUserConfidential.extra"
                                :name="'extra'"
                                :type="'number'"
                                class="col-md-6"
                            />
                            <HtFormSelect
                                :id="'extra_currency'"
                                v-model="companyUserConfidential.extra_currency"
                                :name="'extra_currency'"
                                :options="getCurrentieOptions"
                                class="col-md-6"
                            />
                        </div>
                    </NewProfileItem>

                    <!-- INFORMATION -->
                    <NewProfileItem :title="translate('Information')">
                        <span v-if="!isEdit">{{ companyUserConfidential.$.information || '-' }}</span>

                        <HtFormTextarea
                            v-else
                            :id="'information'"
                            v-model="companyUserConfidential.information"
                            :name="'information'"
                        />
                    </NewProfileItem>
                </div>
            </div>
        </NewProfileBloc>
        <Modalable
            ref="deleteConfidential"
            class="modalable-1 small"
            :mode="2"
        />
    </div>
</template>

<script>
import CompanyUserConfidential from '@/models/CompanyUserConfidential';
import Currencies from '@/Currencies';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanyUser from '@/models/CompanyUser';
import NewProfileBloc from '../NewProfileBloc.vue';
import NewProfileItem from '../profile/NewProfileItem.vue';

export default {
    name: 'NewProfileAdministrativeConfidential',

    permissions: [
        'ModelCompanyUserConfidential',
    ],

    components: {
        NewProfileBloc,
        HtFormSwitch,
        NewProfileItem,
    },

    props: {
        user: {
            type: CompanyUser,
            required: true,
        },
    },

    data() {
        return {
            isEdit: false,
            companyUserConfidential: null,
            currencies: {},
            showConfidential: false,
            buttonState: 'idle',
        };
    },

    computed: {
        getCurrentieOptions() {
            return Object.keys(this.currencies).map((currency) => ({
                name: this.currencies[currency].label,
                id: currency,
            })).sort((a, b) => a.name > b.name);
        },

        hasPermission() {
            return this.$canUpdate('ModelCompanyUserConfidential', { company_user_id: this.user.id });
        },

        canUpdate() {
            return this.showConfidential && this.hasPermission;
        },

        canDelete() {
            return this.$canDelete('ModelCompanyUserConfidential', { company_user_id: this.user.id });
        },
    },

    created() {
        this.currencies = Currencies.list;
        this.loadCompanyUserConfidential();
    },

    methods: {
        loadCompanyUserConfidential() {
            return new Promise((resolve, reject) => {
                this.companyUserConfidential = new CompanyUserConfidential([
                    'id',
                    'company_user_id',
                    'salary',
                    'salary_currency',
                    'extra',
                    'extra_currency',
                    'stock_option',
                    'information',
                ]).where([['company_user_id', '=', this.user.id]]);

                this.companyUserConfidential.get().then((data) => {
                    if (data.id) {
                        this.showConfidential = true;
                    } else {
                        this.companyUserConfidential.company_user_id = this.user.id;
                    }
                    resolve(data);
                }).catch(() => {
                    reject();
                });
            });
        },

        onUpdate() {
            this.buttonState = 'loading';
            this.companyUserConfidential.save().then(() => {
                this.isEdit = false;
            }).finally(() => {
                this.buttonState = 'idle';
            });
        },

        toggleDelete(showConfidential) {
            if (!showConfidential && this.canDelete && this.companyUserConfidential.id) {
                this.$refs.deleteConfidential.deleteWindow({
                    content: this.translate('Are you sure you want to permanently delete the salary information? Once you click on delete, you will no longer be able to restore it.'),
                }).then((result) => {
                    if (!result) {
                        this.showConfidential = true;
                        return;
                    }

                    this.companyUserConfidential.delete().then(() => {
                        this.loadCompanyUserConfidential();
                        this.isEdit = false;
                        this.showConfidential = false;
                    });
                });
            } else {
                this.showConfidential = showConfidential;
                this.isEdit = false;
            }
        },
    },
};
</script>
