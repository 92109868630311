import Model from '@tony.caron/node-model-proxy/Model';
import CompanyTagTemplate from '@/models/CompanyTagTemplate';
import CompanyFile from './CompanyFile';

import I18n from '../modules/i18n/I18n';
import CompanyRequirementCollection from './CompanyRequirementCollection';

export default class CompanyRequirement extends Model {
    modelConfig() {
        return {
            primaryKey: 'id',
            onSave: 'refresh', // Do not send lookable to the query if we refresh TODO
            collection: CompanyRequirementCollection,
        };
    }

    // Default attributes that define the "empty" state.
    modelDefaultValues() {
        return {
            question_multiple_value: [],
            type: 'text',
            is_heyteam: 0,
            is_mandatory: true,
            media: ['image', 'pdf', 'office'],
            file_max_size: null,
            order: 0,
            can_fill_document: false,
            translations: {
                name: {},
                question_multiple_value: {},
                image: {},
            },
            file_translations: {},
        };
    }

    // Todo maybe auto add attribute
    modelAccessors() {
        return {
            name: (n) => (this.is_heyteam ? I18n.translate(n) : n),
        };
    }

    // Convert the data in to something else
    // Will be save "as this"
    // TODO TO RENAME modelDecoder
    modelInConvertor() {
        return {
            can_fill_document: (n) => Boolean(n),
        };
    }

    modelRelations() {
        return {
            companyFiles: () => this.hasMany(CompanyFile),
            tags: () => this.hasMany(CompanyTagTemplate),
        };
    }

    static getRequirementTypes() {
        return [
            {
                label: 'Short text field',
                value: 'string',
            },
            {
                label: 'Long text field',
                value: 'text',
            },
            {
                label: 'Text with controls',
                value: 'text_with_control',
            },
            {
                label: 'Document (Pdf, word, image...)',
                value: 'file',
            },
            {
                label: 'Document to fill',
                value: 'file_to_fill',
            },
            {
                label: 'Document to sign',
                value: 'file_to_sign',
            },
            {
                label: 'Iban',
                value: 'iban',
            },
            {
                label: 'Date',
                value: 'date',
            },
            {
                label: 'Question with a single select',
                value: 'single_select',
            },
            {
                label: 'Question with multiple choices',
                value: 'multiple_select',
            },
            {
                label: 'Address',
                value: 'address',
            },
            {
                label: 'Hexapost',
                value: 'hexapost',
            },
            {
                label: 'Dynamic document',
                value: 'dynamic_document',
            },
            {
                label: 'Social security number',
                value: 'social security number',
            },
            {
                label: 'Phone number',
                value: 'phone',
            },
        ];
    }
}
