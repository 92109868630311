<template>
    <div
        v-if="!isOldUi "
        class="profile-administrative-page"
    >
        <template v-if="companyUser && companyUser.isLoaded() && permissionsLoaded">
            <NewProfileAdministrativePersonal
                v-if="canShowPersonalInformation"
                :user="companyUser"
            />

            <NewUserRequirementProfile
                v-if="$canRead('AbstractProfileRequirements', {company_user_id: companyUser.id})"
                :company-user="companyUser"
                :company-user-program-id="companyUserProgramId"
            />

            <!-- CONTRACT / CONFIDENTIAL INFO -->
            <NewProfileAdministrativeContract
                v-if="$canRead('AbstractProfileContract', {company_user_id: companyUser.id})"
                :user="companyUser"
            />
            <!-- CONFIDENTIAL INFO / COMPENSATION-->
            <NewProfileAdministrativeConfidential
                v-if="$canRead('ModelCompanyUserConfidential', {company_user_id: companyUser.id})"
                :user="companyUser"
            />
        </template>
        <template v-else>
            <HtIconLoading />
        </template>
    </div>
    <div v-else-if="isOldUi && companyUser && companyUser.isLoaded() && permissionsLoaded">
        <!-- PERSONAL INFO / CONFIDENTIAL INFO -->
        <ProfileAdministrativePersonal
            v-if="canShowPersonalInformation"
            :company-user="companyUser"
        />

        <!-- REQUIREMENTS -->
        <UserRequirementProfile
            v-if="$canRead('AbstractProfileRequirements', {company_user_id: companyUser.id})"
            :company-user="companyUser"
            :company-user-program-id="companyUserProgramId"
        />

        <!-- CONTRACT / CONFIDENTIAL INFO -->
        <ProfileAdministrativeContract
            v-if="$canRead('AbstractProfileContract', {company_user_id: companyUser.id}) || $canRead('AbstractProfileProbation', {company_user_id: companyUser.id})"
            :company-user="companyUser"
        />

        <!-- CONFIDENTIAL INFO / COMPENSATION-->
        <ProfileAdministrativeConfidential
            v-if="$canRead('ModelCompanyUserConfidential', {company_user_id: companyUser.id})"
            :company-user-id="companyUser.id"
        />
    </div>
</template>

<script>
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';
import UserRequirementProfile from '@/components/pages/profile/administrative/UserRequirementProfile.vue';
import ProfileAdministrativeContract from '@/components/pages/profile/administrative/ProfileAdministrativeContract.vue';
import ProfileAdministrativeConfidential from '@/components/pages/profile/administrative/ProfileAdministrativeConfidential.vue';
import ProfileAdministrativePersonal from '@/components/pages/profile/administrative/ProfileAdministrativePersonal.vue';
import CompanyUser from '@/models/CompanyUser';
import ProfileBlocMixin from '@/components/mixins/ProfileBlocMixin';
import NewUserRequirementProfile from '@/components/pages/profile/administrative/NewUserRequirementProfile.vue';
import NewProfileAdministrativePersonal from '@/components/pages/profile/administrative/NewProfileAdministrativePersonal.vue';
import NewProfileAdministrativeContract from '@/components/pages/profile/administrative/NewProfileAdministrativeContract.vue';
import NewProfileAdministrativeConfidential from '@/components/pages/profile/administrative/NewProfileAdministrativeConfidential.vue';

export default {
    name: 'ProfileAdministrative',

    components: {
        NewUserRequirementProfile,
        ProfileAdministrativePersonal,
        ProfileAdministrativeContract,
        ProfileAdministrativeConfidential,
        UserRequirementProfile,
        NewProfileAdministrativePersonal,
        NewProfileAdministrativeConfidential,
        NewProfileAdministrativeContract,
    },
    mixins: [ProfileBlocMixin],

    props: {
        companyUser: {
            type: CompanyUser,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },
    computed: {
        isOldUi() {
            return !hasFeatureEnabled(FEATURE_NEW_UI);
        },
    },
    permissions: [
        'ModelCompanyUserConfidential',
        'AbstractProfilePersonal',
        'AbstractProfileContract',
        'AbstractProfileRequirements',
        'AbstractProfileProbation',
        'AbstractProfileUserPersonalInformation',
        'ModelCompanyUser',
    ],
};
</script>
<style lang="scss" scoped>

.profile-administrative-page {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 12px;
    background-color: var(--fill-secondary);
    border-radius: var(--radius-block);
}
</style>
