<template>
    <div class="layout">
        <div class="layout__title">
            <slot name="title" />
        </div>

        <div class="layout__sidebar">
            <slot name="sidebar" />
        </div>

        <div class="layout__container">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewPageWithSidebar',
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.layout {
    display: flex;
    flex-flow: column;
    height: 100%;

    .layout__sidebar {
        display: flex;
        justify-content: center;
    }

    .layout__container {
        flex-grow: 1;
        margin: 0;
    }
}
</style>
