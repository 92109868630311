<template>
    <div
        v-if="items.length"
        class="widget-list"
    >
        <div
            v-for="(item, index) in items"
            :key="item.user_program_id"
            class="widget-list-item"
        >
            <div class="list-item-left">
                <HtAvatar
                    :user="$Utils.getAvatarData(item.user)"
                    class="item-fixed"
                />
                <div class="item-infos item-auto">
                    <div class="item-infos-title">
                        {{ item.user.firstname }} {{ item.user.lastname }}
                    </div>
                    <div class="item-infos-subtitle">
                        <span>{{ item.program_template_name }}</span>
                    </div>
                </div>
            </div>
            <div class="list-item-right">
                <HtButton
                    :loading="remindLoading[index]"
                    @click="remind({id: item.user_program_id, index})"
                >
                    <t>Remind</t>
                </HtButton>
                <HtDropdown
                    position="block-start"
                    align="end"
                    width="200"
                >
                    <template #toggle>
                        <HtButton type="secondary">
                            ...
                        </HtButton>
                    </template>
                    <template #default>
                        <HtDropdownGroup>
                            <HtDropdownItem
                                v-if="canExport"
                                @click.native="exportProgram(item.user_program_id)"
                            >
                                <t>Export program</t>
                            </HtDropdownItem>
                            <HtDropdownItem
                                v-if="canExport"
                                @click.native="exportActions(item.user_program_id)"
                            >
                                <t>Export resources</t>
                            </HtDropdownItem>
                            <HtDropdownItem
                                v-if="!canNotClose(item)"
                                @click.native="closeProgram(item.user_program_id)"
                            >
                                <t>Close</t>
                            </HtDropdownItem>
                        </HtDropdownGroup>
                    </template>
                </HtDropdown>
            </div>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/miscellaneous/HtButton.vue';
import api from '@/store/api';
import ModalMixin from '@/components/globals/modals/modalMixin';

export default {
    name: 'UsersTrackingLate',

    components: {
        HtButton,
    },

    mixins: [
        ModalMixin,
    ],

    data() {
        return {
            remindLoading: [],

        };
    },

    computed: {
        canExport() {
            return this.$can('PageSettingsDataAnalysis');
        },
        items() {
            const { moment } = this.$Utils;

            return this.$store.getters['dashboard/programs/getPage'](1).map((row) => ({
                user: row.user,
                user_program_id: row.program.id,
                user_program_status: row.program.status,
                program_template_name: this.programs.find((p) => p.id == row.program.template.id).name,
                enrollee_completion: row.program.tasks.enrollee_completion,
                participants_completion: row.program.tasks.participants_completion,
                main_key_date_starts_at: moment(row.program.main_key_date.starts_at),
            })).slice(0, 6);
        },
        programs() {
            return this.$store.state.programs.programs.map((program) => ({
                id: program.id,
                name: this.oldLocalize(program.locales, 'name'),
            }));
        },
    },

    created() {
        this.$store.dispatch('dashboard/programs/clearAllFilters');
        this.$store.commit('dashboard/programs/set_statuses_filter', 'late');

        this.$store.dispatch('entities/fetchEntities');
        this.$store.dispatch('programs/fetchPrograms');
        this.$store.dispatch('dashboard/programs/refresh');
        this.$store.dispatch('dashboard/programs/fetchPage', this.page);
    },

    methods: {
        canNotClose(row) {
            return ['completed', 'closed'].includes(row.user_program_status)
                || this.$can('AbstractClosePrograms', { company_user_id: row.user.id }) === false;
        },
        remind({ id, index }) {
            this.genericConfirm(
                this.translate('Confirmation needed'),
                this.translate("You're about to send a reminder to this program"),
            ).then(() => {
                this.$set(this.remindLoading, index, true);
                this.$http.post('dashboard/statistics/send-reminders', {
                    programs: [id],
                });
            })
                .then(() => {
                    this.$store.dispatch('dashboard/programs/refresh', this.page);
                    this.$store.dispatch('dashboard/programs/fetchPage', this.page);
                    this.$set(this.remindLoading, index, false);

                    this.$Notifier('App').showInfo(this.translate('Successful update'));
                }).catch(() => {});
        },
        closeProgram(programId) {
            this.genericConfirm(
                this.translate('Confirmation needed'),
                this.translate("You're about to permanently close this program"),
            ).then(() => this.$http.post('dashboard/statistics/close-programs', {
                programs: [programId],
            }))
                .then(() => {
                    this.$store.dispatch('dashboard/programs/refresh', this.page);
                    this.$store.dispatch('dashboard/programs/fetchPage', this.page);
                    this.$Notifier('App').showInfo(this.translate('Program closed'));
                }).catch(() => {});
        },
        exportProgram(programId) {
            api.miscellaneous.exportUsers({ programs: [programId] }).then((data) => {
                window.location = data.data.link;
            });
        },
        exportActions(programId) {
            api.miscellaneous.exportActions({ programs: [programId] }).then((data) => {
                window.location = data.data.link;
            });
        },
    },

};
</script>

<style lang="scss" scoped src="./PilotingWidgetList.scss" />
