<template>
    <div class="wrapper-all-programs">
        <button
            v-for="program in companyUserProgramCollection.models"
            :key="program.id"
            type="button"
            class="profile-program"
            @click="goToUserProgram(program.id)"
        >
            <div class="program-infos">
                <div class="program-name">
                    {{ program.company_program.name_translated }}
                </div>
                <div class="program-date">
                    {{ translate('Started on {date}', {
                        date: $Utils.moment(program.main_key_date.starts_at).format(
                            language === 'fr' ? 'Do MMMM YYYY' : 'MMMM Do, YYYY'
                        )
                    }) }}
                </div>
            </div>
            <div class="wrapper-status-icon">
                <HtBadge
                    size="large"
                    class="program-status"
                >
                    <HtIndicator :status="program.task_count_late > 0 ? 'error' : 'success'" />
                    <t v-if="program.task_count_late > 0">
                        Late
                    </t>
                    <t v-else-if="program.status === programStatusCompleted">
                        Completed
                    </t>
                    <t v-else>
                        On time
                    </t>
                </HtBadge>
                <HtIcon
                    name="nav-arrow-right"
                    size="20"
                    class="program-icon"
                />
            </div>
        </button>
    </div>
</template>
<script>
import CompanyUserProgramCollection from '@/models/CompanyUserProgramCollection';
import CompanyUserProgram from '@/models/CompanyUserProgram';

export default {
    name: 'NewProfileAllProgram',

    props: {
        companyUserProgramCollection: {
            type: CompanyUserProgramCollection,
            required: true,
        },
    },

    computed: {
        language() {
            return this.shared.session.companyUser.company_language.key;
        },
        programStatusCompleted() {
            return CompanyUserProgram.STATUS_COMPLETED;
        },
    },

    methods: {
        goToUserProgram(id) {
            this.$emit('on-change-program', { value: id });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/ds/typography";

.wrapper-all-programs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    background: var(--fill-secondary);
    border-radius: var(--radius-block);
}
.profile-program {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    text-align: left;
    border-radius: var(--radius);
    border: 1px solid var(--border-primary);
    background: var(--fill-primary);
    box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    transition: var(--transition-s);
    transition-property: transform, box-shadow;
    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 0px 8px 0px rgba(16, 24, 40, 0.07);
    }
}
.program-name {
    @include ht-heading-4;
}
.program-date {
    @include ht-caption;
    color: var(--text-secondary);
}
.wrapper-status-icon {
    display: flex;
    align-items: center;
    gap: 20px;
}
</style>
