import debounce from 'lodash.debounce';
import api from '@/store/api';
import get from 'lodash.get';

export default {
    props: {
        requirement: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
    },

    computed: {
        currentTaskPendingUser() {
            let userTask = this.requirement.tasks.find((task) => task.status === 'pending'
                && task.participants[0].company_user_id === this.shared.session.companyUser.id);

            if (!userTask) {
                userTask = this.requirement.tasks.find((task) => task.status === 'pending');
            }

            return userTask;
        },
    },

    methods: {
        updateRequirementWithDebounce: debounce(function (param) {
            return new Promise((resolve, reject) => {
                this.updateRequirement()
                    .then((data) => {
                        this.$emit('onChangeValue', this.companyUserRequirement.id, param);
                        resolve(data);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        }, 300),

        updateRequirementWithoutTask() {
            return this.callUpdateRequirement({
                resource: this.companyUserRequirement,
            });
        },

        updateRequirement() {
            this.loading = true;
            let currentTask = this.currentTaskPendingUser;

            // A cause du fait que le delegue (company_user_id) puisse etre null
            if (!currentTask) {
                currentTask = this.companyUserRequirement.tasks.find((task) => task.status === 'pending' && task.participants.find((participant) => participant.type === 'preparer'));
            }

            return this.callUpdateRequirement({
                resource: this.companyUserRequirement,
                task: currentTask,
            });
        },

        callUpdateRequirement(data) {
            return new Promise((resolve, reject) => {
                api.user.requirements.update(this.userId, this.companyUserRequirement.id, data)
                    .then(({ data }) => {
                        const requirementResponse = get(data, 'resource');

                        if (requirementResponse) {
                            this.companyUserRequirement = this.formatRequirement(requirementResponse);
                            if (this.$store.state.requirementCategoryEditModal.is_used) {
                                this.$store.dispatch('requirementCategoryEditModal/updateRequirement', this.companyUserRequirement);
                            }
                        }

                        resolve(data);
                    })
                    .catch((err) => {
                        console.error('Error on update requirement', err);

                        this.errorMessage = err.response?.data?.errors?.value?.[0]
                            ? this.translate(err.response?.data?.errors?.value?.[0])
                            : null;
                        reject(err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },

        formatRequirement(requirement) {
            const isJsonValue = requirement.type === 'iban'
                || requirement.type === 'address'
                || requirement.type === 'single_select'
                || requirement.type === 'multiple_select'
                || requirement.type === 'hexapost';

            // We keep only front object value if its not json type (issue letter erase for type requirement text)
            const value = (isJsonValue) ? JSON.parse(requirement.value) : this.companyUserRequirement.value;
            const questionMultipleValue = (requirement.type === 'single_select') ? JSON.parse(requirement.question_multiple_value) : requirement.question_multiple_value;

            return {
                ...requirement,
                value,
                question_multiple_value: questionMultipleValue,
                company_files: [],
            };
        },
    },

    data() {
        return {
            loading: false,
            companyUserRequirement: this.$Utils.clone(this.requirement, true),
        };
    },
};
