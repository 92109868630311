<template>
    <HtCard>
        <template #title>
            <HtCardTitleBar
                :title="title"
                :show-button="true"
                :button-text="translate('Create entity')"
                @onAdd="openEntityForm"
                @search="setSearchValue"
            />
        </template>
        <template #default>
            <HtTable
                v-if="isLoaded"
                :items="entities"
                :columns="columns"
                :external-search="search"
                @onRowClicked="entityClicked"
            >
                <template #cell(name)="{ item }">
                    {{ getEntityName(item) }}
                </template>
                <template #cell(filterCount)="{ item }">
                    {{ item.values.length }}
                </template>
                <template #cell(programs)="{ item }">
                    {{ item.programs.length }}
                </template>
                <template #cell(trash)="{ item }">
                    <div
                        v-if="
                            $canDelete('ModelCompanyEntity', { id: item.id }) &&
                                !Boolean(item.is_heyteam)
                        "
                        data-cy="delete-entity-value"
                        @click.stop="onDestroyConfirm(item)"
                    >
                        <FontAwesomeIcon :icon="['far', 'trash-alt']" />
                    </div>
                </template>
            </HtTable>
            <IconLoading v-else />
            <modalable
                ref="modalableConfirmDeletion"
                class="modal modalable-4 program"
            >
                <p v-if="currentEntity">
                    <t :entity="getEntityName(currentEntity)">
                        You are about to delete this entity ({entity}). It will be removed from the programs and
                        resources where it is used. It may also impact the email content settings. Are you sure you want
                        to continue?
                    </t>
                </p>

                <div class="row-line middle">
                    <Button
                        class="black"
                        cypress="cancel-delete"
                        @click="$refs.modalableConfirmDeletion.close()"
                    >
                        <t>Cancel</t>
                    </Button>
                    <Button
                        class="red"
                        :state="buttonState"
                        cypress="confirm-delete"
                        @click="onDestroy()"
                    >
                        <t>Delete</t>
                    </Button>
                </div>
            </modalable>
        </template>
    </HtCard>
</template>

<script>
import api from '@/store/api';
import I18n from '@/modules/i18n/I18n';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import HtTable from '@/components/globals/HtTable.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import { showNotification } from '@/helpers/ui';

export default {
    components: {
        HtCardTitleBar,
        HtTable,
        IconLoading,
    },

    data() {
        return {
            search: null,
            currentEntity: null,
            isLoaded: false,
            entities: [],
            isDeleting: false,
        };
    },

    computed: {
        title() {
            if (this.loading) {
                return this.translate('Entities');
            }

            return this.translate('{count} entity | {count} entities', {
                count: this.entities.length,
            });
        },

        columns() {
            return [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    search: true,
                    sortable: false,
                },
                {
                    label: this.translate('Filter count'),
                    key: 'filterCount',
                    sortable: false,
                    class: 'w-35',
                },
                {
                    label: this.translate('Program'),
                    key: 'programs',
                    sortable: false,
                    class: 'w-35',
                },
                {
                    label: '',
                    key: 'trash',
                    sortable: false,
                    class: 'text-right',
                },
            ];
        },
        buttonState() {
            return this.isDeleting ? 'loading' : 'idle';
        },
    },

    created() {
        this.fetchData();
    },

    methods: {
        getEntityName(item) {
            return item.is_heyteam ? this.translate(item.name) : item.name;
        },
        fetchData() {
            this.isLoaded = false;
            api.configuration.entities.getAll()
                .then((res) => {
                    this.entities = res.data.data;
                    this.isLoaded = true;
                });
        },
        entityClicked(row) {
            return this.$router.push({
                name: 'SettingsEntitiesForm',
                params: { id: row.item.id },
            });
        },

        openEntityForm() {
            return this.$router.push({
                name: 'SettingsEntitiesForm',
            });
        },

        setSearchValue(value) {
            this.search = value;
        },

        /**
         * Ouvre la modal de suppression
         * @param {object} currentEntity Value a supprimer
         * @returns {void}
         */
        onDestroyConfirm(currentEntity) {
            this.currentEntity = currentEntity;
            this.$refs.modalableConfirmDeletion.open();
        },

        onDestroy() {
            if (!this.isDeleting) {
                this.isDeleting = true;
                api.configuration.entities
                    .delete(this.currentEntity.id)
                    .then(() => {
                        showNotification(I18n.translate('Successfully deleted'));
                        this.fetchData();
                    })
                    .finally(() => {
                        this.$refs.modalableConfirmDeletion.close();
                        this.currentEntity = null;
                        this.isDeleting = false;
                    });
            }
        },
    },
};
</script>
