<template>
    <HtBadge
        v-if="shouldShowMissing"
        size="large"
    >
        <HtIndicator status="error" />
        <t>Missing</t>
    </HtBadge>
    <div v-else>
        {{ getValues }}
    </div>
</template>

<script>
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';

export default {
    name: 'NewUserRequirementMultipleSelectRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
        userCategoryStatus: {
            type: String,
            default: () => CompanyUserRequirementCategory.STATUS_PENDING_USER,
        },
    },

    computed: {
        getValues() {
            if (!this.userRequirement.value) {
                return '-';
            }
            const items = this.userRequirement.value.filter((v) => v.selected);
            return items.length ? items.map((v) => v.value).join(', ') : '-';
        },
        shouldShowMissing() {
            const allowedStatus = [
                CompanyUserRequirementCategory.STATUS_PENDING_USER,
                CompanyUserRequirementCategory.STATUS_DONE,
                CompanyUserRequirementCategory.STATUS_ARCHIVED,
            ];

            return allowedStatus.includes(this.userCategoryStatus) && !this.userRequirement.value;
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementMultipleSelectEdit.scss' scoped />
