<template>
    <div v-if="shouldShowMissing">
        <HtBadge
            size="large"
        >
            <HtIndicator status="error" />
            <t>Missing</t>
        </HtBadge>
    </div>
    <div v-else>
        {{ getFormattedValue }}
    </div>
</template>

<script>
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';

export default {
    name: 'NewUserRequirementDateRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
        userCategoryStatus: {
            type: String,
            default: () => CompanyUserRequirementCategory.STATUS_PENDING_USER,
        },
    },

    computed: {
        getFormattedValue() {
            if (this.userRequirement.value) {
                return this.$Utils.moment(this.userRequirement.value).format('L');
            }

            return '-';
        },

        shouldShowMissing() {
            const allowedStatus = [
                CompanyUserRequirementCategory.STATUS_PENDING_USER,
                CompanyUserRequirementCategory.STATUS_DONE,
                CompanyUserRequirementCategory.STATUS_ARCHIVED,
            ];

            return allowedStatus.includes(this.userCategoryStatus) && !this.userRequirement.value;
        },
    },
};
</script>
