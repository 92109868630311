<template>
    <div
        class="wrapper-frieze"
    >
        <FriezeMultiple
            v-if="programs.length > 1"
            :programs="programs"
        />
        <FriezeSingle
            v-else
            :program="programs[0]"
        />
    </div>
</template>

<script>
import FriezeMultiple from '@/components/pages/dashboard/components/FriezeMultiple.vue';
import FriezeSingle from '@/components/pages/dashboard/components/FriezeSingle.vue';

export default {
    name: 'Frieze',
    components: {
        FriezeMultiple,
        FriezeSingle,
    },
    data() {
        return {
            currentProgramIndex: 0,
            isMobile: false,
        };
    },
    computed: {
        programs() {
            return this.$store.getters['dashboard/actions/activeProgramsSummary'].sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at));
        },
    },
    methods: {
        update() {
            this.$store.dispatch('dashboard/actions/fetchProgramSummaries');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/ds/typography";

.wrapper-frieze {
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-block);
    margin-bottom: 20px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03), 0px 0px 8px 0px rgba(16, 24, 40, 0.03);
    background-color: var(--fill-primary);
}

@media (min-width: $tablet) {
    .wrapper-frieze {
        margin-bottom: 32px;
    }
}
</style>
