<template>
    <ProfileBloc
        :can-edit="canEdit"
        :is-edit.sync="modeEdit"
        @on-save="onSave"
        @on-cancel="onCancel"
    >
        <div class="profile-rows">
            <div
                v-for="(customField, index) in customFields.models"
                :key="customField.id"
                class="profile-row col-md-6"
            >
                <ProfileItem
                    :title="customField.template.name"
                >
                    <div v-if="modeEdit">
                        <HtFormCustomField
                            :id="`custom-field-${customField.template.id}`"
                            v-model="customFields.models[index]"
                            :name="`custom-field-${customField.template.id}`"
                            :type="customField.template.type"
                            :disabled="customField.template.field_is_editable === false"
                        />
                    </div>
                    <div v-else>
                        <div v-if="customField.template.type === 'text'">
                            {{ customField.$.text | empty }}
                        </div>
                        <div v-if="customField.template.type === 'boolean'">
                            {{ customField.$.boolean | boolean }}
                        </div>
                    </div>
                </ProfileItem>
            </div>
        </div>
    </ProfileBloc>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import ProfileItem from '@/components/pages/profile/profile/ProfileItem.vue';
import ProfileBloc from '@/components/pages/profile/ProfileBloc.vue';

export default {
    name: 'ProfileCustomFields',
    components: {
        ProfileItem,
        ProfileBloc,
    },

    props: {
        user: {
            type: CompanyUser,
            required: true,
        },
    },

    data() {
        return {
            modeEdit: false,
        };
    },

    computed: {
        customFields() {
            return this.user.custom_fields;
        },
        canEdit() {
            return this.customFields.models.length > 0;
        },
    },

    methods: {
        onSave() {
            this.user.save({ method: 'updateCustomFields' }).then(() => {
                this.modeEdit = false;
            });
        },
        onCancel() {
            this.user.custom_fields.models.forEach((f) => f.fromSaveState());
        },
    },
};
</script>

<style lang="scss" scoped>
.profile-rows {
    display: flex;
    flex-wrap: wrap;
}

.profile-row {
    margin-top: 3.2rem;
}
</style>
