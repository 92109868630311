<template>
    <div class="main">
        <UserTaskReminderVue
            v-if="isUserTaskView"
            ref="userTaskReminderVue"
            @on-close="isUserTaskView = false"
        />
        <template v-else>
            <div class="head-row">
                <div
                    v-if="hasSelectedItems"
                    class="extra-action-container"
                >
                    <HtButton
                        :loading="remindLoading"
                        :disabled="remindLoading"
                        @click.native="remindSelection"
                    >
                        <t>Remind</t>
                    </HtButton>
                </div>
            </div>

            <HtEntitiesFiltersWrapper
                v-if="!hasSelectedItems"
                v-model="filters.entities"
                :additional-filters-select-length="selectedAdditionalFiltersLength"
                @on-clear-filters="clearFilters"
            >
                <template #title>
                    <HtFormSelector
                        id="custom-filter"
                        v-model="filters.only_where_i_am_participant"
                        name="custom-filter"
                        :options="[
                            {label: translate('All collaborators'), value: false},
                            {label: translate('My team'), value: true}
                        ]"
                        :placeholder="translate('None')"
                        is-single
                        track-by="value"
                        specific-key="value"
                        label-options="label"
                        :allow-empty="false"
                    >
                        <template #prepend>
                            <FontAwesomeIcon
                                :icon="['far', 'filter']"
                            />
                        </template>
                    </HtFormSelector>
                </template>

                <template #topFilter>
                    <HtSearchField
                        id="search"
                        v-model="filters.search"
                        name="search"
                        class="search"
                        :placeholder="translate('Search tasks names...')"
                    />
                </template>

                <template #beforeFilters>
                    <HtFormSelector
                        id="programs"
                        v-model="filters.programs"
                        name="programs"
                        :options="[...programOptions]"
                        :placeholder="translate('Programs')"
                        :selection-label="translate('{count} program | {count} programs',{count: filters.programs.length})"
                        open-direction="bottom"
                        class="program-top-filter"
                    />
                </template>

                <template #afterFilters>
                    <HtFormSelector
                        id="status"
                        v-model="filters.statuses"
                        :placeholder="translate('Status')"
                        :options="statuses"
                        track-by="slug"
                        name="userStatus"
                    />
                </template>
            </HtEntitiesFiltersWrapper>

            <DashboardProgramsEmpty
                v-if="showEmpty"
                :show-remove-button="false"
            />
            <div
                v-else
                class="table"
            >
                <div class="table-scrollable-container">
                    <HTable
                        :use-new-style="true"
                        :columns="columns"
                        :data="items"
                        :loading="loading"
                        @on-row-click="onRowClick"
                    >
                        <template #header(checkbox)>
                            <HTableCellCheckbox
                                v-model="checkAll"
                                :is-header="true"
                            />
                        </template>

                        <template #cell(checkbox)="data">
                            <HTableCellCheckbox
                                v-if="showSelectionToRemind(data.row)"
                                :value="rowChecked(data.row.requirement_id)"
                                @input="toggleCheckbox(data.row.requirement_id)"
                            />
                        </template>

                        <template #cell(requirement_name)="data">
                            {{ data.value }}
                        </template>

                        <template #cell(program_names)="data">
                            <HTableCellTag
                                v-for="program in data.value"
                                :key="program"
                                :label="program"
                                :is-uppercase="false"
                            />
                        </template>

                        <template #cell(statuses)="data">
                            <HTableCellStatusInfos
                                :done-tasks="parseInt(data.value.done)"
                                :pending-tasks="parseInt(data.value.pending)"
                                :awaiting-tasks="parseInt(data.value.awaiting)"
                                :late-tasks="parseInt(data.value.late)"
                                :reminded-tasks="parseInt(data.value.reminded)"
                                :total-tasks="parseInt(data.value.total)"
                            />
                        </template>

                        <template #cell(completion)="data">
                            <HtCompletionBar
                                :percentage="data.value"
                            />
                        </template>
                    </HTable>
                </div>
                <div>
                    <HtPagination
                        v-model="page"
                        :last-page="lastPage"
                        class="pagination"
                        @input="loadData"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {
    HTable,
    HTableCellCheckbox,
    HTableCellTag,
    HTableCellStatusInfos,
} from '@/components/globals/table';
import HtPagination from '@/components/globals/HtPagination.vue';
import api from '@/store/api';
import HtCompletionBar from '@/components/globals/HtCompletionBar.vue';
import debounce from 'lodash.debounce';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import DashboardProgramsEmpty from '@/components/pages/dashboard/components/DashboardProgramsEmpty.vue';
import UserTaskReminderVue from '@/components/Dashboard/UserTaskReminderVue.vue';
import HtEntitiesFiltersWrapper from '@/components/globals/filters/HtEntitiesFiltersWrapper.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import I18n from '@/modules/i18n/I18n';
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'NewResourceReminderVue',
    components: {
        HtButton,
        HtFormSelector,
        HtEntitiesFiltersWrapper,
        UserTaskReminderVue,
        HTable,
        HtPagination,
        HTableCellCheckbox,
        HTableCellTag,
        HTableCellStatusInfos,
        HtCompletionBar,
        HtSearchField,
        DashboardProgramsEmpty,
    },

    data() {
        return {
            items: [],
            selectedItems: [],
            perPage: 15,
            filters: {
                statuses: [],
                entities: [],
                programs: [],
                search: '',
                only_where_i_am_participant: false,
            },
            loading: false,
            remindLoading: false,
            lastPage: 1,
            page: 1,
            isUserTaskView: false,
            statuses: [
                {
                    name: I18n.translate('Done'),
                    slug: 'done',
                },
                {
                    name: I18n.translate('Pending'),
                    slug: 'pending',
                },
                {
                    name: I18n.translate('Awaiting'),
                    slug: 'awaiting',
                },
                {
                    name: I18n.translate('Late'),
                    slug: 'late',
                },
                {
                    name: I18n.translate('Reminded'),
                    slug: 'reminded',
                },
            ],
        };
    },

    computed: {
        columns() {
            return [
                {
                    key: 'checkbox',
                    thStyle: {
                        zIndex: 2,
                        width: '44px',
                    },
                    tdStyle: {
                        zIndex: 2,
                    },
                },
                {
                    label: this.translate('Tasks'),
                    iconClass: ['far', 'check-circle'],
                    key: 'requirement_name',
                    sortable: true,
                    thStyle: {
                        width: '350px',
                    },
                },
                {
                    label: this.translate('Programs'),
                    iconClass: ['far', 'chart-network'],
                    key: 'program_names',
                    sortable: false,
                    thStyle: {
                        width: '350px',
                    },
                },
                {
                    label: this.translate('Completion'),
                    iconClass: ['far', 'users'],
                    key: 'completion',
                    sortable: true,
                    thStyle: {
                        width: '250px',
                    },
                    tdStyle: {
                        'text-align': 'center',
                    },
                },
                {
                    label: this.translate('Status'),
                    iconClass: ['far', 'alarm-clock'],
                    key: 'statuses',
                    sortable: false,
                    thStyle: {
                        width: '300px',
                    },
                    tdStyle: {
                        'text-align': 'center',
                    },
                },
            ];
        },

        hasSelectedItems() {
            return this.selectedItems.length > 0;
        },

        checkAll: {
            set() {
                if (this.selectedItems.length < this.items.length) {
                    this.selectedItems = this.items.filter((item) => this.showSelectionToRemind(item))
                        .map((item) => item.requirement_id);
                } else {
                    this.selectedItems = [];
                }
            },
            get() {
                return this.selectedItems.length === this.items.length;
            },
        },

        showEmpty() {
            return this.items.length === 0 && !this.loading;
        },

        programOptions() {
            return this.$store.state.programs.programs.map((program) => ({
                id: program.id,
                name: this.oldLocalize(program.locales, 'name'),
            }));
        },

        selectedAdditionalFiltersLength() {
            return this.filters.statuses.length + this.filters.programs.length;
        },
    },

    watch: {
        filters: {
            deep: true,
            handler: debounce(function () {
                this.$router.replace({
                    query: {
                        ...this.$route.query,
                        status: this.filters.statuses.map((status) => status.slug),
                    },
                });
                this.loadData(1);
            }, 300),
        },
    },

    async created() {
        if (this.$route.query.status && this.$route.query.status.length === 1 && this.$route.query.status.includes('late')) {
            this.filters.statuses.push({
                name: I18n.translate('Late'),
                slug: 'late',
            });
        }

        await this.loadData();

        if (this.$route.query.task && this.$route.query.task !== 'all') {
            this.isUserTaskView = true;
            const task = this.items.find((i) => i.requirement_id === parseInt(this.$route.query.task, 10));
            this.$nextTick(() => {
                this.$refs.userTaskReminderVue.open(task.requirement_id, task.requirement_name);
            });
        }
    },

    methods: {
        async loadData(page = 1) {
            if (!this.isValidSearch()) return;
            this.loading = true;
            const response = await api.dashboard.getRequirementsToRemind(page, this.perPage, this.filters);
            this.items = this.formatItems(response.data.data);
            this.lastPage = response.data.last_page;
            this.loading = false;
        },

        rowChecked(id) {
            return this.selectedItems.includes(id);
        },

        toggleCheckbox(requirementId) {
            if (this.selectedItems.includes(requirementId)) {
                this.selectedItems = this.selectedItems.filter((id) => id !== requirementId);
            } else {
                this.selectedItems.push(requirementId);
            }
        },

        formatItems(items) {
            return items.map((item) => ({
                ...item,
                completion: Math.floor((item.statuses.done / item.statuses.total) * 100),
            }));
        },

        isValidSearch() {
            return this.filters.search.length === 0 || this.filters.search.length >= 3;
        },

        onRowClick(row) {
            this.isUserTaskView = true;
            this.$nextTick(() => {
                this.$refs.userTaskReminderVue.open(row.requirement_id, row.requirement_name);
            });
        },

        clearFilters() {
            this.filters.programs = [];
            this.filters.statuses = [];
        },

        showSelectionToRemind(row) {
            const { statuses } = row;

            return statuses.late > 0;
        },

        remindSelection() {
            this.remindLoading = true;

            api.dashboard
                .sendRequirementsReminders({
                    resources: this.selectedItems,
                })
                .then(() => {
                    this.selectedItems = [];
                    this.loadData();
                    this.$Notifier('App')
                        .showInfo(this.translate('The employees you selected have received a reminder to complete their tasks.'));
                })
                .finally(() => {
                    this.remindLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.head-row {
    display: flex;
    margin-bottom: 1rem;

    .search-container, .extra-action-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
        margin-left: auto;
    }
}

.table {
    margin-bottom: 80px;
    margin-top: 30px;

    .table-scrollable-container {
        overflow-y: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        table {
            width: 100%;
            white-space: nowrap;
            table-layout: auto !important;
        }
    }
}

.table-button {
    display: block;
}

</style>
