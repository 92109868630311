var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items.length || _vm.loading)?_c('div',{staticClass:"piloting-widget"},[_c('header',{staticClass:"widget-header"},[_c('span',{staticClass:"widget-title"},[_c('t',[_vm._v("Delay by action")])],1),_c('span',{staticClass:"widget-subtitle"},[_c('t',[_vm._v("Track statistical details by type of action")])],1)]),_c('div',{staticClass:"widget-body"},[(_vm.items.length)?_c('div',[_c('div',{staticClass:"widget-list"},_vm._l((_vm.items),function(item,index){return _c('div',{key:item.requirement_id,staticClass:"widget-list-item"},[_c('div',{staticClass:"list-item-left"},[_c('div',{staticClass:"item-infos item-auto"},[_c('div',{staticClass:"item-infos-title"},[_vm._v(" "+_vm._s(item.requirement_name)+" ")]),_c('div',{staticClass:"item-infos-subtitle"},[_c('span',[_vm._v(" "+_vm._s(_vm.translate(`{nbTasks} late tasks`, { nbTasks: item.statuses.late, }))+" ")]),_c('HtTooltip',{staticClass:"item-tooltip",attrs:{"align":"start","position":"block-start","offset":10,"cross-offset":-11},scopedSlots:_vm._u([{key:"target",fn:function(){return [_c('t',[_vm._v("Remind late employees")])]},proxy:true},{key:"supporting",fn:function(){return [_c('t',[_vm._v(" To send a reminder to employees who are overdue for this action, simply click on 'Remind'. ")])]},proxy:true}],null,true)},[_c('HtIcon',{staticClass:"item-tooltip-icon",attrs:{"size":"12","name":"info-circle"}})],1)],1)])]),_c('div',{staticClass:"list-item-right"},[_c('HtButton',{attrs:{"loading":_vm.remindLoading[index]},on:{"click":function($event){return _vm.remind({
                                id: item.requirement_id,
                                index
                            })}}},[_c('t',[_vm._v("Remind")])],1),_c('HtButton',{attrs:{"type":"secondary","to":{
                                name: 'ProgramsTracking',
                                query: { task: item.requirement_id },
                            }}},[_c('t',[_vm._v("See")])],1)],1)])}),0)]):_c('HtIconLoading')],1),(_vm.$can('PageDashboardPrograms'))?_c('button',{staticClass:"widget-footer",attrs:{"type":"button"},on:{"click":_vm.goToAll}},[_c('t',[_vm._v("Discover the rest")])],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }