import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import CompanyEntityCollection from '@/models/CompanyEntityCollection';
import CompanyEntityValue from '@/models/CompanyEntityValue';
import CompanyEntityValueCustom from '@/models/CompanyEntityValueCustom';
import CompanyProgramEntity from '@/models/CompanyProgramEntity';

export default class CompanyEntity extends Model {
    static SLUG_COMPANY_OFFICE = 'company_office';

    static SLUG_COMPANY_CONTRACT = 'company_contract';

    static SLUG_COMPANY_DEPARTMENT = 'company_department';

    static SLUG_COMPANY_JOB_POSITION = 'company_job_position';

    static DEFAULT_ENTITY_SLUGS = [
        CompanyEntity.SLUG_COMPANY_OFFICE,
        CompanyEntity.SLUG_COMPANY_CONTRACT,
        CompanyEntity.SLUG_COMPANY_DEPARTMENT,
        CompanyEntity.SLUG_COMPANY_JOB_POSITION,
    ];

    static SLUG_SITUATION = 'situation';

    modelConfig() {
        return {
            collection: CompanyEntityCollection,
        };
    }

    modelDefaultValues() {
        return {
            is_private: false,
            is_editable: true,
        };
    }

    modelCustomAttributes() {
        return {
            name_translated: null,
            is_custom: false,
        };
    }

    modelInConvertor() {
        return {
            is_heyteam: (n) => Boolean(n),
            is_private: (n) => Boolean(n),
            is_editable: (n) => Boolean(n),
        };
    }

    modelAccessors() {
        return {
            name_translated: () => (this.is_heyteam ? Vue.prototype.$I18n.translate(this.name) : this.name),
            is_custom: () => !this.is_heyteam || !this.slug.includes('company_'),
        };
    }

    modelRelations() {
        return {
            values: () => this.hasMany(CompanyEntityValue, 'company_entity_id', 'id'),
            customData: () => this.hasMany(CompanyEntityValueCustom, 'company_entity_id', 'id'),
            programs: () => this.hasMany(CompanyProgramEntity, 'company_entity_id', 'id'),
        };
    }
}
