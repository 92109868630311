<template>
    <div class="frieze-single">
        <span class="frieze-single-title">
            <t>{{ getProgramDescription }}</t>
        </span>
        <div class="wrapper-container">
            <div class="wrapper-body">
                <HtTooltip
                    position="block-end"
                    align="center"
                    :offset="-4"
                >
                    <div class="frieze-progress-bar bigger">
                        <div class="progress-bar-placeholder" />
                        <div
                            class="progress-bar-indicator"
                            :style="
                                `width: calc(${program.enrollee_completion}*1%);`
                            "
                        />
                    </div>
                    <template #target>
                        {{ translate('{completion}% of tasks completed', {
                            completion: program.enrollee_completion,
                        }) }}
                    </template>
                </HtTooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FriezeSingle',
    props: {
        program: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getProgramPhase() {
            const diffDays = this.$Utils.moment().diff(this.$Utils.moment(this.program.starts_at), 'days');

            if (diffDays < 0) {
                const keyDates = this.$store.getters['config/getKeyDatesBySlug'];
                const keyDateName = this.translate(
                    keyDates[this.program.program_key_date_slug]?.name,
                );
                return this.translate('before {key_date_name}', { key_date_name: keyDateName.toLowerCase() });
            }
            if (diffDays === 0) return this.translate('1st day');
            if (diffDays > 0 && diffDays <= 7) return this.translate('1st week');
            if (diffDays > 7 && diffDays <= 30) return this.translate('1st month');
            if (diffDays > 30) return this.translate('after 1st month');
            return '';
        },
        getProgramDescription() {
            if (this.program.enrollee_completion !== 100) {
                return this.translate('Current phase : {phase}', {
                    phase: this.getProgramPhase,
                });
            }
            return this.translate('You have completed your program');
        },
    },
};
</script>

<style lang="scss" src="./Frieze.scss" scoped></style>
<style lang="scss" scoped>
@import "~@/styles/var";
@import "~@/styles/ds/typography";

.frieze-single {
    padding: 20px 20px 30px;
}
.frieze-single-title {
    display: block;
    @include ht-heading-3;
    margin-bottom: 16px;
}
.wrapper-container {
    position: relative;
    display: flex;
}

.wrapper-body {
    flex: 1 1 0;
}
</style>
