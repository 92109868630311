import api from '@/store/api';
import HtProgramType from '@/models/HtProgramType';

const SET_PROGRAMS = 'set_programs';
const SET_DEFAULT_PROGRAM = 'set_default_program';
const SET_ENTITIES_FILTER = 'set_entities_filter';
const CLEAR_ALL_FILTERS = 'clear_all_filters';

export default {
    namespaced: true,

    state() {
        return {
            filters: {
                entities: [],
            },
            defaultProgram: null,
            programs: [],
            programsLoaded: false,
            programsExpiresAt: null,
        };
    },

    actions: {
        fetchPrograms({
            commit,
            state,
        }, payload = { forceRefresh: false }) {
            if (!payload.forceRefresh && state.programsExpiresAt > this._vm.$moment()) {
                return;
            }

            return api.configuration.programs.getPrograms()
                .then(({ data }) => {
                    const programs = data.data.filter((p) => p.type.slug !== HtProgramType.SLUG_DEFAULT);
                    const defaultProgram = data.data.find((p) => p.type.slug === HtProgramType.SLUG_DEFAULT);

                    commit(SET_PROGRAMS, programs);
                    commit(SET_DEFAULT_PROGRAM, defaultProgram);
                });
        },
        setEntitiesFilter({
            commit,
            state,
        }, {
            company_entity_id,
            values,
        }) {
            const entities = state.filters.entities.filter((e) => e.company_entity_id !== company_entity_id);
            commit(SET_ENTITIES_FILTER, [...entities, ...values]);
        },
        clearAllFilters({ commit }) {
            commit(CLEAR_ALL_FILTERS);
        },
    },

    mutations: {
        [SET_PROGRAMS](state, programs) {
            state.programs = programs;
            state.programsLoaded = true;
            state.programsExpiresAt = this._vm.$moment()
                .add(5, 'minutes');
        },
        [SET_DEFAULT_PROGRAM](state, program) {
            state.defaultProgram = program;
        },
        [SET_ENTITIES_FILTER](state, entities) {
            state.filters.entities = entities;
        },
        [CLEAR_ALL_FILTERS](state) {
            state.filters.entities = [];
        },
    },

    getters: {
        /**
         * @param {Object} state
         * @param {Array} state.programs
         * @returns {Array} filtered programs
         */
        getProgramsByIds: ({ programs }) => (ids) => programs.filter((program) => ids.includes(program.id)),

        /**
         * @param {Object} state
         * @param {Array} state.programs
         * @returns {Array} filtered programs
         */
        findProgramById: ({ programs }) => (id) => programs.find((program) => program.id === id),

        getFilters: ({ filters }) => filters,
    },
};
