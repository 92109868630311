<template>
    <div class="profile-block-wrapper">
        <div class="d-flex align-items-center justify-content-between">
            <span>{{ title }}</span>
            <div class="d-flex flex-direction-column align-items-center">
                <slot name="title-extra" />
                <HtButton
                    v-if="canEdit"
                    type="tertiary"
                    class="btn-edit"
                    @click="onEdit"
                >
                    <HtIcon
                        name="edit-pencil"
                        size="16"
                        stroke-width="2.5"
                    />
                    <t>Edit</t>
                </HtButton>
            </div>
        </div>

        <slot :name="'default'" />
        <div
            v-show="isEdit"
            class="action-buttons"
        >
            <HtButton
                type="secondary"
                :disabled="buttonState === 'loading'"
                @click="onCancel"
            >
                <t>Cancel</t>
            </HtButton>
            <HtButton
                type="primary"
                :loading="buttonState === 'loading'"
                @click="onSave"
            >
                <t>Save modifications</t>
            </HtButton>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/miscellaneous/HtButton.vue';

export default {
    name: 'NewProfileBloc',
    components: {
        HtButton,
    },
    inject: ['$validator'],
    props: {
        isEdit: {
            type: Boolean,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        buttonState: {
            type: String,
            default: 'idle',
        },
        title: {
            type: String,
            default: () => '',
        },
    },

    methods: {
        onCancel() {
            this.$emit('update:isEdit', false);
            this.$emit('on-cancel');
        },

        onSave() {
            if (this.buttonState === 'loading') {
                return;
            }

            this.$emit('on-save');
        },

        onEdit() {
            this.$emit('update:isEdit', !this.isEdit);
        },
    },
};
</script>

<style lang="scss" scoped>

.profile-block-wrapper {
    position: relative;
    padding: 20px 20px 20px;
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-block);
    background-color: var(--fill-primary);
    box-shadow: var(--shadow-1);
}
.btn-edit {
    ::v-deep .ht-button-inner {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}
.action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 32px;
}
</style>
