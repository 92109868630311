<template>
    <header
        v-if="isLoaded"
        v-click-outside="hideMenu"
        class="headbar-menu"
    >
        <div
            class="headbar-menu-burger d-sm-none"
            @click.stop="toggleBurgerMenu()"
        >
            <FontAwesomeIcon icon="bars" />
        </div>
        <div class="headbar-menu-company">
            <div class="headbar-menu-company-link">
                <router-link
                    :to="goToDashboard"
                    @click.native="hideMenu"
                >
                    <img :src="companyLogo">
                </router-link>
            </div>
        </div>
        <div
            v-if="permissionsLoaded"
            class="headbar-menu-links"
            :class="menuClass"
        >
            <div
                v-for="(link, index) in links"
                :key="index"
                class="headbar-menu-links-item"
            >
                <router-link
                    :to="link"
                    :data-cy="'menu-'+link.name"
                    @click.native="onClick"
                >
                    <t>{{ link.label }}</t>
                </router-link>
            </div>
        </div>
        <div class="headbar-menu-profile">
            <HeadBarNotification
                @on-view="hideMenu"
            />
            <div
                v-user-image="companyUserLogo"
                class="headbar-menu-profile-user"
                @click.stop="toggleUserMenu()"
            />
            <div
                class="headbar-menu-profile-user-mobile"
                @click.stop="toggleUserMenu()"
            >
                <FontAwesomeIcon :icon="['far', 'user']" />
            </div>

            <HeadBarDropDown
                v-click-outside="hideUserMenu"
                :show-user-menu="showUserMenu"
                @hide-menu="hideUserMenu"
            />
        </div>
    </header>
</template>

<script>
import HeadBarDropDown from '@/components/miscellaneous/HeadBarDropdown.vue';
import HeadBarNotification from './HeadBarNotification.vue';

const pages = [
    'PageDashboard',
    'PagePrograms',
    'PageSurveyResults',
    'PageResources',
    'PageCompany',
    'PageTeam',
    'PageSettings',
];

export default {
    name: 'HeadBarMenu',
    permissions: [...pages],

    components: {
        HeadBarNotification,
        HeadBarDropDown,
    },

    shared: {
        session: {},
    },

    data() {
        return {
            showUserMenu: false,
            showBurgerMenu: false,
        };
    },

    computed: {
        menuClass() {
            return this.showBurgerMenu ? 'd-flex' : 'd-none d-sm-flex';
        },
        isLoaded() {
            return this.shared.session.company && this.shared.session.companyUser;
        },
        goToDashboard() {
            return {
                name: 'Dashboard',
            };
        },
        companyLogo() {
            return this.$Utils.resolveS3PublicImage(this.shared.session.company.image_square);
        },
        companyUserLogo() {
            return {
                firstname: this.shared.session.companyUser.firstname,
                lastname: this.shared.session.companyUser.lastname,
                image: this.shared.session.companyUser.image,
            };
        },
        links() {
            return pages
                .filter((page) => this.$can(page))
                .map((page) => ({
                    name: page.replace('Page', ''),
                    label: page.replace('Page', ''),
                }));
        },
    },

    methods: {
        toggleUserMenu() {
            this.hideMenu();
            this.showUserMenu = !this.showUserMenu;
        },
        toggleBurgerMenu() {
            this.showBurgerMenu = !this.showBurgerMenu;
        },
        hideUserMenu() {
            this.showUserMenu = false;
        },
        hideMenu() {
            this.showBurgerMenu = false;
        },
        onClick() {
            this.hideMenu();
            this.$emit('on-change-page');
        },
    },
};
</script>

<style lang="scss" scoped src="./HeadBarMenu.scss"></style>
