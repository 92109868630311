<template>
    <div>
        <form
            ref="fileSelectorForm"
            class="fileSelectorForm"
        >
            <input
                id="file-selector"
                ref="fileSelector"
                type="file"
                :accept="accept"
                :style="{
                    display: !customLabel ? 'block': 'none'
                }"
                :multiple="multiple"
            >
            <label
                v-if="customLabel"
                for="file-selector"
                class="custom-file-upload"
            >
                <t>Add a file</t>
            </label>
        </form>
    </div>
</template>

<script>
import DownloadUploadFileMixin from '@/components/mixins/DownloadUploadFileMixin';

export default {
    name: 'Uploadable',

    mixins: [
        DownloadUploadFileMixin,
    ],

    props: {
        isRequirement: {
            type: Boolean,
            default: false,
        },
        maxSize: {
            type: Number,
            default: 512,
        },
        media: {
            type: Array,
            default() {
                return [];
            },
        },
        extension: {
            type: Array,
            default() {
                return null;
            },
        },
        ressource: {
            type: String,
            required: true,
        },
        customLabel: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            mimeType: [],
            uploadObj: null,
        };
    },

    computed: {
        accept() {
            return this.mimeType.join(',');
        },
    },

    watch: {
        media: {
            deep: true,
            handler() {
                this.initMimeTypes();
            },
        },
    },

    mounted() {
        this.$refs.fileSelector.addEventListener('change', this.onFileChange);

        this.initMimeTypes();
    },

    beforeDestroy() {
        this.$refs.fileSelector.removeEventListener('change', this.onFileChange);
    },

    methods: {
        initMimeTypes() {
            this.mimeType = [];

            for (const i in this.media) {
                if (Object.prototype.hasOwnProperty.call(this.media, i)) {
                    if (this.media[i] === 'office') {
                        this.mimeType.push('application/msword');
                        this.mimeType.push('application/vnd.openxmlformats-officedocument.wordprocessingml.document');
                        this.mimeType.push('application/vnd.openxmlformats-officedocument.wordprocessingml.template');
                        this.mimeType.push('application/vnd.ms-word.document.macroEnabled.12');
                        this.mimeType.push('application/vnd.ms-word.template.macroEnabled.12');

                        this.mimeType.push('application/vnd.ms-excel');
                        this.mimeType.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                        this.mimeType.push('application/vnd.openxmlformats-officedocument.spreadsheetml.template');
                        this.mimeType.push('application/vnd.ms-excel.sheet.macroEnabled.12');
                        this.mimeType.push('application/vnd.ms-excel.template.macroEnabled.12');
                        this.mimeType.push('application/vnd.ms-excel.addin.macroEnabled.12');
                        this.mimeType.push('application/vnd.ms-excel.sheet.binary.macroEnabled.12');

                        this.mimeType.push('application/vnd.ms-powerpoint');
                        this.mimeType.push('application/vnd.openxmlformats-officedocument.presentationml.presentation');
                        this.mimeType.push('application/vnd.openxmlformats-officedocument.presentationml.template');
                        this.mimeType.push('application/vnd.openxmlformats-officedocument.presentationml.slideshow');
                        this.mimeType.push('application/vnd.ms-powerpoint.addin.macroEnabled.12');
                        this.mimeType.push('application/vnd.ms-powerpoint.presentation.macroEnabled.12');
                        this.mimeType.push('application/vnd.ms-powerpoint.template.macroEnabled.12');
                        this.mimeType.push('application/vnd.ms-powerpoint.slideshow.macroEnabled.12');

                        this.mimeType.push('application/x-iwork-pages-sffpages');
                        this.mimeType.push('text/*');
                    }

                    if (this.media[i] === 'docx') {
                        this.mimeType.push('application/msword');
                        this.mimeType.push('application/vnd.openxmlformats-officedocument.wordprocessingml.document');
                        this.mimeType.push('application/vnd.openxmlformats-officedocument.wordprocessingml.template');
                        this.mimeType.push('application/vnd.ms-word.document.macroEnabled.12');
                        this.mimeType.push('application/vnd.ms-word.template.macroEnabled.12');
                    }

                    if (this.media[i] === 'csv') {
                        this.mimeType.push('text/*');
                        this.mimeType.push('application/vnd.ms-excel');
                    }

                    if (this.media[i] === 'pdf') {
                        this.mimeType.push('application/pdf');
                    }

                    if (this.media[i] === 'image') {
                        this.mimeType.push('image/*');
                    }

                    if (this.media[i] === 'video') {
                        this.mimeType.push('video/mp4');
                        this.mimeType.push('video/ogg');
                        this.mimeType.push('video/webm');
                    }
                }
            }
        },

        open() {
            this.$refs.fileSelector.value = null;
            this.$refs.fileSelector.click();
        },

        validateFile(file) {
            const max = this.maxSize || 512;
            if (this.$Utils.convertByteToMegabyte(file.size) > max) {
                this.$Notifier('App')
                    .showError(this.translate('The file is too large, it is over the {size} Mo max size.', { size: this.maxSize }));
                return false;
            }
            if (file.size === 0) {
                this.$Notifier('App')
                    .showError(this.translate('The file is empty!'));
                return false;
            }
            if (
                (file.type.startsWith('text/') && !this.media.includes('office') && !this.media.includes('csv'))
                || (file.type.startsWith('image/') && !this.media.includes('image'))
                || (!file.type.startsWith('image/') && !file.type.startsWith('text/') && !this.mimeType.includes(file.type))
            ) {
                this.$Notifier('App')
                    .showError(this.translate('The file format or file extension is not valid!'));
                return false;
            }

            if (
                this.extension
                && !this.extension.includes(file.name.split('.')
                    .pop())
            ) {
                return false;
            }
            return true;
        },
        uploadFile(file) {
            return this.uploadCompanyFile(file, this.isRequirement);
        },

        onFileChange(e) {
            const { files } = e.target;
            if (files.length === 0 || !files[0].name) {
                return;
            }
            for (let i = 0; i < files.length; i += 1) {
                if (!this.validateFile(files[i])) continue;

                this.cancelUpload();
                if (this.multiple) {
                    if (!this.uploadObj) this.uploadObj = [];
                    this.uploadObj.push(this.uploadFile(files[i]));
                } else {
                    this.uploadObj = this.uploadFile(files[i]).call;
                }
            }
        },

        cancelUpload(name = null) {
            if (this.uploadObj) {
                // if only one file, we cancel the upload
                if (!this.multiple) {
                    this.uploadObj.cancel();
                    // if multiple files and name provided
                    // we cancel the upload with the corresponding name
                } else if (name) {
                    const uploadObjToCancel = this.uploadObj
                        .find((uploadObj) => uploadObj.name === name);
                    uploadObjToCancel?.call?.cancel?.();
                    // if multiple files and no name provided
                    // we cancel all uploads
                } else if (this.uploadObj.length) {
                    for (let i = 0; i < this.uploadObj.length; i += 1) {
                        this.uploadObj[i].cancel?.();
                    }
                }
            }
        },
    },
};
</script>

<style scoped>
.fileSelectorForm {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
}
</style>
