<template>
    <div class="d-flex flex-column px-5 pt-5">
        <div class="mb-5">
            <h1>Traductions (locales)</h1>
        </div>

        <HtCard>
            <template #title>
                <div class="card-title-bar-header">
                    <div class="card-title-bar-left">
                        <HtSearchField
                            :id="'search'"
                            v-model.trim="search"
                            :name="'search'"
                            placeholder="Rechercher par la clé anglaise"
                            @input="filterSearch"
                        />
                    </div>
                    <div class="card-title-bar-right">
                        <HtFormSwitch
                            id="missing"
                            v-model="showMissing"
                            name="missing"
                            label="N'afficher que ceux à remplir"
                            :show-optional="false"
                            @input="filterLocales"
                        />
                    </div>
                </div>
            </template>
            <template #default>
                <HtTableExtended
                    :items="items"
                    :columns="columns"
                    cypress="action-table"
                    :has-quick-actions="true"
                    :loading="loading"
                    :skeleton-rows="15"
                    @on-row-clicked="openLocaleItem"
                >
                    <template #cell(fr)="scope">
                        <HtFormTextarea
                            :id="`fr-${scope.item.id}`"
                            v-model="scope.item.fr"
                            :name="`fr-${scope.item.id}`"
                            @input="save(scope.item)"
                        />
                    </template>
                    <template #cell(created_at)="scope">
                        <span>{{ scope.item.created_at | formatted }}</span>
                    </template>
                    <template #cell(quick_actions)="scope">
                        <div class="d-flex align-items-center">
                            <div
                                :class="['button-delete']"
                                @click.stop="confirmDestroy(scope.item)"
                            >
                                <FontAwesomeIcon
                                    class="button-delete-icon"
                                    :icon="['far', 'trash-alt']"
                                />
                            </div>
                        </div>
                    </template>
                </HtTableExtended>
                <HtPagination
                    v-model="page"
                    :last-page="lastPage"
                    @input="filterLocales"
                />
            </template>
        </HtCard>
    </div>
</template>

<script>
import moment from 'moment';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtTableExtended from '@/components/globals/HtTable/HtTableExtended.vue';
import modalMixin from '@/components/globals/modals/modalMixin';
import HtPagination from '@/components/globals/HtPagination.vue';
import debounce from 'lodash.debounce';
import Branding from '@/Branding';
import LocaleItem from './LocaleItem.vue';

export default {
    name: 'LocalePage',

    components: {
        HtSearchField,
        HtFormSwitch,
        HtTableExtended,
        HtPagination,
    },

    filters: {
        moment(date) {
            return moment(date).format('MMMM Do YYYY');
        },
        formatted(date) {
            return moment(date).format('D MMMM YYYY');
        },
    },

    mixins: [
        modalMixin,
    ],

    data() {
        return {
            items: [],
            search: '',
            showMissing: false,
            loading: false,
            page: 1,
            lastPage: 1,
            filterSearch: debounce((value) => this.searchLocale(value), 400),
            saveWithDebounce: debounce((value) => this.$I18n.saveTranslation(value), 400),
            columns: [
                {
                    label: 'Key',
                    key: 'en',
                    class: 'action-title',
                },
                {
                    label: 'Français',
                    key: 'fr',
                    class: 'width-textarea',
                },
                {
                    label: 'Route (url générant la clé)',
                    key: 'route',
                    class: 'd-none d-md-table-cell',
                },
                {
                    label: 'Composant',
                    key: 'component_name',
                    class: 'd-none d-md-table-cell',
                },
                {
                    label: 'Date de création',
                    key: 'created_at',
                    sortable: false,
                    class: 'd-none d-md-table-cell',
                },
            ],
        };
    },

    created() {
        Branding.setPrimaryBranding({});
        this.fetchLocales();
    },

    methods: {
        searchLocale() {
            const searchLength = this.search.length;

            if (searchLength >= 3 || searchLength === 0) {
                this.page = 1;
                const params = {
                    params: {
                        page: this.page,
                        only_params: this.showMissing,
                    },
                };

                if (searchLength >= 3) {
                    params.params.search = this.search;
                }

                this.fetchLocales(params);
            }
        },

        filterLocales() {
            const params = {
                params: {
                    page: this.page,
                    only_empty: this.showMissing,
                },
            };

            if (this.search.length > 0) {
                params.params.search = this.search;
            }

            this.fetchLocales(params);
        },

        fetchLocales(params = null) {
            if (this.loading === false) {
                this.loading = true;
                this.$http.get('admin/ht_locale', params).then(({ data }) => {
                    this.items = data.data.data;
                    this.loading = false;
                    this.lastPage = data.data.last_page;
                }).catch(() => {
                    this.$router.push('LoginMaster');
                });
            }
        },

        confirmDestroy(locale) {
            this.genericConfirm(
                'Confirmation requise.',
                'Êtes-vous sûr de vouloir supprimer cette clé de traduction ?',
                null,
                'Confirmer',
                'Annuler',
            ).then(() => {
                this.destroy(locale);
            });
        },

        destroy(locale) {
            this.$I18n.deleteTranslation(locale).then(() => {
                this.filterLocales();
            });
        },

        save(item) {
            this.saveWithDebounce(item);
        },

        openLocaleItem({ item }) {
            const title = item.en;
            const locale = item;
            this.openCenterModal(
                LocaleItem,
                {
                    title,
                    locale,
                    acceptLabel: 'Sauvegarder',
                    cancelLabel: 'Annuler',
                },
                {
                    'before-close': (event) => {
                        if (event.params === 'accept') {
                            this.filterLocales();
                        }
                    },
                },
                {
                    reset: true, adaptive: true, styles: 'border-radius: 16px; box-shadow:none;',
                },
            );
        },
    },

};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.card-title-bar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.card-title-bar-right {
    display: flex;
    align-items: center;

    &-close {
        border-radius: 50%;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-lightblue-grey;
        cursor: pointer;
        transition: all 0.3s;

        .icon {
            font-size: 2rem;
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}

h1 {
    margin: 0;
}

.button-delete {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        .button-delete-icon {
            color: $black;
        }
    }

    &.disabled {
        .button-delete-icon {
            color: $grey-dark;
        }

        &:hover {
            cursor: not-allowed;

            .button-delete-icon {
                color: $grey-dark;
            }
        }
    }

    &-icon {
        color: $text;
        transition: color .5s;
    }
}

@media (max-width: $phone) {
    .card-title-bar-header {
        margin: 1.6rem 0;

        flex-direction: column;
        align-items: flex-start;
        gap: 1.6rem;
    }
}
</style>
