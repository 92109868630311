import CardImgLeftSection from './CardImgLeftSection';

export default {
    name: 'Triple cards',
    image: 'triple-card.png',
    fullPageBorder: true,
    content: `
        <section class="ht-section-triple-card-img">
            <div class="card">
                <div class="card-image">
                    <img src="https://via.placeholder.com/200x200" alt="Placeholder Image" />
                </div>
                <div class="card-content">
                    <h2 class="title"><a href="#">Lorem ipsum dolor....</a></h2>
                    <p>... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias corporis deserunt ducimus labore non omnis quibusdam sapiente suscipit voluptate?.</p>
                </div>
            </div>

            <div class="card">
                <div class="card-image">
                    <img src="https://via.placeholder.com/200x200" alt="Placeholder Image" />
                </div>
                <div class="card-content">
                    <h2 class="title"><a href="#">Lorem ipsum dolor....</a></h2>
                    <p>... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias corporis deserunt ducimus labore non omnis quibusdam sapiente suscipit voluptate?.</p>
                </div>
            </div>

            <div class="card">
                <div class="card-image">
                    <img src="https://via.placeholder.com/200x200" alt="Placeholder Image" />
                </div>
                <div class="card-content">
                    <h2 class="title"><a href="#">Lorem ipsum dolor....</a></h2>
                    <p>... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias corporis deserunt ducimus labore non omnis quibusdam sapiente suscipit voluptate?.</p>
                </div>
            </div>
        </section>
    `,
    css: `
        ${CardImgLeftSection.css}

        .ht-section-triple-card-img {
            display: flex;
            flex-direction: row;
            gap: 16px;
            padding: 40px 0;
            justify-content: space-between; /* Garantir un espacement égal entre les cartes */
        }

        .card {
            flex-direction: column;
        }

        .card-image {
            max-width: 100%; /* Assure que l'image occupe toute la largeur disponible */
            max-height: 200px; /* Limite la hauteur pour éviter que les images soient trop grandes */
            justify-content: center;
            align-items: center;
        }

        .card-content {
            flex: 1;
            padding: 10px;
            align-items: center;
        }

        .title {
            font-size: 24px;
            line-height: 28px;
            font-weight: bold;
            color: var(--primary-base);
            margin-bottom: 16px;
        }

        .title a {
            color: var(--primary-base);
        }

        .card-content p {
            font-size: 16px;
            line-height: 20px;
            font-weight: regular;
        }

        @media (max-width: 768px) {
            .ht-section-triple-card-img {
                flex-direction: column; /* Les cartes s'empilent sur les petits écrans */
                align-items: center;
            }

            .card {
                max-width: 100%; /* Assure que les cartes ne dépassent pas la largeur de l'écran */
            }

            .card-image {
                max-height: 200px; /* Limite la hauteur des images sur mobile */
            }

            .title a {
                color: var(--primary-base); /* Couleur de base */
                text-decoration: none; /* Retirer le soulignement */
                transition: color 0.3s ease; /* Pour un effet de transition fluide */
            }

            .title a:hover {
                color: #AA2184; /* Couleur au survol */
            }
        }
    `,
};
