<template>
    <div
        id="login-page"
    >
        <div id="login-content">
            <div v-show="step === 'default'">
                <div class="login-component">
                    <h1
                        class="align-center login-width"
                    >
                        AutoConnect
                    </h1>
                    <div
                        class="login-width"
                    >
                        <p
                            v-if="error"
                            class="error-message"
                        >
                            {{ error }}
                        </p>
                        <form
                            class="form"
                            @submit.prevent
                        >
                            <div v-show="showForm">
                                <fieldset>
                                    <input
                                        id="email-login"
                                        v-model.trim="user.email"
                                        v-next-field
                                        v-fvalidate="'required|email'"
                                        type="text"
                                        name="email"
                                        :placeholder="translate('Type email here')"
                                    >
                                </fieldset>
                                <fieldset>
                                    <input
                                        id="password-login"
                                        v-model.trim="user.password"
                                        v-next-field
                                        v-fvalidate="'required'"
                                        type="password"
                                        name="password"
                                        :placeholder="translate('Type your password here')"
                                    >
                                </fieldset>
                                <HtButton
                                    type="primary"
                                    class="button-login"
                                    :loading="isLoading"
                                    :disabled="isLoading"
                                    @keyup.13="onLogin"
                                    @click.native="onLogin"
                                >
                                    Login
                                </HtButton>
                            </div>

                            <div class="wrapper-button-form">
                                <HtButton
                                    type="primary"
                                    class="sign-with-google-btn"
                                    :loading="isLoading"
                                    :disabled="isLoading"
                                    @click.native="onGoogleLogin"
                                >
                                    Sign in with Google
                                </HtButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'LoginMaster',
    components: { HtButton },
    data() {
        return {
            step: 'default',
            isLoading: false,
            error: '',
            showForm: this.$route.query?.show_form === 'true',
            user: {
                email: '',
                password: '',
                domain: '',
            },
        };
    },

    methods: {
        setIsLoadingToFalse() {
            // Avoid spam clicks
            setTimeout(() => {
                this.isLoading = false;
            }, 2000);
        },

        async onLogin() {
            this.isLoading = true;
            this.error = '';
            this.API.post('admin/login', { email: this.user.email, password: this.user.password, domain: this.$env.getCompanyDomain(document.location.href) }, {
                200: () => {
                    if (this.$router.historyTrack[0].path !== '/') this.$router.push(this.$router.historyTrack[0].path);
                    else {
                        this.$router.push('/Autoconnect');
                    }
                },

                404: (data) => {
                    this.error = (this.translate(data.message) ?? data.message) ?? data;
                    this.setIsLoadingToFalse();
                },

                403: (data) => {
                    this.error = (this.translate(data.message) ?? data.message) ?? data;
                    this.setIsLoadingToFalse();
                },

                423: (data) => {
                    this.error = (this.translate(data.content) ?? data.content) ?? data;
                    this.setIsLoadingToFalse();
                },

                422: (data) => {
                    this.error = (this.translate(data.message) ?? data.message) ?? data;
                    this.setIsLoadingToFalse();
                },

                500: (data) => {
                    this.error = data;
                    this.setIsLoadingToFalse();
                },
                onError: () => {
                    this.setIsLoadingToFalse();
                },
            });
        },
        onGoogleLogin() {
            this.isLoading = true;
            window.location.href = '/LoginMasterV2';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

@media only screen and (max-width: 375px) {
    #login-page {
        .login-component {
            padding: 25px 30px;
        }

        #email-login, #password-login, .button-login, .login-width {
            width: 262px;
        }

        #login-content {
            justify-content: space-between;
            height: 100vh;
            padding: 5em 0 2em;
        }
    }
}

@media screen and (min-width: 376px) and (max-width: 599px) {
    #login-page {
        .login-component {
            padding: 25px 50px;
        }

        #email-login, #password-login, .button-login, .login-width {
            width: 262px;
        }

        #login-content {
            justify-content: space-between;
            height: 100vh;
            padding: 5em 0 2em;
        }
    }
}

@media screen and (min-width: 600px) {
    #login-page {
        .login-background {
            width: 30%;
            height: 100%;
        }

        .login-component {
            padding: 44px 85px;
        }

        #create-password, #create-confirm-password, .button-login, .login-width {
            width: 500px;
        }
    }
}

#login-page {
    height: 100vh;
    display: flex;
    align-items: center;
    -ms-flex-pack: center;
    -ms-flex-align: center;
    background: url(/static/images/login/bg-login.png) center center / cover no-repeat fixed;

    h1 {
        font-family: Lato;
        font-size: 4rem;
        font-style: normal;
        font-weight: bold;
        line-height: 5rem;
        letter-spacing: 0rem;
        margin-bottom: 30px;
    }

    h2 {
        font-family: Lato;
        font-size: 2rem;
        font-style: normal;
        font-weight: bold;
        line-height: 3rem;
        letter-spacing: 0rem;
        margin-bottom: 0px;
    }

    #login-content {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #login-content img{
        margin: auto;
        display: block;
        max-height: 80px;
    }

    .login-component {
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        position: relative;

        .error-message {
            font-size: 12px;
            color: red;
            height: 24px;
        }
    }

    #email-login, #password-login {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 16px;
        background: $neutral-white;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 14px;
        line-height: 17px;
    }

    .button-login {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 24px;
        height: 48px;
        background: #FF5656;
        border-radius: 4px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
        color: #FFFFFF;
        border: none;;
        width: 100%
    }

    .login-input-info {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        /* or 118% */

        color: #979797;
    }

    p {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #342E37;
        padding-bottom: 2rem;
    }
}

.sign-with-google-btn {
    width: 100%;
    transition: background-color .3s, box-shadow .3s;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #757575;
    font-size: 16px;
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;

    &:hover {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
    }

    &:active {
        background-color: #eeeeee;
    }

    &:focus {
        outline: none;
        box-shadow:
            0 -1px 0 rgba(0, 0, 0, .04),
            0 2px 4px rgba(0, 0, 0, .25),
            0 0 0 3px #c8dafc;
    }

    &:disabled {
        filter: grayscale(100%);
        background-color: #ebebeb;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
        cursor: not-allowed;
    }
}

.error-message {
    color: $error;
    font-size: 14px;
    margin-bottom: 4rem;
    font-weight: bold;
}
</style>
