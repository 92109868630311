<template>
    <HtTablePaginated
        :title="translate('Invitations')"
        :program-entities-ids="programEntitiesId"
        :url="`programs/${companyProgramId}/invitations`"
        :columns="columns"
        cypress="invitations-table"
        :has-quick-actions="false"
        :button-text="translate('Create new invitation')"
        @on-row-clicked="onRowClicked"
    >
        <template #cell(items_count)="{ item }">
            <t :count="item.items_count">
                Invitation of {count} participants
            </t>
        </template>

        <template #cell(status)="{ item }">
            <HtTag
                :color="getStatusColor(item.status)"
            >
                {{ getStatusText(item.status) }}
            </HtTag>
        </template>

        <template #cell(creator)="{ item }">
            <TableCellUser
                :user="item.creator"
            />
        </template>

        <template #cell(created_at)="{ item }">
            {{ $Utils.moment(item.created_at).format('L') }}
        </template>
    </HtTablePaginated>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalMixin from '@/components/globals/modals/modalMixin';
import HtTablePaginated from '@/components/globals/HtTable/HtTablePaginated.vue';
import TableCellUser from '@/components/globals/table/TableCellUser.vue';
import HtTag from '@/components/globals/HtTag.vue';

export default {
    name: 'ProgramDetailInvitations',

    permissions: [
        'ModelCompanyUserProgram',
    ],

    components: {
        HtTag,
        TableCellUser,
        HtTablePaginated,
    },

    mixins: [
        ModalMixin,
    ],

    props: {
        companyProgramId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            invitationsCount: 0,
            programEntitiesId: [],
            columns: [
                {
                    label: this.translate('Participants number'),
                    key: 'items_count',
                },
                {
                    label: this.translate('Status'),
                    key: 'status',
                },
                {
                    label: this.translate('Author'),
                    key: 'creator',
                },
                {
                    label: this.translate('Start date'),
                    key: 'created_at',
                },
            ],
        };
    },

    created() {
        this.$store.dispatch('entities/fetchEntities')
            .then(() => {
                this.programEntitiesId = this.$store
                    .state
                    .entities
                    .entities
                    .filter((entity) => entity.programs.map((p) => p.id).includes(this.companyProgramId))
                    .map((entity) => entity.id);
            });
    },

    methods: {
        onRowClicked({ item }) {
            this.$router.push({
                name: 'BulkEnrolling',
                params: {
                    id: item.id,
                },
            });
        },

        getStatusColor(status) {
            switch (status) {
            case 'draft':
                return 'orange';
            case 'generation_in_progress':
                return 'blue';
            case 'in_progress':
                return 'green';
            }

            return status;
        },

        getStatusText(status) {
            switch (status) {
            case 'draft':
                return this.translate('Draft');
            case 'generation_in_progress':
                return this.translate('Generation in progress');
            case 'in_progress':
                return this.translate('In progress');
            }

            return status;
        },
    },

    computed: {
        ...mapGetters('programs', [
            'findProgramById',
        ]),
    },
};
</script>
