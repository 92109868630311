<template>
    <HtTabs
        v-if="isLoaded"
        @onChangeTab="onChangeTab"
    >
        <HtTab
            v-for="(tab, index) in getAvailableTabs"
            :key="index"
            :ref="tab.ref"
            :title="tab.title"
            :disabled="tab.disabled"
            :no-body="tab.noBody"
        >
            <component
                :is="tab.component"
                ref="currentPage"
                :item="item"
                :default-filters="defaultFilters"
                :default-order-onboarding-start="defaultOrderOnboardingStart"
                :default-order-onboarding-end="defaultOrderOnboardingEnd"
                :add-default-order-completion="addDefaultOrderCompletion"
                :show-list-on-end="showListOnEnd"
                :show-button-delete="showButtonDelete"
                :disabled-program-list="disabledProgramList"
                :show-toggle-survey="showToggleSurvey"
                @goTo="handleGoTo"
                @onSave="onSave"
                @onDelete="$emit('onDelete')"
            />
        </HtTab>
        <template #headerRightContent>
            <slot name="headerRightContent">
                <HtIconClose @click="$emit('on-close-stepper')" />
            </slot>
        </template>
    </HtTabs>
</template>

<script>
import CompanyProgram from '@/models/CompanyProgram';
import CompanyRequirementCategory from '@/models/CompanyRequirementCategory';
import CompanyRole from '@/models/CompanyRole';
import CompanySurvey from '@/models/CompanySurvey';

import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import HidedTabsMixin from '@/components/mixins/HidedTabsMixin';
import showButtonActionMixin from '@/components/mixins/showButtonActionMixin';
import CompanySurveyCollection from '@/models/CompanySurveyCollection';
import CompanyRequirementCategoryCollection from '@/models/CompanyRequirementCategoryCollection';
import CompanyRoleCollection from '@/models/CompanyRoleCollection';
import CompanyProgramCollection from '@/models/CompanyProgramCollection';
import CompanyResourceProgramCollection from '@/models/CompanyResourceProgramCollection';
import HtTabs from '@/components/globals/HtTabs.vue';
import HtTab from '@/components/globals/HtTab.vue';
import HtIconClose from '@/components/icons/HtIconClose.vue';
import stepperFormEventBus from '@/eventBus/stepperFormEventBus';
import CompanyPlanningEventTemplateCollection from '@/models/CompanyPlanningEventTemplateCollection';
import CompanyPlanningEventTemplate from '@/models/CompanyPlanningEventTemplate';
import CompanyEntityCollection from '@/models/CompanyEntityCollection';
import CompanyEntity from '@/models/CompanyEntity';
import ResourceCollection from '@/models/ResourceCollection';

export default {
    name: 'HtTabStepperModel',
    components: {
        HtTabs,
        HtTab,
        HtIconClose,
    },
    mixins: [
        HidedTabsMixin,
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
        showButtonActionMixin,
    ],

    props: {
        id: {
            type: Number,
            default: null,
        },
        tabs: {
            type: Array,
            required: true,
        },
        modelName: {
            type: String,
            default: null,
        },
        collectionName: {
            type: String,
            default: null,
        },
        itemFields: {
            type: Array,
            default: () => [],
        },
        itemRelations: {
            type: Object,
            default: () => {
            },
        },
        showListOnEnd: {
            type: Boolean,
            default: true,
        },
        companyProgramId: {
            type: Number,
            required: false,
        },
        isDuplicableModel: {
            type: Boolean,
            default: false,
        },
        disabledProgramList: {
            type: Boolean,
            default: false,
        },
        showToggleSurvey: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            /**
             * Objet du formulaire
             */
            item: null,
            tabComponents: [],
        };
    },

    computed: {
        isLoaded() {
            return (this.item && this.modelName) || !this.modelName;
        },
    },

    async created() {
        if (this.modelName) {
            if (this.id) {
                this.item = await this.fetchModel();

                if (this.isDuplicableModel) {
                    this.item.clean('where');
                    this.item.id = undefined;
                    this.item.company_program_id = this.companyProgramId;
                    this.item.status = 'draft';

                    // Hackish way to remove delete button
                    this.item._state.isNew = true;
                }
            } else {
                this.item = await this.initNewModel();

                if ('resource_program' in this.item && this.companyProgramId) {
                    const resourceProgram = this.item.resource_program.new();

                    resourceProgram.company_program_id = this.companyProgramId;
                    this.item.resource_program.add(resourceProgram);
                } else {
                    this.item.company_program_id = this.companyProgramId;
                }
            }

            let title;

            if (this.item instanceof CompanySurvey) {
                title = this.item.id ? this.item.name : this.translate('New survey');
            }
            if (this.item instanceof CompanyRequirementCategory) {
                title = this.item.id ? this.item.name : this.translate('New requirement');
            }
            if (this.item instanceof CompanyRole) {
                title = this.item.id ? this.item.alias_translated : this.translate('New role');
            }
            if (this.item instanceof CompanyProgram) {
                title = this.item.id ? this.item.name_translated : this.translate('New program');
            }
            if (this.item instanceof CompanyEntity) {
                title = this.item.id ? this.item.name_translated : this.translate('New entity');
            }

            stepperFormEventBus.$emit('fetched-item', title);

            this.$emit('on-load', this.item);
        }

        this.tabComponents = this.tabs;
        stepperFormEventBus.$on('toggle-tab', this.toggleTab);
        stepperFormEventBus.$on('show-tab', this.showTab);
        stepperFormEventBus.$on('hide-tab', this.hideTab);
    },

    destroyed() {
        stepperFormEventBus.$off('toggle-tab', this.toggleTab);
        stepperFormEventBus.$off('show-tab', this.showTab);
        stepperFormEventBus.$off('hide-tab', this.hideTab);
    },

    methods: {
        async fetchModel() {
            return this.createModel()
                .where([
                    ['id', '=', this.id],
                ])
                .get();
        },

        async initNewModel() {
            const model = this.createCollection()
                .new();
            if ('resource' in this.itemRelations) {
                const res = new ResourceCollection([
                    'name', 'description', 'translations',
                ]);
                model.resource = res.new();
            }

            return model;
        },

        /**
         * create a new model
         */
        createModel() {
            const modelClasses = {
                CompanyRequirementCategory,
                CompanySurvey,
                CompanyRole,
                CompanyProgram,
                CompanyPlanningEventTemplate,
                CompanyResourceProgramCollection,
                CompanyEntity,
            };
            if (this.modelName in modelClasses) {
                return new modelClasses[this.modelName](this.itemFields).with(this.itemRelations);
            }
            throw 'wrong model name';
        },

        /**
         * create a new collection
         */
        createCollection() {
            const collectionClasses = {
                CompanyRequirementCategoryCollection,
                CompanySurveyCollection,
                CompanyRoleCollection,
                CompanyProgramCollection,
                CompanyPlanningEventTemplateCollection,
                CompanyEntityCollection,
            };
            if (this.collectionName in collectionClasses) {
                const model = new collectionClasses[this.collectionName](this.itemFields).with(this.itemRelations);
                if ('resource' in this.itemRelations) {
                    const res = new ResourceCollection([
                        'name', 'description', 'translations',
                    ]);
                    model.resource = res.new();
                }
                return model;
            }
            throw 'wrong collection name';
        },

        /**
         * @returns {void}
         */
        onChangeTab(resolve) {
            resolve();
        },

        /**
         * @param {String} ref
         * @param {Boolean} setActive
         * @returns {void}
         */
        updateTab(ref, setActive) {
            if (!setActive) {
                return;
            }

            const currentTabIndex = this.tabComponents.findIndex((tab) => tab.ref === ref);

            if (currentTabIndex === -1) {
                return;
            }

            this.tabComponents[currentTabIndex].disabled = false;

            this.$nextTick(() => {
                const nextStep = this.$refs[ref][0];
                nextStep.disabled = this.tabComponents[currentTabIndex].disabled;
                nextStep.setActive();
            });
        },

        /**
         * @param {String} ref
         * @returns {void}
         */
        toggleTab(ref) {
            const tab = this.tabComponents.find((tab) => tab.ref === ref);

            if (!tab) {
                return;
            }

            if (('hidden' in tab) === false) {
                this.$set(tab, 'hidden', true);
                return;
            }

            tab.hidden = !tab.hidden;
        },

        /**
         * @param {String} ref
         * @returns {void}
         */
        showTab(ref) {
            const tab = this.tabComponents.find((tab) => tab.ref === ref);

            if (!tab) {
                return;
            }

            this.$set(tab, 'hidden', false);
        },

        /**
         * @param {String} ref
         * @returns {void}
         */
        hideTab(ref) {
            const tab = this.tabComponents.find((tab) => tab.ref === ref);

            if (!tab) {
                return;
            }

            this.$set(tab, 'hidden', true);
        },

        /**
         * @param {String} ref
         * @returns {void}
         */
        handleGoTo(ref) {
            this.updateTab(ref, true);
        },

        onSave(...args) {
            this.$emit('onSave', ...args);
        },
    },
};
</script>
