<template>
    <div v-if="companyPlanningEvent">
        <div

            class="resource-type-content"
            :class="{open}"
        >
            <button
                type="button"
                class="resource-type-title"
                @click="toggle"
            >
                <HtEmojiIcon
                    size="32"
                    font-size="13"
                    :style="{backgroundColor: '#D9EEFF' }"
                >
                    <HtIcon
                        name="group"
                        stroke-width="2"
                        size="16"
                        :style="{color: '#305E95' }"
                    />
                </HtEmojiIcon>
                {{ translate('Events') }}
                <HtBadge
                    size="large"
                    theme="neutral"
                >
                    {{ getEventSorted.length }}
                </HtBadge>
                <HtIcon
                    size="20"
                    stroke-width="2"
                    name="nav-arrow-right"
                />
            </button>
            <div
                v-if="open"
                class="resource-types"
            >
                <div class="text-center d-flex justify-content-center align-items-center">
                    <div
                        type="button"
                        class="load-week-button previous"
                        @click="decrementWeek"
                    >
                        <svg class="icon icon-arrow-left">
                            <use xlink:href="#icon-arrow-left" />
                        </svg>
                    </div>
                    <span>{{ getCurrentWeekLabel }}</span>
                    <div
                        type="button"
                        class="load-week-button next"
                        @click="incrementWeek"
                    >
                        <svg class="icon icon-arrow-right">
                            <use xlink:href="#icon-arrow-right" />
                        </svg>
                    </div>
                </div>
                <button
                    v-for="item in getEventSorted"
                    :key="item.id"
                    class="item mt-4"
                    @click="openItemModal(item.id)"
                >
                    <div class="item-text">
                        <div class="item-title">
                            {{ item.name }}
                        </div>
                        <div class="item-program">
                            {{ getDateTime(item.task) }}
                        </div>
                    </div>

                    <HtAvatarGroup
                        v-if="item.attendees && item.attendees.length > 1"
                        size="s"
                        :users="$Utils.getAvatarGroupData(item.attendees.map(p => p.company_user))"
                    />

                    <HtBadge
                        size="large"
                        class="item-tag"
                    >
                        <HtIndicator :status="badgeStates[item.task.status]" />
                        <t>
                            {{ item.task.status }}
                        </t>
                    </HtBadge>
                    <HtIcon
                        size="20"
                        stroke-width="2"
                        name="nav-arrow-right"
                    />
                </button>
            </div>
        </div>

        <NewUserPlanningEventItem
            ref="userItem"
            :from="'profile'"
            @onUpdate="onUpdate(...arguments)"
            @onDelete="onDelete(...arguments)"
        />
    </div>
</template>

<script>
import NewUserPlanningEventItem from '@/components/pages/dashboard/modals/planningEvent/NewUserPlanningEventItem.vue';
import CompanyUser from '@/models/CompanyUser';
import api from '@/store/api';
import { debounce } from '@/helpers/_';

export default {
    name: 'NewUserPlanningEventProfile',
    components: {
        NewUserPlanningEventItem,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            open: false,
            badgeStates: {
                done: 'success',
                pending: 'neutral',
                draft: 'warning',
                cancelled: 'error',
            },
            currentId: null,
            companyUser: new CompanyUser(
                ['id', 'firstname', 'arrival_date', 'is_onboardee'],
            ),
            companyPlanningEvent: null,
            weekOffset: 0,
        };
    },

    computed: {
        getEventSorted() {
            return this.companyPlanningEvent ? this.companyPlanningEvent : [];
        },
        currentYearWeek() {
            const current = this.$Utils.moment()
                .add(this.weekOffset, 'weeks')
                .startOf('isoWeek');

            const weekOfYear = current.isoWeek();
            const isoYear = current.isoWeekYear();

            return `${isoYear}${String(weekOfYear).padStart(2, '0')}`;
        },
        getCurrentWeekLabel() {
            const currentWeek = this.$Utils.moment().add(this.weekOffset, 'week');

            const weekNumber = currentWeek.format('WW');
            const weekStart = currentWeek.clone().startOf('isoWeek').format('DD MMM');
            const weekEnd = currentWeek.clone().endOf('isoWeek').format('DD MMM');

            return `${this.translate('Week')} ${weekNumber} (${weekStart} - ${weekEnd})`;
        },
    },

    watch: {
        currentYearWeek: debounce(function () { this.loadPlanningWeekly(); }, 250),
    },

    created() {
        this.companyUser.id = this.companyUserId;
        this.companyUser.get().then(() => {
            this.loadPlanningWeekly();
        });
    },

    methods: {
        toggle() {
            this.open = !this.open;
        },
        onUpdate() {
            this.loadPlanningWeekly();
        },

        onDelete() {
            this.loadPlanningWeekly();
        },

        openItemModal(id) {
            this.$refs.userItem.open(id, this.companyUserId);
        },

        incrementWeek() {
            this.weekOffset += 1;
        },

        decrementWeek() {
            this.weekOffset -= 1;
        },

        loadPlanningWeekly() {
            const data = {
                yearWeek: this.currentYearWeek,
                withoutDelegated: false,
            };

            if (this.companyUser.is_onboardee) {
                data.withoutDelegated = true;
            }

            api.user.planningEvents.getAllByUser(this.companyUserId, data).then((response) => {
                this.companyPlanningEvent = response.data.data;
                this.$emit('on-load', this.companyPlanningEvent.length);
            });

            return this.companyPlanningEvent;
        },

        getDateTime(item) {
            if (!item.datetime_start) {
                return '';
            }
            const language = this.shared.session.companyUser.company_language.key;

            if (!item.time_start) {
                const format = language === 'fr' ? 'Do MMM' : 'MMM Do';
                return this.$Utils.moment(item.datetime_start).format(format);
            }

            const format = language === 'fr' ? 'Do MMM -- HH:mm' : 'MMM Do -- HH:mm';
            return `${this.$Utils.moment(item.datetime_start).format(format)}-${this.$Utils.moment(item.datetime_end).format('HH:mm')} (${this.getEventTimeZone(item)})`;
        },
        getEventTimeZone(item) {
            if (!item.timezone) {
                return item.company_user.timezone;
            }

            return item.timezone;
        },
    },
};
</script>

<style lang="scss" scoped src="../ressourceItem.scss" />
<style lang="scss" scoped>
.load-week-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0 2em;
    top: 0;
    background: transparent;

    &.previous {
        left: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
    }

    &.next {
        right: 0;
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
    }
}
</style>
