<template>
    <div>
        <HtTooltip
            inverted
            position="inline-end"
        >
            <router-link
                :to="item"
                :data-cy="'menu-'+item.name"
                class="mainbar-menu-item-link"
            >
                <HtIcon
                    :name="item.icon"
                    size="18"
                />
                <span class="mainbar-menu-item-label">
                    <t>{{ item.label }}</t>
                </span>
                <div
                    v-show="canSeeActionsPage && isActionMenuItem(item)"
                    class="mainbar-menu-item-actions-counter"
                >
                    <span>{{ actionsInProgressCounter }}</span>
                </div>
            </router-link>
            <template #target>
                {{ item.label }}
            </template>
        </HtTooltip>
    </div>
</template>

<script>

import MenuCustomPermissionsMixin from '@/mixins/MenuCustomPermissionsMixin';

export default {
    name: 'HtMainBarMenuPage',
    mixins: [MenuCustomPermissionsMixin],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    created() {
        if (this.isActionMenuItem(this.item)) {
            this.loadUserActionsPage();
        }
    },
};
</script>

<style lang="scss" scoped>
.mainbar-menu-item-actions-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background-color: var(--branding-color);
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 6px;
    margin-left: auto;
}
</style>
