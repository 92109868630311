<template>
    <div class="user-requirement-edit">
        <component
            :is="component"
            :user-requirement="userRequimentModel"
            :requirement="userRequimentModel"
            :value="{html_content: userRequirement.value}"
            :is-preview="true"
            :show-generate-pdf="false"
            :show-only-file="true"
            :user-id="userId"
            :user-category-status="userCategoryStatus"
        />
    </div>
</template>

<script>
import CompanyUserRequirement from '@/models/CompanyUserRequirement';
import DynamicDocument from '@/components/globals/DynamicDocument.vue';
import NewUserRequirementFileToFillRead from '@/components/pages/onboarding/type/fileToFill/NewUserRequirementFileToFillRead.vue';
import NewUserRequirementFileRead from '@/components/pages/onboarding/type/file/NewUserRequirementFileRead.vue';
import NewUserRequirementTextRead from '@/components/pages/onboarding/type/NewUserRequirementTextRead.vue';
import NewUserRequirementTextCopyRead from '@/components/pages/onboarding/type/NewUserRequirementTextCopyRead.vue';
import NewUserRequirementStringRead from '@/components/pages/onboarding/type/NewUserRequirementStringRead.vue';
import NewUserRequirementDateRead from '@/components/pages/onboarding/type/NewUserRequirementDateRead.vue';
import NewUserRequirementAddressRead from '@/components/pages/onboarding/type/NewUserRequirementAddressRead.vue';
import NewUserRequirementIbanRead from '@/components/pages/onboarding/type/NewUserRequirementIbanRead.vue';
import NewUserRequirementHexapostRead from '@/components/pages/onboarding/type/NewUserRequirementHexapostRead.vue';
import NewUserRequirementMultipleSelectRead from '@/components/pages/onboarding/type/NewUserRequirementMultipleSelectRead.vue';
import NewUserRequirementFileToSignRead from '@/components/pages/onboarding/type/NewUserRequirementFileToSignRead.vue';
import NewUserRequirementSingleSelectRead from '@/components/pages/onboarding/type/NewUserRequirementSingleSelectRead.vue';
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';
import NewUserRequirementPhoneRead from './type/NewUserRequirementPhoneRead.vue';

export default {
    name: 'NewUserRequirementRead',
    components: {
        NewUserRequirementTextRead,
        NewUserRequirementTextCopyRead,
        NewUserRequirementFileToSignRead,
        NewUserRequirementFileToFillRead,
        NewUserRequirementStringRead,
        NewUserRequirementDateRead,
        NewUserRequirementAddressRead,
        NewUserRequirementSingleSelectRead,
        NewUserRequirementMultipleSelectRead,
        NewUserRequirementIbanRead,
        NewUserRequirementFileRead,
        NewUserRequirementHexapostRead,
        NewUserRequirementPhoneRead,
        DynamicDocument,
    },

    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
        userCategoryStatus: {
            type: String,
            default: () => CompanyUserRequirementCategory.STATUS_PENDING_USER,
        },
        userId: {
            type: Number,
            required: true,
        },
    },

    computed: {
        userRequimentModel() {
            if (this.userRequirement?.constructor?.name === 'CompanyUserRequirement') return this.userRequirement;
            const newUserRequirement = { ...this.userRequirement };
            newUserRequirement.value = (new CompanyUserRequirement(['id', 'type', 'value', 'question_multiple_value']))
                .fill(this.userRequirement)
                .modelInConvertor().value(this.userRequirement.value);
            return newUserRequirement;
        },
        component() {
            switch (this.userRequirement.type) {
            case 'phone':
                return NewUserRequirementPhoneRead;
            case 'text':
            case 'text_with_control':
                return NewUserRequirementTextRead;
            case 'social security number':
                return NewUserRequirementTextCopyRead;
            case 'string':
                return NewUserRequirementStringRead;
            case 'file':
                return NewUserRequirementFileRead;
            case 'date':
                return NewUserRequirementDateRead;
            case 'iban':
                return NewUserRequirementIbanRead;
            case 'address':
                return NewUserRequirementAddressRead;
            case 'single_select':
                return NewUserRequirementSingleSelectRead;
            case 'multiple_select':
                return NewUserRequirementMultipleSelectRead;
            case 'file_to_sign':
                return NewUserRequirementFileToSignRead;
            case 'file_to_fill':
                return NewUserRequirementFileToFillRead;
            case 'hexapost':
                return NewUserRequirementHexapostRead;
            case 'dynamic_document':
                return DynamicDocument;
            default:
                return 'div';
            }
        },
    },

};
</script>
