<template>
    <div
        v-if="companyUserId && permissionsLoaded"
        class="profile-team"
    >
        <ProfileTeamList
            ref="teamList"
            :company-user-id="companyUserId"
            :can-edit="canEditTeam"
            @change="roleUser => changeUserRole(roleUser)"
            @remove="roleUser => removeRoleUser(roleUser)"
        />
        <!-- TEAM -->
        <SetSupervisorModal
            v-if="clickedRoleUser"
            ref="modal"
            :role-name="clickedRoleUser.role.alias_translated"
            :role-id="clickedRoleUser.role.id"
            :is-guest-role="false"
            @user-selected="onUserSelected"
            @user-invited="onUserInvited"
        />
    </div>
</template>

<script>
import SetSupervisorModal from '@/components/globals/modals/SetSupervisorModal.vue';
import ProfileTeamList from '@/components/pages/profile/team/ProfileTeamList.vue';

export default {
    name: 'PageTeam',

    permissions: [
        'ModelCompanyUser',
    ],

    components: {
        ProfileTeamList,
        SetSupervisorModal,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            /**
             * @type {CompanyRoleUser}
             */
            clickedRoleUser: null,
        };
    },

    computed: {
        canEditTeam() {
            return this.$can('ModelCompanyUser', { id: this.companyUserId }, 'updateSupervisors')
                && this.$can('AbstractManageFocRoles');
        },
    },

    methods: {
        onUserSelected(user) {
            this.clickedRoleUser.company_user_id = user.id;
            this.clickedRoleUser.user = user;
            this.$refs.modal.close();
            this.$refs.teamList.onValidate().finally(() => {
                this.clickedRoleUser = null;
            });
        },

        onUserInvited(user) {
            this.clickedRoleUser.company_user_id = user.id;
            this.clickedRoleUser.user = user;
            this.$refs.modal.close();
            this.$refs.teamList.onValidate().finally(() => {
                this.clickedRoleUser = null;
            });
        },

        async changeUserRole(roleUser) {
            this.clickedRoleUser = roleUser;
            await this.$nextTick();
            this.$refs.modal.open();
        },

        removeRoleUser(roleUser) {
            const roleUserCopy = roleUser;
            roleUserCopy.user = undefined;
            roleUserCopy.company_user_id = undefined;
            this.$refs.teamList.onValidate();
        },
    },
};
</script>
<style lang="scss" scoped>
.profile-team {
    padding: 16px;
    border-radius: var(--radius-block);
    background-color: var(--fill-secondary);
}

</style>
