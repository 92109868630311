<template>
    <div v-if="companyUserNudgeCollection.isLoaded()">
        <div
            class="resource-type-content"
            :class="{open}"
        >
            <button
                type="button"
                class="resource-type-title"
                @click="toggle"
            >
                <HtEmojiIcon
                    size="32"
                    font-size="13"
                    :style="{backgroundColor: '#F5F4F4' }"
                >
                    <HtIcon
                        name="playstation-gamepad"
                        stroke-width="2"
                        size="16"
                        :style="{color: '#504F4F' }"
                    />
                </HtEmojiIcon>
                {{ translate('Nudges') }}
                <HtBadge
                    size="large"
                    theme="neutral"
                >
                    {{ filteredUserNudges.length }}
                </HtBadge>
                <HtIcon
                    v-if="filteredUserNudges.length"
                    size="20"
                    stroke-width="2"
                    name="nav-arrow-right"
                />
            </button>
            <div
                v-if="open"
                class="resource-types"
            >
                <button
                    v-for="userNudge in filteredUserNudges"
                    :key="userNudge.id"
                    class="item"
                    @click="openItemModal(userNudge.id)"
                >
                    <div class="item-text">
                        <div class="item-title">
                            {{ userNudge.name }}
                        </div>
                        <div class="item-program">
                            {{
                                translate('Program "{programName}"', { programName: userNudge.company_user_program.company_program.name_translated })
                            }}
                        </div>
                    </div>

                    <HtAvatarGroup
                        v-if="userNudge.company_user_participant"
                        size="s"
                        :users="$Utils.getAvatarGroupData([userNudge.company_user_recipient])"
                    />

                    <HtBadge
                        size="large"
                        class="item-tag"
                    >
                        <HtIndicator :status="badgeStates[userNudge.company_user_program_task.status]" />
                        <t>{{ userNudge.company_user_program_task.status }}</t>
                    </HtBadge>
                    <HtIcon
                        size="20"
                        stroke-width="2"
                        name="nav-arrow-right"
                    />
                </button>
            </div>
        </div>

        <modalable
            ref="modalableUserItem"
            class="modalable-1 medium"
            :mode="2"
        >
            <NewUserNudgeItem
                :id="currentId"
                ref="userItem"
                :from="'profile'"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
    </div>
</template>

<script>
import NewUserNudgeItem from '@/components/pages/dashboard/modals/nudge/NewUserNudgeItem.vue';
import CompanyUserNudgeCollection from '@/models/CompanyUserNudgeCollection';

export default {
    name: 'NewUserNudgeProfile',
    components: {
        NewUserNudgeItem,
    },

    props: {
        companyUserId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            open: false,
            badgeStates: {
                pending: 'neutral',
                done: 'success',
                draft: 'orange',
                proposed: 'white',
                accepted: 'white',
                failure: 'error',
                refuse: 'error',
                success: 'success',
            },
            currentId: null,
            companyUserNudgeCollection: new CompanyUserNudgeCollection([
                'id', 'company_user_id', 'status', 'company_user_id', 'datetime_availability', 'time_to_complete',
            ]).with({
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id'])
                        .with({
                            companyProgram: (query) => {
                                query.select(['id'])
                                    .with({
                                        locales: (query) => {
                                            query.select(['id', 'name', 'language_key']);
                                        },
                                    });
                            },
                        });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserInitiator: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
                companyUserRecipient: (query) => {
                    query
                        .select(['id', 'firstname', 'lastname', 'gender', 'image', 'company_language_id'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyNudge: (query) => {
                    query.select(['id']);
                    query.with({
                        resource: (query) => {
                            query.select(['name', 'message', 'language_key']);
                        },
                    });
                },
                companyUserProgramTask: (query) => {
                    query.select(['status']);
                },
            }),
            searchValue: null,
        };
    },

    computed: {
        /**
         * Nudges filtrés par la barre de recherches
         */
        filteredUserNudges: {
            get() {
                return this.companyUserNudgeCollection.$.models;
            },

            set(val) {
                this.companyUserNudgeCollection.$.models = val;
            },
        },

        hasNudge() {
            return this.companyUserNudgeCollection.$.models.length > 0;
        },
    },

    created() {
        this.companyUserNudgeCollection.where([
            ['company_user_id', '=', this.companyUserId],
        ]);

        if (this.shared.session.companyUser.isAdmin() === false) {
            this.companyUserNudgeCollection.whereIn([
                ['status', ['accepted', 'refuse', 'success']],
            ]);
        }
        this.companyUserNudgeCollection.get()
            .then(() => {
                this.$emit('on-load', this.companyUserNudgeCollection.$.models.length);
            });
    },

    methods: {
        toggle() {
            if (this.filteredUserNudges.length) this.open = !this.open;
        },
        onUpdate() {
            this.companyUserNudgeCollection.get();
        },
        onDelete() {
            this.companyUserNudgeCollection.get();
        },
        openItemModal(id) {
            this.currentId = id;
            this.$refs.modalableUserItem.open();
        },
    },
};
</script>
<style lang="scss" scoped src="../ressourceItem.scss" />
