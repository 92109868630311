<template>
    <div v-if="companyUserQuizCollection.isLoaded()">
        <HtAccordion
            :title="translate('Quizzes')"
            :count="filteredUserQuiz.length"
        >
            <HtSearchField
                v-if="hasQuiz"
                :id="'searchQuiz'"
                v-model.trim="searchValue"
                :name="'searchQuiz'"
            />

            <HtTable
                v-if="filteredUserQuiz.length > 0"
                :items="filteredUserQuiz"
                :columns="columns"
                cypress="user-activities"
                :external-search="searchValue"
                @onCellClicked="openItemModal"
            >
                <template #cell(display_status)="scope">
                    <HtTag :color="taskColor[scope.item.company_user_program_task.status]">
                        {{ statusText(scope.item.company_user_program_task.status) }}
                    </HtTag>
                </template>
                <template #cell(participant)="scope">
                    <ParticipantList :users="[{company_user: scope.item.company_user_participant}]" />
                </template>
            </HtTable>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No Quiz added</t>
                </strong>
            </div>
        </HtAccordion>

        <modalable
            ref="modalableUserItem"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserQuizItem
                :id="currentId"
                ref="userItem"
                :from="'profile'"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
    </div>
</template>

<script>
import HtSearchField from '@/components/globals/HtSearchField.vue';
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserQuizItem from '@/components/pages/dashboard/modals/quiz/UserQuizItem.vue';
import CompanyUserQuizCollection from '@/models/CompanyUserQuizCollection';
import HtTag from '@/components/globals/HtTag.vue';
import TaskStatusMixin from '@/mixins/TaskStatusMixin';

export default {
    name: 'UserQuizProfile',
    components: {
        UserQuizItem,
        HtSearchField,
        HtAccordion,
        HtTag,
    },

    mixins: [
        TaskStatusMixin,
    ],

    props: {
        companyUserId: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('status'),
                    key: 'display_status',
                    sortable: false,
                },
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                    search: true,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participant',
                    sortable: false,
                },
                {
                    label: this.translate('Program linked'),
                    key: 'company_user_program.company_program.name_translated',
                    sortable: false,
                },
            ],
            currentId: null,
            companyUserQuizCollection: new CompanyUserQuizCollection([
                'id',
                'company_user_program_id',
                'company_user_id',
                'name',
                'company_user_participant_id',
                'permissions',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select(['status', 'datetime_availability']);
                },
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserParticipant: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
                companyQuiz: (query) => {
                    query.select(['id']).with({
                        resource: (query) => {
                            query.select(['id', 'name', 'translations']);
                        },
                    });
                },
            }).where([
                ['company_user_participant_id', '=', this.companyUserId],
                ['company_user_id', '=', this.companyUserId, 'or'],
            ]),
            searchValue: null,
        };
    },

    computed: {
        filteredUserQuiz: {
            get() {
                return this.companyUserQuizCollection.$.models.filter((item) => {
                    if (this.$UserAcl.isOwner(this.companyUserId)) {
                        return this.$Utils.moment().isSameOrAfter(this.$Utils.moment(item.company_user_program_task.datetime_availability));
                    }

                    return true;
                });
            },

            set(val) {
                this.companyUserQuizCollection.$.models = val;
            },
        },

        hasQuiz() {
            return this.companyUserQuizCollection.$.models.length > 0;
        },
    },

    created() {
        if (this.$canRead('ModelCompanyUserQuiz', { company_user_id: this.companyUserId })) {
            this.companyUserQuizCollection.where([['company_user_id', '=', this.companyUserId]]);
        } else {
            this.companyUserQuizCollection.where([['company_user_participant_id', '=', this.companyUserId]]);
        }

        this.companyUserQuizCollection.get().then(() => {
            this.$emit('on-load', this.filteredUserQuiz.length);
        });
    },

    methods: {
        onUpdate() {
            this.companyUserQuizCollection.get();
        },

        onDelete() {
            this.companyUserQuizCollection.get();
        },

        openItemModal(row) {
            this.currentId = row.item.id;
            this.$refs.modalableUserItem.open();
        },
    },
};
</script>
