<template>
    <div
        v-if="userRequirement.value"
        class="copy-clipboard"
    >
        <span>{{ userRequirement.value }}</span>
        <HtTooltip
            align="center"
            position="block-start"
            class="btn-copy"
        >
            <button
                type="button"
                @click="copyClipboard"
            >
                <HtIcon
                    name="copy"
                    size="16"
                    stroke-width="2"
                />
            </button>
            <template #target>
                <span v-if="!copied"><t>Click to copy</t></span>
                <span v-else><t>Copied!</t></span>
            </template>
        </HtTooltip>
    </div>
    <HtBadge
        v-else-if="shouldShowMissing"
        size="large"
    >
        <HtIndicator status="error" />
        <t>Missing</t>
    </HtBadge>
    <div v-else>
        -
    </div>
</template>

<script>
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';

export default {
    name: 'NewUserRequirementTextCopyRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
        userCategoryStatus: {
            type: String,
            default: () => CompanyUserRequirementCategory.STATUS_PENDING_USER,
        },
    },
    data() {
        return {
            copied: false,
        };
    },
    methods: {
        copyClipboard() {
            if (!this.userRequirement.value) return;
            navigator.clipboard.writeText(this.userRequirement.value);
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, 1000);
        },
        shouldShowMissing() {
            const allowedStatus = [
                CompanyUserRequirementCategory.STATUS_PENDING_USER,
                CompanyUserRequirementCategory.STATUS_DONE,
                CompanyUserRequirementCategory.STATUS_ARCHIVED,
            ];

            return allowedStatus.includes(this.userCategoryStatus);
        },
    },
};
</script>

<style lang="scss" scoped>
.copy-clipboard {
    display: inline-flex;
    align-items: center;
}
.btn-copy {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    .ht-icon {
        position: relative;
        top: -1px;
    }
}
</style>
