<template>
    <div v-if="isLoaded && !isOldUi">
        <router-view />
    </div>
    <PageWithSidebar v-else-if="isLoaded">
        <template #title>
            <HtPageTitle>
                <t>Settings</t>
            </HtPageTitle>
        </template>

        <template #sidebar>
            <HtPageSidebar
                :pages="filteredPages"
                @update-page="setupPage"
            />
        </template>

        <template #default>
            <router-view />
        </template>
    </PageWithSidebar>
</template>

<script>
import HtPageSidebar from '@/components/globals/HtPageSidebar.vue';
import HtPageTitle from '@/components/globals/HtPageTitle.vue';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';
import { hasFeatureEnabled, FEATURE_NEW_UI } from '@/helpers/feature';

export default {
    name: 'Settings',

    components: {
        HtPageTitle,
        HtPageSidebar,
        PageWithSidebar,
    },

    computed: {
        isOldUi() {
            return !hasFeatureEnabled(FEATURE_NEW_UI);
        },
        permissions() {
            return this.pages.map((p) => `Page${p.name}`);
        },

        pages() {
            return [
                {
                    name: 'SettingsUsers',
                    label: this.translate('Users'),
                },
                {
                    name: 'SettingsRolesAndPermissions',
                    label: this.translate('Roles'),
                },
                {
                    name: 'SettingsEntities',
                    label: this.translate('Entities'),
                },
                {
                    name: 'SettingsCompany',
                    label: this.translate('Platform'),
                },
                {
                    name: 'SettingsEnterprisePage',
                    label: this.translate('EnterprisePage'),
                },
                {
                    name: 'SettingsNotifications',
                    label: this.translate('Notifications'),
                },
                {
                    name: 'SettingsPreboarding',
                    label: this.translate('Welcome Chatbot'),
                },
                {
                    name: 'SettingsCommunication',
                    label: this.translate('Communication'),
                },
                {
                    name: 'SettingsLanguages',
                    label: this.translate('Languages'),
                },
                {
                    name: 'SettingsIntegrations',
                    label: this.translate('Integrations'),
                },
                {
                    name: 'SettingsDataAnalysis',
                    label: this.translate('Data analysis'),
                },
                {
                    name: 'SettingsSecurityAndPrivacy',
                    label: this.translate('Security & Privacy'),
                },
                {
                    name: 'SettingsCustomFields',
                    label: this.translate('Custom fields'),
                },
                {
                    name: 'SettingsLogs',
                    label: this.translate('Logs'),
                },
                {
                    name: 'SettingsAi',
                    label: this.translate('AI'),
                },
            ];
        },

        filteredPages() {
            return this.pages.filter((page) => this.$can(`Page${page.name}`));
        },

        isLoaded() {
            return this.$store.getters['permissions/getPermissionsLoaded'](this.permissions);
        },
    },

    watch: {
        $route() {
            this.redirect();
        },
    },

    created() {
        this.setupPage(this.$route.name);

        this.$loadPermissions(this.permissions)
            .then(() => {
                this.redirect();
            });
    },

    methods: {
        redirect() {
            if (this.filteredPages.length > 0 && this.$route.name === 'Settings') {
                const { name } = this.filteredPages[0];

                this.$router.push({ name });

                this.setupPage(name);
            }
        },

        setupPage(name) {
            const page = this.pages.find((p) => p.name === name);

            if (page) {
                this.setPage({
                    title: page.label,
                    subtitles: [this.translate('Settings')],
                });
            }
        },
    },
};
</script>
