<template>
    <div class="user-requirement-category-edit">
        <p
            v-if="requirementCategory.description"
            class="user-requirement-category-edit-text"
        >
            {{ requirementCategory.description }}
        </p>
        <div
            v-for="(group, iGroupIndex) in requirementCategory.groups"
            :key="iGroupIndex"
            class="user-requirement-category-edit-item"
            :class="{'col-md-6': !isModal}"
        >
            <div
                v-if="requirementCategory.groups.length > 1"
                class="mb-10 d-flex justify-content-between align-items-center"
            >
                <div class="user-requirement-category-edit-item-index">
                    {{ getIndexGroup(iGroupIndex) }}.
                </div>
                <div
                    v-if="iGroupIndex > 0 && canDuplicate"
                    class="d-flex align-items-center justify-content-end user-requirement-category-edit-item-delete"
                    data-cy="requirement-group-delete"
                    @click="onGroupDelete(group.id)"
                >
                    <t>remove</t>
                </div>
            </div>

            <div
                v-for="requirement in group.requirements"
                :key="requirement.id"
                class="mb-10"
            >
                <div class="user-requirement-category-edit-item-name">
                    <t v-if="requirement.is_heyteam === true">
                        {{ requirement.name }}
                    </t>
                    <span v-else>{{ requirement.name }}</span>
                    <span v-if="requirement.is_mandatory">
                        *
                    </span>
                    <span>&nbsp;</span>
                    <span
                        v-if="requirement.is_mandatory === 0"
                        class="optional"
                    >
                        <t>Optional</t>
                    </span>
                </div>
                <NewUserRequirementEdit
                    v-if="editable"
                    :requirement="requirement"
                    :participants="participants"
                    :is-participant="isParticipant"
                    :index="requirement.id"
                    :is-disabled="isDisabled"
                    :signer-task="signerTask"
                    :user-id="userId"
                    :can-update="canUpdate"
                    @onChangeValue="onChangeValue(...arguments)"
                    @on-update-requirement="$emit('on-update-requirement', ...arguments)"
                />
                <UserRequirementRead
                    v-else
                    :user-requirement="requirement"
                    :user-category-status="requirementCategory.status"
                    :user-id="userId"
                />
            </div>
        </div>
        <div
            v-if="canDuplicate"
            :class="['my-3 d-flex', {'justify-content-center': isModal}]"
        >
            <Button
                class="negative"
                :state="isDisabled ? 'disabled' : buttonStateDuplicate"
                cypress="requirement-group-add-button"
                @click="onGroupAdd"
            >
                <t>Add an entry</t>
            </Button>
        </div>
    </div>
</template>

<script>
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import UserRequirementRead from '@/components/pages/onboarding/UserRequirementRead.vue';
import api from '@/store/api';
import NewUserRequirementEdit from './NewUserRequirementEdit.vue';

export default {
    name: 'NewUserRequirementCategoryEdit',
    components: {
        UserRequirementRead,
        NewUserRequirementEdit,
    },
    inject: ['$validator'],

    props: {
        requirementCategory: {
            type: Object,
            required: true,
        },
        isModal: {
            type: Boolean,
            default: false,
        },
        isParticipant: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        signerTask: {
            type: CompanyUserProgramTask,
            default: null,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        isRelatedTaskExecutor: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: Number,
            required: true,
        },
        canUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            buttonStateDuplicate: 'idle',
        };
    },

    computed: {
        canDuplicate() {
            return this.requirementCategory.is_duplicable && this.isRelatedTaskExecutor;
        },

        participants() {
            return this.requirementCategory.tasks.map((task) => ({
                ...task.participants[0],
                taskStatus: task.status,
            }));
        },
    },

    methods: {
        onChangeValue(id, hasChanged, isComplete = true) {
            this.$emit('onChangeValue', id, hasChanged, isComplete);
        },

        getIndexGroup(index) {
            return (index < 10) ? `0${index + 1}` : index;
        },

        async onGroupDelete(groupId) {
            await api.user.requirementGroups.delete(this.userId, groupId);
            this.$emit('removeGroup', groupId);
        },

        async onGroupAdd() {
            const data = {
                groupId: this.requirementCategory.groups[0].id,
            };

            this.buttonStateDuplicate = 'loading';

            const response = await api.user.requirementGroups.duplicate(this.userId, data);
            this.$emit('addGroup', response.data.data);
            this.buttonStateDuplicate = 'idle';
        },
    },
};
</script>

<style lang='scss' src='./NewUserRequirementCategoryEdit.scss' scoped></style>
