<template>
    <div class="d-flex flex-column flex-grow-1">
        <div
            v-if="companyUserQuiz.isLoaded()"
            class="d-flex flex-column justify-content-between flex-grow-1"
        >
            <div class="quiz-item">
                <div class="d-flex flex-column quiz-item-content">
                    <div class="container-fluid pl-0 pr-0 quiz-item-content-container">
                        <div class="row align-items-center no-gutters">
                            <div class="col-4 quiz-item-content-label">
                                <t>Participant</t>
                            </div>
                            <div class="col-8">
                                <div class="d-flex align-items-center">
                                    <HtAvatar
                                        :user="$Utils.getAvatarData(companyUserQuiz.company_user_participant)"
                                        size="s"
                                    />
                                    <div class="ml-3">
                                        {{ getParticipantFullname }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center no-gutters">
                            <div class="col-4 quiz-item-content-label">
                                <t>To complete by</t>
                            </div>
                            <div class="col-8">
                                <p>
                                    {{ getFormattedDueDate }}
                                </p>
                            </div>
                        </div>
                        <div class="row align-items-center no-gutters">
                            <div class="col-4 quiz-item-content-label">
                                <t>Status</t>
                            </div>
                            <div class="col-8">
                                <div>{{ statusText(companyUserQuiz.company_user_program_task.status) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-5">
                    <img
                        src="/static/images/modal.svg"
                        class="quiz-item-content-img"
                    >
                </div>
            </div>
            <div
                v-if="!isCancelled"
                class="d-flex justify-content-center mb-4 quiz-item-actions"
            >
                <Button
                    v-if="canDelete"
                    class="negative"
                    :state="buttonState"
                    cypress="quiz-delete-button"
                    @click="onDelete()"
                >
                    <t>Remove</t>
                </Button>
                <Button
                    v-if="canUpdate"
                    :state="buttonState"
                    cypress="quiz-edit-button"
                    @click="openEditModal()"
                >
                    <t>Edit</t>
                </Button>
                <Button
                    v-if="canSeeResult"
                    :state="buttonState"
                    cypress="quiz-see-results-button"
                    @click="openAnswerModal()"
                >
                    <t>See results</t>
                </Button>
                <Button
                    v-if="canAnswer"
                    :state="buttonState"
                    cypress="quiz-open-answer-button"
                    @click="openAnswerModal()"
                >
                    <t>Answer</t>
                </Button>
            </div>
            <Modalable
                ref="userQuizEdit"
                class="modalable-1 small"
                :mode="2"
                :element-delete-label="translate('this quiz')"
            >
                <UserQuizEdit
                    :id="companyUserQuiz.id"
                    :company-user-id="companyUserId"
                    :should-show-availability-date="shouldShowAvailabilityDate"
                    @onUpdate="onUpdate"
                />
            </Modalable>

            <ModalableLarge
                ref="userQuizFill"
                class="modalable-1 small"
                :mode="2"
            >
                <UserQuizFill
                    :id="id"
                    :company-user-id="companyUserId"
                    @onUpdate="onReload"
                />
            </ModalableLarge>
        </div>
        <template v-else>
            <IconLoading />
        </template>
    </div>
</template>

<script>
import ParticipantListHelper from '@/components/mixins/ParticipantListHelper.vue';
import CompanyUserQuiz from '@/models/CompanyUserQuiz';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import TaskStatusMixin from '@/mixins/TaskStatusMixin';
import UserQuizEdit from './UserQuizEdit.vue';
import UserQuizFill from './UserQuizFill.vue';

export default {
    name: 'NewUserQuizItem',

    components: {
        UserQuizFill,
        UserQuizEdit,
    },

    mixins: [
        ParticipantListHelper,
        TaskStatusMixin,
    ],

    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        return {
            buttonState: 'idle',
            companyUserQuiz: new CompanyUserQuiz([
                'id',
                'name',
                'company_user_program_task_id',
                'company_user_participant_id',
                'permissions',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'arrival_date', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['key']);
                            },
                        });
                },
                companyUserParticipant: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'arrival_date', 'image']);
                },
                companyUserProgramTask: (query) => {
                    query.select(['id', 'company_user_id', 'status', 'datetime_end']);
                },
                companyUserQuizQuestionAnswer: (query) => {
                    query.select([
                        'is_correct_answer', 'company_quiz_id', 'company_user_quiz_id',
                        'company_quiz_question_id', 'company_quiz_question_answer_id', 'company_user_id',
                    ]);
                },
                companyQuiz: (query) => {
                    query.select([
                        'id', 'image', 'message_welcome', 'message_congratulary',
                    ]).with({
                        resource: (query) => {
                            query.select(['id', 'name', 'translations']);
                        },
                    });
                },
            })
                .where([['id', '=', this.id]]),
        };
    },

    computed: {
        canRead() {
            return this.$canRead('ModelCompanyUserQuiz', { company_user_id: this.companyUserId });
        },

        canDelete() {
            return this.$canDelete('ModelCompanyUserQuiz', { company_user_id: this.companyUserId });
        },

        canSeeResult() {
            if (this.companyUserQuiz.company_user_program_task.status !== 'done') {
                return false;
            }

            return this.canRead || this.isParticipant;
        },

        canUpdate() {
            return this.$canUpdate('ModelCompanyUserQuiz', { company_user_id: this.companyUserId });
        },

        canAnswer() {
            if (this.from === 'bop' || this.companyUserQuiz.company_user_program_task.status !== 'pending') {
                return false;
            }

            return this.isParticipant;
        },

        isParticipant() {
            return this.$UserAcl.isOwner(this.companyUserQuiz.company_user_participant_id);
        },

        isCancelled() {
            return this.companyUserQuiz.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },

        getFormattedDueDate() {
            const momentDatimeEnd = this.$Utils.moment(this.companyUserQuiz.company_user_program_task.datetime_end);

            return this.$Utils.momentLocalized(momentDatimeEnd, this.shared.session.companyUser.company_language.key).format('LL');
        },

        getParticipantFullname() {
            return `${this.companyUserQuiz.company_user_participant.firstname} ${this.companyUserQuiz.company_user_participant.lastname}`;
        },
    },

    async created() {
        this.companyUserQuiz.company_user_id = this.companyUserId;
        await this.companyUserQuiz.get()
            .then(
                (item) => {
                    this.modal.setTitle(item.name);
                    this.modal.setSubtitle(this.translate('Quiz'));
                },
                () => {
                    this.modal.close();
                },
            );
    },

    methods: {
        onDelete() {
            this.$refs.userQuizEdit.deleteWindow({ content: this.translate('Are you sure you want to delete this quiz? Once you click on delete, you will no longer be able to access this quiz.') }).then((result) => {
                if (result) {
                    this.companyUserQuiz.delete().then(() => {
                        this.modal.close();
                        this.$emit('onDelete');
                    });
                }
            });
        },

        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        openEditModal() {
            this.$refs.userQuizEdit.open();
        },
        openAnswerModal() {
            this.$refs.userQuizFill.open();
        },
        async onReload() {
            this.$emit('onUpdate');
            this.buttonState = 'loading';
            await this.companyUserQuiz.get()
                .then(
                    (item) => {
                        this.buttonState = 'idle';
                        this.modal.setTitle(item.name);
                    },
                    () => {
                        this.buttonState = 'idle';
                        this.modal.close();
                    },
                );
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.quiz-item {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &-content {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
        &-label {
            color: $neutral-600
        }
        &-img {
            width: 418px;
            height: 272px;
        }
    }

    &-actions {
        gap: 12px;
    }
}
</style>
