<template>
    <HtFormSwitch
        v-if="canEnableNewUi"
        id="new-ui"
        :value="isNewUiEnabled"
        :label="translate('Use new version of Heyteam (beta)')"
        :on-label="translate('Enabled')"
        :off-label="translate('Disabled')"
        :disabled="isLoading"
        name="new-ui"
        cypress="new-ui"
        :show-optional="false"
        @input="onToggleNewUi"
    />
</template>
<script>
import api from '@/store/api';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import { FEATURE_NEW_UI, hasFeatureEnabled } from '@/helpers/feature';
import CompanyUser from '@/models/CompanyUser';
import ModalMixin from '@/components/globals/modals/modalMixin';

export default {
    name: 'SwitchNewUi',

    components: {
        HtFormSwitch,
    },

    mixins: [
        ModalMixin,
    ],

    data() {
        return {
            isLoading: false,
        };
    },

    computed: {
        canEnableNewUi() {
            return this.shared.session.companyUser.role === CompanyUser.ROLE_SUPERADMIN;
        },
        isNewUiEnabled() {
            return hasFeatureEnabled(FEATURE_NEW_UI);
        },
    },

    methods: {
        onToggleNewUi() {
            let description = "You're about to activate the new ui. By clicking 'Accept', all users will automatically switch to this new user experience. Don't worry, you can deactivate at any time and all user will be redirected to the old version.";

            if (hasFeatureEnabled(FEATURE_NEW_UI)) {
                description = "You’re about to deactivate the new ui. By clicking ‘Accept’, all users will automatically switch to this old user experience. Don't worry, you can activate at any time and all user will be redirected to the new version.";
            }

            this.genericConfirm(
                this.translate('New user experience (beta)'),
                this.translate(description),
            ).then(() => {
                this.isLoading = true;
                api
                    .settings
                    .newUi
                    .toggleFeature()
                    .then((res) => {
                        this.$store.dispatch('config/registerFeature', res.data.data);
                    }).catch(({ response }) => {
                        this.$Notifier('App').showError(response.data.message);
                    }).finally(() => {
                        this.isLoading = false;
                    });
            });
        },
    },
};
</script>
