<template>
    <div v-if="permissionsLoaded">
        <ListActionsItemHtml
            :title="getTitle"
            :status="status"
            :is-late="isLate"
            :date="date"
            :available-date="availableDate"
            :participants="participants"
            :is-togglable="isToggleable"
            :can-validate="canValidate"
            :show-multi-select="showMultiSelectComputed"
            :is-selectable="isSelectable"
            :is-selected="isSelected"
            :show-icon-resource="true"
            :resource="item.resource"
            :has-dependent="Boolean(item.dependentId)"
            :should-show-availability-date="shouldShowAvailabilityDate"
            :is-clickable="isClickable"
            :tag-label="getTagLabel"
            :validator-type="validatorType"
            :cypress="cypress"
            @onSelectedAction="onSelectedAction"
            @onToggle="onToggle"
            @onClick="onItemClick"
        >
            <template
                v-if="item.tag"
                #middle-content
            >
                <HtTag
                    class="middle-content-tag"
                    color="purple"
                >
                    <t>{{ item.tag }}</t>
                </HtTag>
            </template>
            <template #dependency>
                <div
                    v-for="(task, index) in item.dependenciesTasks"
                    :key="index"
                >
                    <UserProgramTaskItem
                        :key="index"
                        :from="from"
                        :item="task"
                        :cypress="`${cypress}-child`"
                        :selected-action-list="selectedActionList"
                        :show-multi-select="showMultiSelect"
                        :parent-is-done="isDone"
                        @onToggle="onToggle"
                        @onToggleStatus="onToggleStatus"
                    />
                </div>
            </template>
        </ListActionsItemHtml>

        <UserWrapperModal
            ref="userWrapperModal"
            :size="getSize"
            :is-fill="isFillModal"
            :is-edit="isEditModal"
            :can-validate="parentIsDone"
            :cypress="`${cypress}-wrapper-modal`"
            :should-show-availability-date="shouldShowAvailabilityDate"
            @onUpdate="onUpdate"
            @onDelete="onDelete"
        />
    </div>
</template>

<script>
import ListActionsItemHtml from '@/components/pages/dashboard/actions/ListActionsItemHtml.vue';
import UserWrapperModal from '@/components/miscellaneous/UserWrapperModal.vue';
import CypressMixin from '@/mixins/CypressMixin';
import CompanyUserProgramTask from '../../models/CompanyUserProgramTask';

export default {
    name: 'UserProgramTaskItem',
    permissions: [
        'ModelCompanyPlanningEvent',
        'ModelCompanyUserDoc',
        'ModelCompanyUserDocument',
        'ModelCompanyUserEquipment',
        'ModelCompanyUserNudge',
        'ModelCompanyUserProgramTask',
        'ModelCompanyUserQuiz',
        'ModelCompanyUserSoftware',
        'ModelCompanyUserSurvey',
        'AbstractManageUserPrograms',
    ],
    components: {
        UserWrapperModal,
        ListActionsItemHtml,
        UserProgramTaskItem: () => import('@/components/program/UserProgramTaskItem.vue'),
    },
    mixins: [CypressMixin],

    props: {
        from: {
            type: String,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedActionList: {
            type: Array,
            required: true,
        },
        parentIsDone: {
            type: Boolean,
            default: true,
        },
    },

    shared: {
        program: {
            removeIncrementTask: {
                type: Number,
                default: 0,
            },
            updateIncrementTask: {
                type: Number,
                default: 0,
            },
        },
    },
    data() {
        return {
            groupedToggled: false,
        };
    },
    computed: {
        shouldShowAvailabilityDate() {
            return this.parentIsDone || !this.item.dependentId;
        },

        isDone() {
            return this.item.status === 'done';
        },

        validatorType() {
            return this.item.validator_type;
        },

        canReadAssociatedResource() {
            switch (this.item.resource) {
            case CompanyUserProgramTask.TASK_COMPANY_PLANNING_EVENT:
                return this.item.company_planning_event.permissions.can_read;

            case CompanyUserProgramTask.TASK_COMPANY_USER_DOC:
                return this.item.company_user_doc.permissions.can_read;

            case CompanyUserProgramTask.TASK_COMPANY_USER_DOCUMENT:
                return this.item.company_user_document.permissions.can_read;

            case CompanyUserProgramTask.TASK_COMPANY_USER_EMAIL_CUSTOM:
                return this.item.company_user_email_custom.permissions.can_read;

            case CompanyUserProgramTask.TASK_COMPANY_USER_EQUIPMENT:
                return this.item.company_user_equipment.permissions.can_read;

            case CompanyUserProgramTask.TASK_COMPANY_USER_NUDGE:
                return this.item.company_user_nudge.permissions.can_read;

            case CompanyUserProgramTask.TASK_COMPANY_USER_REQUIREMENT_CATEGORY:
                return this.item.company_user_requirement_category.permissions.can_read;

            case CompanyUserProgramTask.TASK_COMPANY_USER_QUIZ:
                return this.item.company_user_quiz.permissions.can_read;

            case CompanyUserProgramTask.TASK_COMPANY_USER_SOFTWARE:
                return this.item.company_user_software.permissions.can_read;

            case CompanyUserProgramTask.TASK_COMPANY_USER_SURVEY:
                return this.item.company_user_survey.permissions.can_read;

            case CompanyUserProgramTask.TASK_CUSTOM:
                return this.item.permissions.can_read;

            default:
                return false;
            }
        },

        isFullTitle() {
            if (this.item.is_grouped) {
                return false;
            }
            const allowedFull = [
                CompanyUserProgramTask.TASK_COMPANY_USER_DOC,
                CompanyUserProgramTask.TASK_COMPANY_USER_REQUIREMENT_CATEGORY,
                CompanyUserProgramTask.TASK_COMPANY_USER_SOFTWARE,
                CompanyUserProgramTask.TASK_COMPANY_USER_EQUIPMENT,
                CompanyUserProgramTask.TASK_COMPANY_USER_QUIZ,
            ];

            return allowedFull.includes(this.item.resource);
        },

        getTitle() {
            return this.isFullTitle ? this.item.full_title : this.item.title;
        },

        isEditModal() {
            switch (this.item.resource) {
            case 'company_requirement_category':
                return this.from === 'cop';
            default:
                return this.from === 'bop';
            }
        },

        isFillModal() {
            if (this.shared.session.companyUser.isAdmin()) {
                return false;
            }

            return this.item.company_user_program_task_participant.models.some((participant) => participant.company_user_id === this.shared.session.companyUser.id)
                && this.item.status === 'pending';
        },

        getSize() {
            let size = null;

            switch (this.item.resource) {
            case 'company_planning':
            case 'company_document':
            case 'company_doc':
            case 'company_software':
            case 'company_equipment':
            case 'company_requirement_category':
            case 'custom':
                size = (this.from === 'bop') ? 'medium' : 'small';
                break;
            case 'company_quiz':
            case 'company_user_quiz':
                if (this.from === 'bop') {
                    size = 'medium';
                } else {
                    size = this.isFillModal === true ? 'large' : 'small';
                }
                break;
            default:
                size = 'medium';
            }

            return size;
        },

        date() {
            const {
                datetime_start,
                datetime_end,
            } = this.item;

            if (!datetime_end) return datetime_start;

            return datetime_end;
        },

        participants() {
            return this.item.company_user_program_task_participant.models;
        },

        canModify() {
            return this.item.permissions.can_validate;
        },

        canValidate() {
            return (this.from !== 'bop') && (this.item.permissions.can_validate || (this.item.permissions.delete === true));
        },

        status() {
            return this.item.status;
        },

        isLate() {
            return this.$Utils.moment(this.date)
                .isBefore(this.$Utils.moment(), 'day')
                && this.item.resource !== 'company_planning';
        },

        isToggleable() {
            if (this.from != 'bop') {
                switch (this.item.resource) {
                // THESE RESOURCES DO NOT HAVE 1-CLICK VALIDATION
                case 'company_requirement_category':
                case 'company_email':
                case 'company_email_custom':
                case 'company_quiz':
                case 'company_user_quiz':
                case 'company_survey':
                case 'company_planning':
                    return this.item.company_planning_event && this.item.company_planning_event.is_delegated === 0;
                case 'company_document':
                    return this.$UserAcl.isOwner(this.item.company_user_id) || this.canModify;
                case 'company_software':
                    return this.item.company_user_software
                        && this.item.validator_type === 'human'
                        && (this.canModify || this.isTaskParticipant);
                case 'custom':
                    return this.participants.length === 0 || this.isTaskParticipant || this.canModify;
                default:
                    return this.isTaskParticipant || this.canModify;
                }
            }
            return false;
        },

        isTaskParticipant() {
            return this.item.company_user_program_task_participant.models.some(
                (participant) => participant.company_user_id == this.shared.session.companyUser.id,
            );
        },

        availableDate() {
            const { moment } = this.$Utils;

            const now = moment()
                .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                })
                .format('X');
            const datetime_available = moment(this.item.datetime_availability)
                .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                })
                .format('X');

            if (now >= datetime_available) {
                return '';
            }
            const format = (this.shared.session.companyUser.language == 'fr') ? 'DD/MM/YY' : 'MM/DD/YY';
            return this.$Utils.moment(this.item.datetime_availability)
                .format(format);
        },

        isSelectable() {
            if (this.item.dependents?.models?.length
                && this.item.dependents?.models[0].status === CompanyUserProgramTask.STATUS_DONE
            ) {
                return false;
            }

            const { resource } = this.item;

            if (this.item.validator_type === 'system') {
                return false;
            }

            return resource !== 'company_survey'
                && resource !== 'company_requirement_category'
                && resource !== 'company_user_quiz'
                && resource !== 'company_email_custom';
        },

        isSelected() {
            return this.selectedActionList.includes(this.item.id);
        },

        isRequirementViaSurveyPending() {
            const { resource } = this.item;
            const requirement = this.item.company_user_requirement_category;

            return (resource === 'company_requirement_category' && requirement.display_survey === true && this.item.status !== CompanyUserProgramTask.STATUS_DONE);
        },
        isClickable() {
            if (this.item.dependents?.models?.length) {
                return this.item.dependents.models[0].status === CompanyUserProgramTask.STATUS_DONE || this.$canRead('AbstractManageUserPrograms');
            }
            if (this.isRequirementViaSurveyPending) {
                return false;
            }

            return true;
        },
        getTagLabel() {
            if (this.isRequirementViaSurveyPending) {
                return this.translate('Via survey');
            }

            return null;
        },
        showMultiSelectComputed() {
            return this.showMultiSelect && this.status != 'done';
        },
    },

    methods: {
        onDelete() {
            this.shared.program.removeIncrementTask++;
        },

        onUpdate() {
            this.shared.program.updateIncrementTask++;
        },

        onItemClick() {
            if (this.showMultiSelect || !this.canReadAssociatedResource) {
                return;
            }

            this.$refs.userWrapperModal.open(this.item);
        },

        onSelectedAction() {
            if (this.selectedActionList.includes(this.item.id)) {
                this.selectedActionList.splice(this.selectedActionList.indexOf(this.item.id), 1);
            } else {
                this.selectedActionList.push(this.item.id);
            }
        },

        onToggle(status) {
            this.item.status = (status
                ? CompanyUserProgramTask.STATUS_DONE
                : CompanyUserProgramTask.STATUS_PENDING
            );

            this.$emit('onToggleStatus', this.item);
        },

        onToggleStatus(item) {
            this.$emit('onToggleStatus', item);
        },
    },
};
</script>
