<template>
    <div class="page-content">
        <h1 class="small">
            {{ getTranslatedMessage(companyFlowCopy['bloc_requirement_title']['content']['messages']['message_1']) }}
        </h1>

        <FlowRow
            v-if="messages['bloc_requirement_a'] === true"
            :company-user="bubbleUser"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_requirement_a']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_requirement_b'] === true && isFullyLoaded"
            :company-user="companyUser"
            :show-buttons="showUserInfo"
            :timeout="1500"
            :reversed="true"
            :full="true"
            :can-skip="false"
            @onValidate="onValideProfile"
            @onLoadRow="$emit('onLoadRow')"
            @onCancel="increaseStep(1)"
        >
            <template slot="content">
                <div class="wrapper-form-line">
                    <HtFormInput
                        :id="'firstname'"
                        v-model="companyUser.firstname"
                        v-validate.disable="'required'"
                        :label="translate('First name')"
                        :type="'text'"
                        :name="'firstname'"
                        class="form-line-content"
                        :data-vv-as="translate('first name')"
                    />

                    <HtFormInput
                        :id="'lastname'"
                        v-model="companyUser.lastname"
                        v-validate.disable="'required'"
                        :label="translate('Last name')"
                        :type="'text'"
                        :name="'lastname'"
                        class="form-line-content"
                        :data-vv-as="translate('last name')"
                    />
                </div>

                <div class="wrapper-form-line form-2">
                    <HtFormMaskedInput
                        :id="'id'"
                        v-model="getDateOfBirth"
                        v-validate.disable="'date_format:'+dateFormat"
                        :label="translate('Date of birth')"
                        :placeholder="dateFormat"
                        type="text"
                        :name="'dob'"
                        class="form-line-content"
                        :mask="[ /\d/, /\d/ , '/', /\d/, /\d/ , '/', /\d/, /\d/, /\d/, /\d/ ]"
                        :data-vv-as="translate('date of birth')"
                    />

                    <HtFormSelect
                        :id="'gender'"
                        v-model="companyUser.gender"
                        :name="'gender'"
                        class="form-line-content"
                        :label="translate('Gender')"
                        :options="genderOptions"
                    />
                </div>

                <div class="wrapper-form-line form-2">
                    <HtFormSelect
                        v-if="shared.queries.htNationalityCollection.isLoaded()"
                        :id="'nationality'"
                        v-model="companyUser.ht_nationality_id"
                        :label="translate('Nationality')"
                        :name="'nationality'"
                        class="form-line-content"
                        :options="getOptionsNationality"
                    />

                    <HtFormSelect
                        :id="'job_position'"
                        v-model="companyUser.company_job_position_id"
                        v-validate.disabled="'required'"
                        :label="translate('Job Position')"
                        :name="'job_position'"
                        :disabled="isJobPositionDisabled"
                        :options="getOptionsJobPosition"
                        class="form-line-content"
                        :data-vv-as="translate('job position')"
                    />
                </div>
                <div class="wrapper-form-line form-2">
                    <HtFormSelect
                        :id="'office'"
                        v-model="companyUser.company_office_id"
                        v-validate.disabled="'required'"
                        :disabled="hasUserProgram"
                        :label="translate('Site')"
                        :name="'office'"
                        :options="getOptionsOffice"
                        class="form-line-content"
                        :data-vv-as="translate('site')"
                    />

                    <HtFormSelect
                        :id="'department'"
                        v-model="companyUser.company_department_id"
                        v-validate.disabled="'required'"
                        :disabled="hasUserProgram"
                        :label="translate('Department')"
                        :name="'department'"
                        :options="getOptionsDepartment"
                        class="form-line-content"
                        :data-vv-as="translate('department')"
                    />
                </div>
                <div class="wrapper-form-line form-2">
                    <timezone-selector
                        id="timezone"
                        v-validate.disabled="'required'"
                        :field-label="translate('Timezone')"
                        :data-vv-as="translate('Timezone')"
                        :value.sync="companyUser.timezone"
                        :default-value="companyUser.timezone"
                        name="timezone"
                        class="ht-form form-line-content"
                    />

                    <HtFormInput
                        v-if="hasUserProgram"
                        :id="'start_date'"
                        v-validate.disable="'required'"
                        :name="'start_date'"
                        :value="getArrivalDate"
                        :label="translate('Start Date')"
                        class="form-line-content"
                        :disabled="true"
                    />
                    <div
                        v-else
                        class="form-line-content"
                    />
                </div>
            </template>
        </FlowRow>

        <template v-if="hasUserProgram && categories.length">
            <FlowRow
                v-if="messages['bloc_requirement_c'] === true"
                :company-user="bubbleUser"
                @onLoadRow="$emit('onLoadRow')"
            >
                <template slot="content">
                    {{
                        getTranslatedMessage(companyFlowCopy['bloc_requirement_c']['content']['messages']['message_1'])
                    }}
                </template>
            </FlowRow>
            <div v-if="messages['bloc_requirement_d'] === true">
                <FlowRow
                    v-for="(category, index) in shownCompanyUserRequirementCategories"
                    :key="category.id"
                    :company-user="companyUser"
                    :show-buttons="showUserRequirements"
                    :is-completed="category.status === 'done'"
                    :is-skipped="category.status !== 'done' && isSkipped(index)"
                    :reversed="true"
                    :full="true"
                    :timeout="1500"
                    :is-not-concerned="category.status === 'not_concerned'"
                    :can-skip="isMandatoryRequirement(category)"
                    :can-reject="!category.is_mandatory"
                    :can-validate="companyFlowCopy['bloc_requirement_f']['is_enabled'] ? isMandatoryRequirement(category, true) : categoryUpdated[category.id]"
                    @onValidate="onValideRequirements(category)"
                    @onLoadRow="$emit('onLoadRow')"
                    @onCancel="onCancel(index)"
                    @onNotConcerned="onNotConcerned(category)"
                >
                    <template slot="content">
                        <div class="form-2">
                            <UserRequirementCategoryEdit
                                :requirement-category="category"
                                :is-participant="true"
                                :signer-task="getPendingTask(category)"
                                :editable="true"
                                :is-disabled="category.status === 'done' || (category.status !== 'done' && isSkipped(index))"
                                :user-id="$Auth.getLoggedUser().id"
                                is-related-task-executor
                                @onChangeValue="onChangeValue"
                                @on-update-requirement="onUpdateRequirement(...arguments)"
                                @on-reload="loadCategories"
                                @addGroup="(group) => onAddGroup(category.id, group)"
                                @removeGroup="(groupId) => onRemoveGroup(category.id, groupId)"
                            />
                        </div>
                    </template>
                </FlowRow>
            </div>
        </template>
        <FlowRow
            v-if="messages['bloc_requirement_e'] === true"
            :company-user="bubbleUser"
            @onLoad="$emit('onLoad', true)"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_requirement_e']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>
    </div>
</template>

<script>
import CompanyJobPositionCollection from '@/models/CompanyJobPositionCollection';
import CompanyDepartmentCollection from '@/models/CompanyDepartmentCollection';
import CompanyOfficeCollection from '@/models/CompanyOfficeCollection';
import HtNationalityCollection from '@/models/HtNationalityCollection';

import FlowRow from '@/components/flow/FlowRow.vue';
import FlowMixin from '@/components/mixins/FlowMixin.vue';

import CompanyUser from '@/models/CompanyUser';
import TimezoneSelector from '@/components/globals/timezone/TimezoneSelector.vue';
import get from 'lodash.get';
import api from '@/store/api';
import UserRequirementCategoryEdit from '../pages/onboarding/UserRequirementCategoryEdit.vue';

export default {
    name: 'FlowRequirement',
    components: {
        UserRequirementCategoryEdit,
        FlowRow,
        TimezoneSelector,
    },
    mixins: [FlowMixin],

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
        data: {
            htNationalityCollection: {
                type: HtNationalityCollection,
                default: null,
            },
        },
        queries: {
            htNationalityCollection: {
                type: HtNationalityCollection,
                default: null,
            },
        },
    },

    data() {
        return {
            genderOptions: [
                {
                    id: null,
                    name: '',
                },
                {
                    id: 'male',
                    name: this.translate('Male'),
                },
                {
                    id: 'female',
                    name: this.translate('Female'),
                },
                {
                    id: 'neutral',
                    name: this.translate('Neutral'),
                },
            ],
            button_state: [],
            key: 0,
            skippedIndex: [],

            step: 0,

            rowLabel: [],
            rowLabelInteractive: ['bloc_requirement_b', 'bloc_requirement_d'],
            iMessageIndex: 0,
            globalTimeout: null,

            companyFlowCopy: {},
            messages: {},
            messagesEnabled: [],

            showUserInfo: true,
            showUserRequirements: true,

            userRequirements: [],
            requirementMandatory: {},
            requirementUpdated: {},
            requirementByCategory: {},
            requirementAndCategory: {},
            categoryUpdated: {},
            categories: [],
            shownCompanyUserRequirementCategoriesCount: 1,
            alreadyValidatedCategoriesId: [],

            companyOffices: new CompanyOfficeCollection(
                ['id', 'name', 'is_heyteam'],
            ),
            companyDepartments: new CompanyDepartmentCollection(
                ['id', 'name', 'is_heyteam'],
            ),
            companyJobPositions: new CompanyJobPositionCollection(
                ['id', 'name', 'is_heyteam'],
            ),
        };
    },

    computed: {
        getArrivalDate: {
            get() {
                return this.$Utils.moment(this.companyUser.arrival_date)
                    .format('L');
            },
        },

        getDateOfBirth: {
            get() {
                return this.$Utils.mysqlToDate(this.companyUser.date_of_birth, this.dateFormat);
            },
            set(value) {
                this.companyUser.date_of_birth = this.$Utils.dateToMysql(value, false, this.dateFormat);
            },
        },

        companyLanguage() {
            return this.shared.session.companyUser.company_language;
        },

        isFullyLoaded() {
            return this.companyDepartments.isLoaded()
                && this.companyOffices.isLoaded()
                && this.companyJobPositions.isLoaded();
        },

        hasUserProgram() {
            return this.companyUser.company_user_program.models.length > 0;
        },

        isJobPositionDisabled() {
            return this.hasUserProgram || this.companyUser.company_job_position_id !== null;
        },

        dateFormat() {
            return (this.companyLanguage.key == 'fr') ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        },

        getOptionsNationality() {
            if (this.shared.queries.htNationalityCollection.isLoaded()) {
                return this.shared.data.htNationalityCollection.models.map((nationality) => ({
                    id: nationality.id,
                    name: nationality[`name_${this.companyLanguage.key === 'fr' ? 'fr' : 'en'}`],
                }))
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
            return [];
        },

        getOptionsOffice() {
            if (this.companyOffices.isLoaded()) {
                return this.companyOffices.$.models.map((office) => ({
                    name: office.name_translated,
                    id: office.id,
                }))
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
            return [];
        },

        getOptionsDepartment() {
            if (this.companyDepartments.isLoaded()) {
                return this.companyDepartments.$.models.map((department) => ({
                    name: department.name_translated,
                    id: department.id,
                }))
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
            return [];
        },

        getOptionsJobPosition() {
            if (this.companyJobPositions.isLoaded()) {
                return this.companyJobPositions.$.models.map((jobPosition) => ({
                    name: jobPosition.name_translated,
                    id: jobPosition.id,
                }))
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
            return [];
        },

        shownCompanyUserRequirementCategories() {
            return this.categories.slice(0, this.shownCompanyUserRequirementCategoriesCount);
        },
    },

    async created() {
        if (this.shared.queries.htNationalityCollection.isLoaded() === false) {
            this.shared.queries.htNationalityCollection.get()
                .then((data) => {
                    this.shared.data.htNationalityCollection = data;
                });
        }

        this.contents.map((model) => {
            this.companyFlowCopy[model.subtype] = model;

            if (!model.subtype.includes('_title')) {
                this.$set(this.messages, model.subtype, false);
                if (model.is_enabled) {
                    this.messagesEnabled.push(model.subtype);
                }
            }
        });

        if (!this.messagesEnabled.length) {
            if (this.is_forward === true) {
                this.$emit('onNextPage');
            } else {
                this.$emit('onPreviousPage');
            }
        }

        await this.loadCategories();

        Promise.all([
            this.companyOffices.get(),
            this.companyDepartments.get(),
            this.companyJobPositions.get(),
        ])
            .then(() => {
                this.rowLabel = Object.keys(this.messages);

                this.loadRows();
                this.runLoadRows();
            });
    },

    methods: {
        getPendingTask(category) {
            return category.tasks.find((task) => task.status === 'pending'
                && task.participants.some((participant) => participant.company_user_id === this.$Auth.getLoggedUser().id));
        },
        isMandatoryRequirement(category, checkUpdate) {
            // bloc_requirement_f = parameter to force the user to fill in the mandatory administrative requirements
            if (!this.companyFlowCopy.bloc_requirement_f.is_enabled) {
                return true; // we do not force the filling of administrative requirements
            }

            for (const requirementId of this.requirementByCategory[category.id]) {
                if (this.requirementMandatory[requirementId]) {
                    if (!checkUpdate) {
                        return false;
                    }
                    if (checkUpdate && !this.requirementUpdated[requirementId]) {
                        return false;
                    }
                }
            }

            return true;
        },

        runLoadRows() {
            this.globalTimeout = setInterval(() => {
                this.loadRows();
            }, 1500);
        },

        async loadCategories() {
            const data = await api.user.requirementCategories.getAll(this.$Auth.getLoggedUser().id);
            this.categories = data.data.data;
            this.initRequirementUpdated();
        },

        initRequirementUpdated() {
            let requirements = [];

            this.categories.forEach((category) => {
                this.$set(this.categoryUpdated, category.id, get(this.categoryUpdated, category.id, false));

                category.groups.forEach((group) => {
                    group.requirements.forEach((requirement) => {
                        this.$set(this.requirementMandatory, requirement.id, requirement.is_mandatory);
                        this.$set(this.requirementUpdated, requirement.id, requirement.status === 'done');
                        this.$set(this.requirementAndCategory, requirement.id, category.id);
                        requirements.push(requirement.id);
                    });
                });

                this.requirementByCategory[category.id] = requirements;
                requirements = [];
            });
        },

        onUpdateRequirement(requirement) {
            this.requirementByCategory[requirement.company_user_requirement_category_id].push(requirement.id);
            this.$set(this.requirementAndCategory, requirement.id, requirement.company_user_requirement_category_id);
            this.$set(this.requirementUpdated, requirement.id, get(this.requirementUpdated, requirement.id, false));
            this.$set(this.requirementMandatory, requirement.id, requirement.is_mandatory);
        },

        onChangeValue(id, hasChanged) {
            this.requirementUpdated[id] = hasChanged;

            const categoryId = this.requirementAndCategory[id];

            const requirementFilled = this.requirementByCategory[categoryId].some((requirementId) => this.requirementUpdated[requirementId] === true);

            this.categoryUpdated[this.requirementAndCategory[id]] = requirementFilled;
        },

        /**
         * @param {number} categoryId
         * @param {Object} group
         */
        onAddGroup(categoryId, group) {
            this.categories = this.categories.map((category) => {
                if (category.id === categoryId) {
                    return {
                        ...category,
                        groups: [...category.groups, group],
                    };
                }

                return category;
            });
        },

        /**
         * @param {number} categoryId
         * @param {number} groupId
         */
        onRemoveGroup(categoryId, groupId) {
            this.categories = this.categories.map((category) => {
                if (category.id === categoryId) {
                    category.groups = category.groups.filter((group) => group.id !== groupId);
                }

                return category;
            });
        },

        onNotConcerned(category) {
            api.user.requirementCategories.setNotConcerned(
                this.$Auth.getLoggedUser().id,
                category.id,
            )
                .then(({ data }) => {
                    const categoryUpdated = data.data;

                    this.categories = this.categories.map((item) => {
                        if (item.id === categoryUpdated.id) {
                            return categoryUpdated;
                        }

                        return item;
                    });
                });
            this.increaseStep(3);
        },

        isSkipped(index) {
            return this.skippedIndex.includes(index);
        },

        loadRows() {
            while (this.iMessageIndex < this.rowLabel.length && this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled === false) {
                this.iMessageIndex++;
            }

            if (this.messagesEnabled.indexOf(this.rowLabel[this.iMessageIndex]) === -1) {
                this.$emit('onLoadPage', true);
                clearInterval(this.globalTimeout);
            } else {
                if (this.rowLabel[this.iMessageIndex] === 'bloc_requirement_c') {
                    if (this.hasUserProgram && this.categories.length === 0) {
                        this.iMessageIndex += 2;
                    }
                }

                if (this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled) {
                    this.messages[this.rowLabel[this.iMessageIndex]] = true;
                }

                if (!this.rowLabelInteractive.includes(this.rowLabel[this.iMessageIndex])) {
                    this.iMessageIndex++;
                }
            }
        },

        async increaseStep(index) {
            if (index !== this.iMessageIndex) {
                return;
            }

            if (index === 1 && this.showUserInfo) {
                await this.$validator.validateAll();

                if (this.errors.any()) return;
                this.showUserInfo = false;

                this.iMessageIndex++;
            } else if (index === 3 && this.showUserRequirements) {
                if (this.shownCompanyUserRequirementCategoriesCount === this.categories.length) {
                    this.iMessageIndex++;
                } else {
                    this.shownCompanyUserRequirementCategoriesCount++;
                }
            } else {
                this.iMessageIndex++;
            }

            clearInterval(this.globalTimeout);
            this.loadRows();
            this.runLoadRows();
        },

        async onValideProfile() {
            await this.$validator.validateAll();

            if (this.companyUser.isSaving() || this.errors.any()) {
                return;
            }

            await this.onUpdateProfile();
            await this.increaseStep(1);
        },

        async onValideRequirements(category) {
            await this.$validator.validateAll();

            if (this.errors.any() || this.alreadyValidatedCategoriesId.indexOf(category.id) >= 0) {
                return;
            }

            this.alreadyValidatedCategoriesId.push(category.id);

            await this.onUpdateRequirements(category);
            await this.increaseStep(3);
        },

        async onCancel(index) {
            this.skippedIndex.push(index);
            await this.increaseStep(3);
        },

        onUpdateProfile() {
            return new Promise((resolve) => {
                this.companyUser.save()
                    .then(() => {
                        this.shared.session.companyUser.fill(this.companyUser);
                        resolve();
                    });
            });
        },

        onUpdateRequirements(category) {
            return new Promise((resolve) => {
                const pendingTask = this.getPendingTask(category);

                const params = {
                    resource: category,
                    taskId: pendingTask.id,
                };

                api.user.requirementCategories.update(
                    this.$Auth.getLoggedUser().id,
                    category.id,
                    params,
                )
                    .then(() => {
                        resolve();
                    });
            });
        },

        // appelé dans le parent
        async onValidate() {
            await this.$validator.validateAll();

            if (!this.isFullyLoaded
                || this.companyUser.isSaving()) {
                this.$emit('onFail');
                return;
            }

            let hasRequirementNotBeenFilled = false;
            for (const arrayRequirement of Object.entries(this.requirementByCategory)) {
                if (hasRequirementNotBeenFilled) {
                    break;
                }

                for (const requirementId of arrayRequirement[1]) {
                    if (this.requirementMandatory[requirementId] && !this.requirementUpdated[requirementId]) {
                        hasRequirementNotBeenFilled = true;
                        break;
                    }
                }
            }

            if (hasRequirementNotBeenFilled) {
                this.$Notifier('App')
                    .showWarning(
                        this.translate('There are some mandatory requirements that you have not filled, you can still complete your registration, but you will have to enter the missing information on your account'),
                        7500,
                    );
            }

            this.$emit('onNextPage');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.no-margin {
    margin: 0;
}

.flex-1 {
    flex: 1 1 0%;
}

.background-grey {
    background: $bg-grey;
}

.background-white {
    background: white !important;
}

.input-spe {
    margin-top: 5px;
    font-family: "lato", sans-serif;
    font-size: 1.6rem;
    padding: 14px 18px;
    border: 1px solid $grey-ultra-light;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    background: $white;
    cursor: pointer;

    body:not(.old-ui) & {
        font-family: var(--poppins);
    }
}

.square {
    cursor: pointer;
    border: 1px solid rgb(231, 232, 232);
    width: 15px;
    height: 15px;
    display: -webkit-inline-box;
    border: 1px solid $grey-ultra-light;
    border-radius: 4px;
}

.big-square {
    padding: 5px;
    border: 1px solid rgb(231, 232, 232);
    width: 27px;
    height: 27px;
    display: -webkit-inline-box;
    border: 1px solid $grey-ultra-light;
    margin-right: 10px;
    border-radius: 4px;

}

.green {
    background-color: $valid;
}

.wrapper-file-to-fill {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0;
    border: 1px solid $grey-ultra-light;
    background-color: $grey-lighter;
    border-radius: 6px;

    &.background-success {
        background: $green-light;
        transition: background $transition, opacity $transition 0.3s;
    }

    .arrow-center {
        align-self: center;
    }

    .file {
        width: 43%;
        display: flex;
        flex-direction: column;
        padding: 20px;

        @media screen and (max-width: $desktop-large) {
            width: 100%;
        }

        .margin-bottom {
            margin-bottom: 20px;
        }

        .progress-upload {
            position: absolute;
            left: 0;
            bottom: 0px;
            height: 3px;
            background: $primary;
            transition: $transition;

            &.success {
                background: $valid;
                //opacity: 0;
                transition: background $transition, opacity $transition 0.3s;
            }
        }
    }
}

@media (max-width: $desktop-large) {
    .wrapper-file-to-fill {
        flex-direction: column;
    }

    .arrow-center {
        display: none;
    }
}
</style>
