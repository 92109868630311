<template>
    <NewProfileBloc
        v-if="companyUserPersonal.isLoaded()"
        :is-edit.sync="modeEdit"
        :can-edit="canModifyPersonalInformation"
        :button-state="buttonState"
        :title="translate('Personal Information')"
        @on-save="onUpdate"
    >
        <div class="profile-rows">
            <div class="grid-2-col">
                <!-- PERSONAL EMAIL -->
                <NewProfileItem>
                    <template #title>
                        <div class="d-flex flex-direction-column align-items-center">
                            <t>Personal E-mail</t>
                            <HtIcon
                                v-if="companyUserPersonal.main_email === 'personal_email'"
                                v-tooltip.bottom="translate('This is the login connexion')"
                                name="help-circle"
                                size="15"
                                class="cursor-help ml-3 cursor-help"
                                stroke-width="2"
                            />
                        </div>
                    </template>
                    <template #default>
                        <HtFormInput
                            v-if="modeEdit"
                            :id="'personal_email'"
                            v-model.trim="companyUserPersonal.personal_email"
                            v-validate.disable="'required|email'"
                            :name="'personal_email'"
                            :data-vv-as="translate('personal email')"
                        />
                        <div
                            v-else
                        >
                            {{ companyUserPersonal.$.personal_email || '-' }}
                        </div>
                    </template>
                </NewProfileItem>

                <!-- BIRTHDAY -->
                <NewProfileItem
                    :title="translate('Birthday')"
                >
                    <HtFormFlatPickr
                        v-if="modeEdit"
                        :id="'dob'"
                        v-model.trim="companyUserPersonal.date_of_birth"
                        :name="'dob'"
                        :is-working-day="false"
                    />
                    <div v-else>
                        {{ companyUserPersonal.$.date_of_birth ? $Utils.moment(companyUserPersonal.$.date_of_birth).format('L') : '-' }}
                    </div>
                </NewProfileItem>
            </div>
            <div class="profile-row grid-2-col">
                <!-- PROFESSIONAL EMAIL -->
                <NewProfileItem>
                    <template #title>
                        <div class="d-flex flex-direction-column">
                            <t>Professional E-mail</t>
                            <HtIcon
                                v-if="companyUserPersonal.main_email === 'professional_email'"
                                v-tooltip.bottom="translate('This is the login connexion')"
                                name="help-circle"
                                size="15"
                                class="cursor-help ml-3 cursor-help"
                                stroke-width="2"
                            />
                        </div>
                    </template>
                    <template #default>
                        <div
                            v-if="!modeEdit"
                            class="profile-item-content"
                        >
                            {{ companyUserPersonal.$.professional_email || '-' }}
                        </div>
                        <HtFormInput
                            v-else
                            :id="'email_professional'"
                            v-model.trim="companyUserPersonal.professional_email"
                            v-validate.disable="'required'"
                            :name="'email_professional'"
                            :data-vv-as="translate('professional email')"
                        />
                    </template>
                </NewProfileItem>

                <!-- NATIONALITY -->
                <NewProfileItem
                    :title="translate('Nationality')"
                >
                    <HtFormSelect
                        v-if="modeEdit && getNationalityOption.length > 0"
                        :id="'nationality'"
                        v-model="companyUserPersonal.ht_nationality_id"
                        :name="'nationality'"
                        :options="getNationalityOption"
                    />
                    <div
                        v-show="!modeEdit"
                        class="profile-item-content"
                    >
                        {{ nationality || '-' }}
                    </div>
                </NewProfileItem>
            </div>
            <div class="profile-row grid-2-col">
                <!-- GENDER -->
                <NewProfileItem
                    :title="translate('Gender')"
                >
                    <GenderSelection
                        v-if="modeEdit"
                        :company-user="companyUserPersonal"
                        class="form-2"
                    />
                    <div
                        v-else
                        class="profile-item-content"
                    >
                        {{ translate($Utils.capitalize(companyUserPersonal.$.gender)) || '-' }}
                    </div>
                </NewProfileItem>

                <!-- PHONE NUMBER -->
                <NewProfileItem
                    :title="translate('Phone number')"
                >
                    <div
                        v-show="!modeEdit"
                        class="profile-item-content"
                    >
                        <span v-if="companyUserPersonal.$.phone_number">{{ companyUserPersonal.$.phone_country_code }}{{ companyUserPersonal.$.phone_number }}</span>
                        <span v-else>-</span>
                    </div>
                    <PhoneNumberInput
                        v-if="modeEdit"
                        v-model="phone"
                    />
                </NewProfileItem>
            </div>
            <!-- Custom entities -->
            <ProfilCustomEntities
                :company-user="companyUserPersonal"
                :entity-ids="entityIds"
                :is-edit="modeEdit"
            />
        </div>
    </NewProfileBloc>
</template>
<script>
import HtNationalityCollection from '@/models/HtNationalityCollection';
import CompanyUser from '@/models/CompanyUser';
import GenderSelection from '@/components/globals/GenderSelection.vue';
import profileEventBus from '@/eventBus/profileEventBus';
import PhoneNumberInput from '@/components/globals/inputs/PhoneNumberInput.vue';
import NewProfileBloc from '../NewProfileBloc.vue';
import NewProfileItem from '../profile/NewProfileItem.vue';
import ProfilCustomEntities from '../profile/ProfilCustomEntities/ProfilCustomEntities.vue';

export default {
    name: 'NewProfileAdministrativePersonnal',

    components: {
        NewProfileBloc,
        GenderSelection,
        ProfilCustomEntities,
        NewProfileItem,
        PhoneNumberInput,
    },

    props: {
        user: {
            type: CompanyUser,
            required: true,
        },
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: {},
            },
        },
        data: {
            htNationalityCollection: {
                type: HtNationalityCollection,
                default: null,
            },
        },
        queries: {
            htNationalityCollection: {
                type: HtNationalityCollection,
                default: null,
            },
        },
    },

    data() {
        return {
            modeEdit: false,
            buttonState: 'idle',
            phone: {
                phone_number: null,
                phone_country_code: null,
            },
            /**
             * Utilisateur avec ses données personnelles
             */
            companyUserPersonal: new CompanyUser([
                'id',
                'date_of_birth',
                'ht_nationality_id',
                'main_email',
                'personal_email',
                'phone_country_code',
                'phone_number',
                'professional_email',
                'gender',
            ]).where([
                ['id', '=', this.user.id],
            ]).with({
                defaultEntities: (query) => query.select([
                    'id',
                    'company_entity_value_id',
                    'company_entity_id',
                ]).with({
                    value: (query) => {
                        query.select([
                            'id',
                            'company_entity_id',
                            'resourceable_id',
                        ]).with({
                            custom: (query) => query.select(['id', 'key', 'name']),
                        });
                    },
                    entity: (query) => {
                        query.select([
                            'id',
                            'name',
                            'slug',
                            'is_private',
                            'is_heyteam',
                        ]);
                    },
                }),
            }),
        };
    },

    computed: {
        canModifyPersonalInformation() {
            return this.$canUpdate('AbstractProfileUserPersonalInformation', { company_user_id: this.user.id })
                || this.$canRead('AbstractProfilePersonal', { company_user_id: this.user.id });
        },

        professionalEmailRules() {
            const rules = {
                required: false,
                email: true,
            };

            if (this.companyUserPersonal.main_email === 'professional_email') {
                rules.required = true;
            }

            return rules;
        },

        getGenderOption() {
            return [
                {
                    name: null,
                    id: null,
                },
                {
                    name: this.translate('Male'),
                    id: 'male',
                },
                {
                    name: this.translate('Female'),
                    id: 'female',
                },
            ];
        },

        getNationalityOption() {
            return this.shared.queries.htNationalityCollection.isLoaded() && this.shared.data.htNationalityCollection
                ? this.shared.data.htNationalityCollection.models.map((nationality) => ({
                    name: nationality[`name_${(this.currentUserLang === 'fr') ? 'fr' : 'en'}`],
                    id: nationality.id,
                })).sort((a, b) => a.name.localeCompare(b.name)) : [];
        },

        currentDateFormat() {
            return (this.currentUserLang === 'fr') ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        },

        currentUserLang() {
            return this.shared.session.companyUser.isLoaded()
                ? this.shared.session.companyUser.company_language.key
                : 'en';
        },

        nationality() {
            if (
                this.companyUserPersonal.ht_nationality_id === null
                || this.shared.data.htNationalityCollection === null
            ) {
                return null;
            }

            const htNationality = this.shared.data.htNationalityCollection.models.find((item) => this.companyUserPersonal.ht_nationality_id === item.id);

            return htNationality ? htNationality[`name_${this.currentUserLang === 'fr' ? 'fr' : 'en'}`] : null;
        },

        entityIds() {
            return this.user.default_entities.models.filter((entity) => Boolean(entity.entity.is_private) && !entity.entity.is_heyteam).map((entity) => entity.entity.id);
        },
    },

    created() {
        this.companyUserPersonal.setLookupMethod('lookupPersonal');
        this.companyUserPersonal.get().then(() => {
            this.phone.phone_country_code = this.companyUserPersonal.phone_country_code;
            this.phone.phone_number = this.companyUserPersonal.phone_number;
        });

        if (this.shared.queries.htNationalityCollection.isLoaded() === false) {
            this.shared.queries.htNationalityCollection.get().then((data) => {
                this.shared.data.htNationalityCollection = data;
            });
        }
    },

    methods: {
        onUpdate() {
            this.$validator.validateAll().then((result) => {
                if (!result) {
                    return;
                }

                this.buttonState = 'loading';

                this.companyUserPersonal.phone_country_code = this.phone.phone_country_code;
                this.companyUserPersonal.phone_number = this.phone.phone_number;

                const hasProfessionalChanged = this.companyUserPersonal.$.professional_email !== this.companyUserPersonal.professional_email;

                this.companyUserPersonal.save().then(() => {
                    this.modeEdit = false;
                    this.buttonState = 'idle';
                    if (hasProfessionalChanged) {
                        profileEventBus.$emit('reload-user');
                    }
                }).finally(() => {
                    this.buttonState = 'idle';
                });
            });
        },
    },

};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.grid-2-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.2rem 2.1rem;
}

.cursor-help {
    cursor: help !important;
}
</style>
