<template>
    <div v-if="companyUserQuizCollection.isLoaded()">
        <div
            v-if="filteredUserQuiz.length"
            class="resource-type-content"
            :class="{open}"
        >
            <button
                type="button"
                class="resource-type-title"
                @click="toggle"
            >
                <HtEmojiIcon
                    size="32"
                    font-size="13"
                    :style="{backgroundColor: '#EDE5DE' }"
                >
                    <HtIcon
                        name="chat-lines"
                        stroke-width="2"
                        size="16"
                        :style="{color: '#694F4F' }"
                    />
                </HtEmojiIcon>
                {{ translate('Quizzes') }}
                <HtBadge
                    size="large"
                    theme="neutral"
                >
                    {{ filteredUserQuiz.length }}
                </HtBadge>
                <HtIcon
                    v-if="filteredUserQuiz.length"
                    size="20"
                    stroke-width="2"
                    name="nav-arrow-right"
                />
            </button>
            <div
                v-if="open"
                class="resource-types"
            >
                <button
                    v-for="item in filteredUserQuiz"
                    :key="item.id"
                    class="item"
                    @click="openItemModal(item.id)"
                >
                    <div class="item-text">
                        <div class="item-title">
                            {{ item.name }}
                        </div>
                        <div class="item-program">
                            {{ translate('Program "{programName}"', { programName: item.company_user_program.company_program.name_translated }) }}
                        </div>
                    </div>

                    <HtAvatarGroup
                        v-if="item.company_user_participant"
                        size="s"
                        :users="$Utils.getAvatarGroupData([item.company_user_participant])"
                    />

                    <HtBadge
                        size="large"
                        class="item-tag"
                    >
                        <HtIndicator :status="badgeStates[item.company_user_program_task.status]" />
                        <t>
                            {{ item.company_user_program_task.statusText }}
                        </t>
                    </HtBadge>
                    <HtIcon
                        size="20"
                        stroke-width="2"
                        name="nav-arrow-right"
                    />
                </button>
            </div>
        </div>

        <modalable
            ref="modalableUserItem"
            class="modalable-1 medium full-height-content"
            :mode="2"
        >
            <NewUserQuizItem
                :id="currentId"
                ref="userItem"
                :from="'profile'"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
    </div>
</template>

<script>
import NewUserQuizItem from '@/components/pages/dashboard/modals/quiz/NewUserQuizItem.vue';
import CompanyUserQuizCollection from '@/models/CompanyUserQuizCollection';

export default {
    name: 'NewUserQuizProfile',
    components: {
        NewUserQuizItem,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            open: false,
            badgeStates: {
                done: 'success',
                pending: 'neutral',
                draft: 'warning',
                cancelled: 'error',
            },
            currentId: null,
            companyUserQuizCollection: new CompanyUserQuizCollection([
                'id',
                'company_user_program_id',
                'company_user_id',
                'name',
                'company_user_participant_id',
                'permissions',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select(['status', 'datetime_availability']);
                },
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserParticipant: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
                companyQuiz: (query) => {
                    query.select(['id']).with({
                        resource: (query) => {
                            query.select(['id', 'name', 'translations']);
                        },
                    });
                },
            }).where([
                ['company_user_participant_id', '=', this.companyUserId],
                ['company_user_id', '=', this.companyUserId, 'or'],
            ]),
            searchValue: null,
        };
    },

    computed: {
        filteredUserQuiz: {
            get() {
                return this.companyUserQuizCollection.$.models.filter((item) => {
                    if (this.$UserAcl.isOwner(this.companyUserId)) {
                        return this.$Utils.moment().isSameOrAfter(this.$Utils.moment(item.company_user_program_task.datetime_availability));
                    }

                    return true;
                });
            },

            set(val) {
                this.companyUserQuizCollection.$.models = val;
            },
        },

        hasQuiz() {
            return this.companyUserQuizCollection.$.models.length > 0;
        },
    },

    created() {
        if (this.$canRead('ModelCompanyUserQuiz', { company_user_id: this.companyUserId })) {
            this.companyUserQuizCollection.where([['company_user_id', '=', this.companyUserId]]);
        } else {
            this.companyUserQuizCollection.where([['company_user_participant_id', '=', this.companyUserId]]);
        }

        this.companyUserQuizCollection.get().then(() => {
            this.$emit('on-load', this.filteredUserQuiz.length);
        });
    },

    methods: {
        toggle() {
            if (this.filteredUserQuiz.length) this.open = !this.open;
        },
        onUpdate() {
            this.companyUserQuizCollection.get();
        },

        onDelete() {
            this.companyUserQuizCollection.get();
        },

        openItemModal(id) {
            this.currentId = id;
            this.$refs.modalableUserItem.open();
        },
    },
};
</script>
<style lang="scss" scoped src="../ressourceItem.scss" />
