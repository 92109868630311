import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

/**
 * @param Object[] tasks
 * @return Object[]
 */
export function sortUserProgramTasks(tasks) {
    return tasks.sort((a, b) => {
        if (getUserProgramTaskStatus(a) === CompanyUserProgramTask.STATUS_DONE) {
            return 1;
        }

        if (getUserProgramTaskStatus(b) === CompanyUserProgramTask.STATUS_DONE) {
            return -1;
        }

        return 0; // No change if statutes are identical
    });
}

/**
 * @param Object task
 * @returns {{string}}
 */
export function getUserProgramTaskStatus(task) {
    // note: no dependent tasks
    if (!task.tasks) {
        return task.status;
    }

    return task.tasks.reduce((currentTaskStatus, dependentTask) => {
        if (
            dependentTask.status === CompanyUserProgramTask.STATUS_DONE
            && currentTaskStatus === CompanyUserProgramTask.STATUS_DONE
        ) {
            return CompanyUserProgramTask.STATUS_DONE;
        }

        return CompanyUserProgramTask.STATUS_PENDING;
    }, CompanyUserProgramTask.STATUS_DONE);
}

/**
 * @typedef {{ status: string }} Model
 * @typedef {{ models?: Model[] }} Dependent
 * @typedef {{ dependents?: Dependent, datetime_availability: string, status: string }} Task
 */

/**
 * @param Task task
 * @returns {boolean}
 */
export function isTaskUnavailable(task) {
    return new Date(task.datetime_availability) > new Date();
}

/**
 * @param {Task[]} taskDependencies
 * @returns {Task[]}
 */
export function availableTaskDependencies(taskDependencies) {
    return taskDependencies.filter((task) => !task.dependents?.models?.some(
        (dependent) => dependent.status !== CompanyUserProgramTask.STATUS_DONE || isTaskUnavailable(dependent),
    ));
}
