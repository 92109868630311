export default {
    data() {
        return {
            userHasRequirements: null,
            canSeeActionsPage: false,
            userRequirementsIsLoaded: false,
            actionsPageIsLoaded: false,
            actionsInProgressCounter: 0,
            actionMenuItemName: 'Actions',
        };
    },
    computed: {
        canSeePilotingPage() {
            return this.$can('PageDashboardStatistics') || this.$can('PageDashboardReport') || this.$can('PageDashboardSpecificReports') || this.$can('PageSurveyResults');
        },
    },
    methods: {
        async loadUserActionsPage() {
            /**
             * note: we need to fetch user tasks to check if the "Actions" page should be in the menu
             * we don't show it in the menu if the user has 0 task
             * this is a quick dirty way to do it. It should be manage directly with a permission
             */
            const currentTasks = await this.$store.dispatch('tasks/fetchCurrentTasks', { params: { page: 1 } })
                .then((result) => result.data.data);

            this.canSeeActionsPage = true;
            this.actionsPageIsLoaded = true;
            this.actionsInProgressCounter = currentTasks.length;
        },
        loadUserRequirements() {
            // We need to fetch user requirements to check if the "Administrative" page should be in the menu
            // This is a quick dirty way to do it. It should be manage directly with a permission
            this.$http.get(`enrollee/${this.shared.session.companyUser.id}/requirements/profil`)
                .then((response) => {
                    this.userHasRequirements = response.data?.data && response.data.data.length > 0;
                    this.userRequirementsIsLoaded = true;
                });
        },
        /**
         * @typedef {{icon: string, name: string, label: string}} Item
         * @param Item item
         */
        isActionMenuItem(item) {
            return item.name === this.actionMenuItemName;
        },
    },
};
