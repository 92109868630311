<template>
    <div v-if="companyUserDocCollection && companyUserDocCollection.isLoaded()">
        <HtAccordion
            :title="translate('Documents')"
            :count="filteredUserDoc.length"
        >
            <HtTable
                v-if="filteredUserDoc.length > 0"
                :items="filteredUserDoc"
                :columns="columns"
                cypress="user-activities"
                @onCellClicked="openItemModal"
            >
                <template #cell(display_status)="scope">
                    <HtTag :color="taskColor[scope.item.company_user_program_task.status]">
                        {{ statusText(scope.item.company_user_program_task.status) }}
                    </HtTag>
                </template>
            </HtTable>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No document added</t>
                </strong>
            </div>
        </HtAccordion>

        <Modalable
            ref="modalableItem"
            :mode="2"
            class="modalable-1 small"
        >
            <UserDocItem
                :id="id"
                :company-user-id="companyUserId"
                :status-on-toggle="statusOnToggle"
                @onUpdate="onUpdate()"
                @onDelete="onDelete()"
            />
        </Modalable>
    </div>
</template>

<script>
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserDocItem from '@/components/pages/dashboard/modals/doc/UserDocItem.vue';
import CompanyUserDocCollection from '@/models/CompanyUserDocCollection';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import HtTag from '@/components/globals/HtTag.vue';
import TaskStatusMixin from '@/mixins/TaskStatusMixin';

export default {
    name: 'UserDocumentProfile',

    components: {
        UserDocItem,
        HtAccordion,
        HtTag,
    },

    mixins: [
        TaskStatusMixin,
    ],

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        statusOnToggle: {
            type: String,
            default: CompanyUserProgramTask.STATUS_PENDING,
        },
        statusOnCreate: {
            type: String,
            default: CompanyUserProgramTask.STATUS_PENDING,
        },
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('status'),
                    key: 'display_status',
                    sortable: false,
                },
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                },
                {
                    label: this.translate('Program linked'),
                    key: 'company_user_program.company_program.name_translated',
                    sortable: false,
                },
            ],
            id: null,
            companyUserDocCollection: new CompanyUserDocCollection([
                'id', 'company_user_id', 'company_doc_id', 'name', 'is_heyteam',
            ]).with({
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUserProgramTask: (query) => {
                    query.select(['id', 'resource_id', 'resource', 'status', 'datetime_availability']);
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]).orderBy([['name', 'asc']]),
        };
    },

    computed: {
        canModify() {
            return this.$canUpdate('ModelCompanyUserDoc', { company_user_id: this.companyUserId });
        },

        filteredUserDoc() {
            return this.companyUserDocCollection.$.models.filter((item) => ((this.shared.session.companyUser.id === this.companyUserId)
                ? this.$Utils.moment().isSameOrAfter(this.$Utils.moment(item.company_user_program_task.datetime_availability))
                : true));
        },
    },

    created() {
        this.companyUserDocCollection.get().then(() => {
            this.$emit('on-load', this.companyUserDocCollection.$.models.length);
        });
    },

    methods: {
        onDelete() {
            this.companyUserDocCollection.get();
        },

        onUpdate() {
            this.companyUserDocCollection.get();
        },

        openItemModal(row) {
            this.id = row.item.id;
            this.$refs.modalableItem.open();
        },
    },
};
</script>
