import Model from '@tony.caron/node-model-proxy/Model';
import CompanyEmailTemplateRecipient from './CompanyEmailTemplateRecipient';
import CompanyFile from './CompanyFile';
import CompanyUser from './CompanyUser';
import Notifier from '../Notifier';
import I18n from '../modules/i18n/I18n';

export default class CompanyEmailTemplate extends Model {
    static TYPE_FIELD_MAPPING = {
        email: 'is_enabled',
        sms: 'is_enabled_sms',
    };

    static CUSTOM_FIELD_VARIABLES_PREFIX = 'custom_field_';

    static CUSTOM_ENTITY_VARIABLES_PREFIX = 'custom_entity_';

    modelDefaultValues() {
        return {};
    }

    modelRelations() {
        return {
            companyEmailTemplateRecipient: () => this.hasMany(CompanyEmailTemplateRecipient, 'company_email_template_id', 'id'),
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
            companySender: () => this.belongsTo(CompanyUser, 'company_sender_id', 'id'),
        };
    }

    modelCustomAttributes() {
        return {
            trigger_event_translated: null,
            title_translated: null,
            isOrderMinus: null,
        };
    }

    modelAccessors() {
        return {
            trigger_event_translated: () => {
                if (this.trigger_event) {
                    return I18n.translate(this.trigger_event);
                }
            },
            title_translated: () => {
                if (this.title) {
                    return I18n.translate(this.title);
                }
            },
        };
    }

    modelEvents() {
        return {
            updated() {
                Notifier.getInstance('App')
                    .showInfo(
                        I18n.translate('You have successfully updated this notification'),
                    );
            },
        };
    }

    modelOutConvertor() {
        return {
            order: (n) => ((!this.trigger_event) ? n * (this.isOrderMinus ? -1 : 1) : 0),
            time: (n) => this.convertFromTimeString(n),
            content_sms_fr: (n) => ((n) ? n.replace(/<[^>]*>?/gm, '') : null),
            content_sms_en: (n) => ((n) ? n.replace(/<[^>]*>?/gm, '') : null),
            // is_enabled_sms: (n) => { return (this.is_enabled) ? n : false }
        };
    }

    modelInConvertor() {
        return {
            order: (n) => {
                this.isOrderMinus = n < 0;
                return Math.abs(n);
            },
            is_enabled_email: (n) => Boolean(n),
            is_enabled_sms: (n) => Boolean(n),
            is_enabled_subject: (n) => Boolean(n),
            is_editable_content: (n) => Boolean(n),
            time: (n) => this.convertToTimeString(n),
        };
    }

    convertToTimeString(time) {
        return time < 10 ? `0${time}:00 h` : `${time}:00 h`;
    }

    convertFromTimeString(time) {
        return parseInt(time.split(':')[0], 10);
    }
}
